/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import '../sass/pages/generatepassword.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import { useGetUserComposerQuery, useUserComposerMutation } from '../features/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEye } from '@fortawesome/free-solid-svg-icons';
import CommonModal from './CommonModal';

const GeneratePassword: React.FC = () => {
  const { data: premiumData } = useGetUserComposerQuery({});
  const [expiry, setExpiry] = useState<string>('');
  const [Password, setPassword] = useState('');
  const [Username, setUsername] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [userComposer] = useUserComposerMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState<any>({
    username: '',
    password: '',
    expiry: '',
    isButtonDisabled: false,
    clients: [],
  });
  const [tooltipUsername, setTooltipUsername] = useState(false);
  const [tooltipPassword, setTooltipPassword] = useState(false);
  useEffect(() => {
    const upadtedClient = [];
    if (upadtedClient.length === 0) {
      for (let i = 0; i < 2; i++) {
        const first = {
          username: '',
          password: '',
          index: i,
          expiry: '',
          buttonDisable: true,
        };
        upadtedClient.push(first);
      }
      setUsers({
        ...users,
        clients: upadtedClient,
      });
    }
  }, []);
  // console.log("users.clients", users.clients)

  const handleExpiryChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number): void => {
    const clientDetails: any = users.clients;
    if (users.clients.length > 0) {
      const ik = clientDetails.findIndex((ik: any) => {
        return ik.index === index;
      });
      if (ik > -1) {
        clientDetails[ik].buttonDisable = false;
        clientDetails[ik].expiry = event.target.value;
      }
    }
    setUsers({
      ...users,
      clients: clientDetails,
    });
  };

  // const generateRandomUsername = () => {
  //   const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  //   let generatedUsername = '';
  //   for (let i = 0; i < 8; i++) {
  //     generatedUsername += chars.charAt(Math.floor(Math.random() * chars.length));
  //   }
  //   return generatedUsername;
  // };

  const generateRandomUsername = () => {
    const chars = 'abcdefghijklmnopqrstuvwxyz';
    const digits = '0123456789';
    let generatedUsername = '';

    for (let i = 0; i < 7; i++) {
      generatedUsername += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    const randomDigit = digits.charAt(Math.floor(Math.random() * digits.length));

    const randomPosition = Math.floor(Math.random() * 8);
    generatedUsername =
      generatedUsername.slice(0, randomPosition) + randomDigit + generatedUsername.slice(randomPosition);

    return generatedUsername;
  };

  // const generateRandomPassword = () => {
  //   const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  //   let generatedPassword = '';
  //   for (let i = 0; i < 16; i++) {
  //     generatedPassword += chars.charAt(Math.floor(Math.random() * chars.length));
  //   }
  //   return generatedPassword;
  // };

  const generateRandomPassword = () => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digits = '0123456789';
    const specialChars = '!@#$%^&*()';
    let generatedPassword = '';

    for (let i = 0; i < 15; i++) {
      generatedPassword += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    const randomDigit = digits.charAt(Math.floor(Math.random() * digits.length));

    const randomPosition = Math.floor(Math.random() * 16);
    generatedPassword =
      generatedPassword.slice(0, randomPosition) + randomDigit + generatedPassword.slice(randomPosition);

    return generatedPassword;
  };
  const handleGeneratePassword = async (event: any, index: any): Promise<void> => {
    event.preventDefault();
    if (users.clients.length > 0) {
      users.clients[index].buttonDisable = true;
      users.clients[index].username = generateRandomUsername();
      users.clients[index].password = generateRandomPassword();
      // console.log("users.clients[index]", users.clients)
      setUsers({
        ...users,
        clients: [...users.clients],
      });
    }
  };

  const handleUser = async (event: any) => {
    event.preventDefault();
    setIsButtonDisabled(false);
    const generatedUsername = await generateRandomUsername();
    const generatedPassword = await generateRandomPassword();
    const details = {
      username: generatedUsername,
      password: generatedPassword,
      frequency: expiry,
    };
    // console.log(details)
    setUsername(details.username);
    setPassword(details.password);
    userComposer(details)
      .then((res1: any) => {
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 5000);
        if (Object.prototype.hasOwnProperty.call(res1.data, 'success')) {
          toast.success(res1.data.message);
        } else {
          toast.error('error during add the composer details');
        }
      })
      .catch((error: any) => {
        toast.error('error during add the composer details');
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 5000);
      });
  };

  const handleUserExpiryChange = (event: any) => {
    setIsButtonDisabled(true);
    setExpiry(event.target.value);
  };

  const isExpirySelected = expiry !== '';

  const passwordModalOpen = () => {
    setIsModalOpen(true);
  };

  function copyText(text: any, type: any) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
        if (type === 'username') {
          setTooltipUsername(true);
          setTimeout(() => setTooltipUsername(false), 2000); // Hide after 2 seconds
        } else if (type === 'password') {
          setTooltipPassword(true);
          setTimeout(() => setTooltipPassword(false), 2000); // Hide after 2 seconds
        }
      })
      .catch(err => {
        console.error('Error in copying text: ', err);
      });
  }

  return (
    <>
      <main className='main'>
        <div className='accordion-container'>
          <h1>Generate Password</h1>

          <div className='accordion styled-accordion' id='accordionExample'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingOne'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseOne'
                  aria-expanded='true'
                  aria-controls='collapseOne'
                >
                  Generate Username and Password
                </button>
              </h2>
              <div
                id='collapseOne'
                className='accordion-collapse collapse show'
                aria-labelledby='headingOne'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <form className='addprojects addproject'>
                    {/* <h5>User#1</h5> */}
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{ width: '100px', height: '40px' }}>
                        Username
                      </span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='username will appear here'
                        value={Username}
                        style={{ width: '80px', height: '40px', marginRight: '5px' }}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{ width: '100px', height: '40px' }}>
                        Password
                      </span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='Password will appear here'
                        value={Password}
                        style={{ width: '100px', height: '40px', marginRight: '5px' }}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <label
                        htmlFor='expirySelect'
                        className='input-group-text'
                        style={{ width: '100px', height: '40px' }}
                      >
                        Expiry
                      </label>
                      <select
                        className='form-select'
                        id='expirySelect'
                        value={expiry}
                        onChange={handleUserExpiryChange}
                        style={{ width: '100px', height: '40px', marginRight: '5px' }}
                      >
                        <option value='' disabled={true}>
                          Select expiry
                        </option>
                        <option value='day'>Day</option>
                        <option value='month'>Month</option>
                        <option value='year'>Year</option>
                        <option value='forever'>Forever</option>
                      </select>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button className='orange_btn' onClick={handleUser} disabled={!isButtonDisabled}>
                        Save
                      </button>
                    </div>
                    <FontAwesomeIcon
                      icon={faEye}
                      className='grey-color'
                      style={{ marginLeft: '10px', marginTop: '7px', height: '25px', cursor: 'pointer' }}
                      onClick={passwordModalOpen}
                    />
                  </form>
                  <CommonModal
                    className='composer-model'
                    show={isModalOpen}
                    onHide={() => setIsModalOpen(false)}
                    animation={false}
                    size='lg'
                  >
                    <CommonModal.Body>
                      <div className='logo' style={{ color: 'white' }}>
                        <h1 style={{ marginRight: '102px', marginBottom: '10px' }}>
                          UserName: &nbsp; {premiumData?.Username}{' '}
                          <FontAwesomeIcon
                            icon={faCopy}
                            onClick={() => copyText(premiumData?.Username, 'username')}
                            style={{ color: 'rgb(240, 137, 33)' }}
                          />{' '}
                          {tooltipUsername && <span className='text-white'>Copied!</span>}
                        </h1>
                        <h1>
                          Password: &nbsp; {premiumData?.password}{' '}
                          <FontAwesomeIcon
                            icon={faCopy}
                            onClick={() => copyText(premiumData?.password, 'password')}
                            style={{ color: 'rgb(240, 137, 33)' }}
                          />
                          {tooltipPassword && <span className='text-white'>Copied!</span>}
                        </h1>
                      </div>
                    </CommonModal.Body>
                  </CommonModal>
                  {/* <div className="accordion" id="accordionExample1">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Accordion Item #2
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
              <div className="accordion-body">
              <form className='addprojects addproject'>
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{width:'100px',height:'40px'}}>Username</span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='username will appear here'
                        value={users.clients[0]?.username || ''}
                        style={{width:'80px',height:'40px', marginRight:'5px'}}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{width:'100px',height:'40px'}}>Password</span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='Password will appear hBEGINere'
                        value={users.clients.length > 0 ? users.clients[0].password:""}
                        style={{width:'100px',height:'40px',marginRight:'5px'}}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <label htmlFor='expirySelect' className='input-group-text' style={{width:'100px',height:'40px'}}>
                        Expiry
                      </label>
                      <select
                        className='form-select'
                        id='expirySelect'
                        value={users.clients.length > 0 ? users.clients[0].expiry:""}
                       onChange={(event) => handleExpiryChange(event,0)}
                        style={{width:'100px',height:'40px',marginRight:'5px'}}
                      >
                        <option value='' disabled={true}>Select expiry</option>
                        <option value='day'>Day</option>
                        <option value='month'>Month</option>
                        <option value='year'>Year</option>
                        <option value='forever'>Forever</option>
                      </select>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button className='orange_btn' onClick={(event) => handleGeneratePassword(event,0)} disabled={users.clients.length ? users.clients[0]?.buttonDisable:true} >Save</button>
                    </div>
                  </form>
              </div>
            </div>
          </div>
          <div className="accordion-item">BEGIN
            <h2 className="accordion-header" id="headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Accordion Item #3
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample1">
              <div className="accordion-body">
              <form className='addprojects addproject'>
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{width:'100px',height:'40px'}}>Username</span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='username will appear here'
                        value={users.clients[1]?.username || ''}
                        style={{width:'80px',height:'40px', marginRight:'5px'}}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{width:'100px',height:'40px'}}>Password</span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='Password will appear here'
                        value={users.clients[1]?.password BEGIN|| ''}
                        style={{width:'100px',height:'40px',marginRight:'5px'}}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <label htmlFor='expirySelect' className='input-group-text' style={{width:'100px',height:'40px'}}>
                        Expiry
                      </label>
                      <select
                        className='form-select'
                        id='expirySelect'
                        value={users.clients.length > 0 ? users.clients[1]?.expiry:""}
                       onChange={(event) => handleExpiryChange(event,1)}
                        style={{width:'100px',height:'40px',marginRight:'5px'}}
                      >
                        <option value='' disabled={true}>Select expiry</option>
                        <option value='day'>Day</option>
                        <option value='month'>Month</option>
                        <option value='year'>Year</option>
                        <option value='forever'>Forever</option>
                      </select>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button className='orange_btn' onClick={(event) => handleGeneratePassword(event,1)} disabled={users.clients.length ? users.clients[1]?.buttonDisable:true} >Save</button>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <Accordion defaultActiveKey="1" className="styled-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>User-1</Accordion.Header>
          <Accordion.Body>
             <form className='addprojects addproject'>
              <h5>User#1</h5>
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{width:'100px',height:'40px'}}>Username</span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='username will appear here'
                        value={Username}
                        style={{width:'80px',height:'40px', marginRight:'5px'}}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{width:'100px',height:'40px'}}>Password</span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='Password will appear here'
                        value={Password}
                        style={{width:'100px',height:'40px',marginRight:'5px'}}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <label htmlFor='expirySelect' className='input-group-text' style={{width:'100px',height:'40px'}}>
                        Expiry
                      </label>
                      <select
                        className='form-select'
                        id='expirySelect'
                        value={expiry}
                       onChange={handleUserExpiryChange}
                        style={{width:'100px',height:'40px',marginRight:'5px'}}
                      >
                        <option value='' disabled={true}>Select expiry</option>
                        <option value='day'>Day</option>
                        <option value='month'>Month</option>
                        <option value='year'>Year</option>
                        <option value='forever'>Forever</option>
                      </select>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button className='orange_btn' onClick={handleUser} disabled={!isButtonDisabled}>Save</button>
                    </div>
                  </form>

          </Accordion.Body>
          <Accordion.Body>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Team#1</Accordion.Header>
                <Accordion.Body>
                  <form className='addprojects addproject'>
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{width:'100px',height:'40px'}}>Username</span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='username will appear here'
                        value={users.clients[0]?.username || ''}
                        style={{width:'80px',height:'40px', marginRight:'5px'}}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{width:'100px',height:'40px'}}>Password</span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='Password will appear here'
                        value={users.clients.length > 0 ? users.clients[0].password:""}
                        style={{width:'100px',height:'40px',marginRight:'5px'}}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <label htmlFor='expirySelect' className='input-group-text' style={{width:'100px',height:'40px'}}>
                        Expiry
                      </label>
                      <select
                        className='form-select'
                        id='expirySelect'
                        value={users.clients.length > 0 ? users.clients[0].expiry:""}
                       onChange={(event) => handleExpiryChange(event,0)}
                        style={{width:'100px',height:'40px',marginRight:'5px'}}
                      >
                        <option value='' disabled={true}>Select expiry</option>
                        <option value='day'>Day</option>
                        <option value='month'>Month</option>
                        <option value='year'>Year</option>
                        <option value='forever'>Forever</option>
                      </select>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button className='orange_btn' onClick={(event) => handleGeneratePassword(event,0)} disabled={users.clients.length ? users.clients[0]?.buttonDisable:true} >Save</button>
                    </div>
                  </form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Accordion.Body>
          <Accordion.Body>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Team#2</Accordion.Header>
                <Accordion.Body>
                <form className='addprojects addproject'>
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{width:'100px',height:'40px'}}>Username</span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='username will appear here'
                        value={users.clients[1]?.username || ''}
                        style={{width:'80px',height:'40px', marginRight:'5px'}}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text' style={{width:'100px',height:'40px'}}>Password</span>
                      <textarea
                        className='form-control'
                        readOnly
                        cols={2}
                        rows={2}
                        placeholder='Password will appear here'
                        value={users.clients[1]?.password || ''}
                        style={{width:'100px',height:'40px',marginRight:'5px'}}
                      ></textarea>
                    </div>
                    <div className='input-group mb-3'>
                      <label htmlFor='expirySelect' className='input-group-text' style={{width:'100px',height:'40px'}}>
                        Expiry
                      </label>
                      <select
                        className='form-select'
                        id='expirySelect'
                        value={users.clients.length > 0 ? users.clients[1]?.expiry:""}
                       onChange={(event) => handleExpiryChange(event,1)}
                        style={{width:'100px',height:'40px',marginRight:'5px'}}
                      >
                        <option value='' disabled={true}>Select expiry</option>
                        <option value='day'>Day</option>
                        <option value='month'>Month</option>
                        <option value='year'>Year</option>
                        <option value='forever'>Forever</option>
                      </select>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button className='orange_btn' onClick={(event) => handleGeneratePassword(event,1)} disabled={users.clients.length ? users.clients[1]?.buttonDisable:true} >Save</button>
                    </div>
                  </form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion> */}
        </div>
      </main>
    </>
  );
};

export default GeneratePassword;
