/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import avatar from '../img/avatar.png';
import attachment from '../img/ metroattachment.png';
import user_img from '../img/user_img.png';
import { useDispatch } from 'react-redux';
import { fetchSupportCategory } from '../features/getSupportCategory';
import { fetchSupportCustomer } from '../features/getSupportCustomer';
import { fetchSupportStatus } from '../features/getSupportStatus';
import { useAppSelector } from '../features/hooks';
import { fetchUserDetails } from '../features/userDetailsSlice';
import { fetchSupportTicketsThread } from '../features/getSupportTicketThread';
import { useParams } from 'react-router-dom';
import { fetchSupportTicketsDetails } from '../features/getSupportTicketDetails';
import axios from 'axios';
import Loader from './Loader';

const TicketDetails: React.FC = () => {
  const [ticketDetailsData, setTicketDitailsData] = useState<any>({});
  const [listImage, setListImage] = useState<Array<any>>([]);

  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchUserDetails());
    dispatch(fetchSupportCategory());
    dispatch(fetchSupportCustomer());
    dispatch(fetchSupportStatus());

    dispatch(fetchSupportTicketsThread(id));
    dispatch(fetchSupportTicketsDetails(id));
  }, [dispatch, id]);

  // const { data:userdetails } = useAppSelector((state:any) => state.userDetails);
  const { data: supportStatus } = useAppSelector((state: any) => state.supportStatus);
  const { data: supportCategory } = useAppSelector((state: any) => state.supportCategory);
  // const { data:supportCustomers } = useAppSelector((state:any) => state.supportCustomers);
  const { data: supportTicketThread } = useAppSelector((state: any) => state.supportTicketThread);
  const { data: supportTicketDetails } = useAppSelector((state: any) => state.supportTicketDetails);

  useEffect(() => {
    if (
      supportStatus !== null &&
      supportCategory !== null &&
      supportTicketThread !== null &&
      supportTicketDetails !== null
    ) {
      const ticketDetails = {
        ...supportTicketDetails,
        status: supportStatus.find((ikk: any) => {
          return ikk.id === supportTicketDetails.status;
        }),
        category: supportCategory.find((ikkk: any) => {
          return ikkk.id === supportTicketDetails.category;
        }),
        // attachments:createLinks
      };
      // console.log("ticketDetails",ticketDetails)
      setTicketDitailsData(ticketDetails);
    }
  }, [supportStatus, supportCategory, supportTicketThread, supportTicketDetails]);

  const createLinks = async (ticketDetailsAttach: string) => {
    const ids = ticketDetailsAttach.split(',');
    const promises = ids.map(id => fetchImage(id));
    const images = await Promise.all(promises);
    // images.forEach((img) => console.log("Image URL:", img));
    return images;
  };

  const fetchImage = async (id: any): Promise<string> => {
    try {
      const img = await axios({
        url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/supportcandy/v2/attachments/${id}`,
        method: 'GET',
        auth: {
          username: process.env.REACT_APP_WORDPRESS_USERNAME as any,
          password: process.env.REACT_APP_WORDPRESS_APP_PASSWORD as any,
        },
      });
      return img.data.url;
    } catch (err) {
      return '';
    }
  };

  useEffect(() => {
    if (supportTicketThread !== null) {
      const ticketDetailsAttach = supportTicketThread.results[0].attachments;
      createLinks(ticketDetailsAttach).then((images: any) => {
        setListImage(images);
      });
    }
  }, [supportTicketThread]);

  // console.log("userdetails",userdetails,supportStatus,supportCategory,supportCustomers,supportTicketThread,supportTicketDetails)
  return (
    <section className='ticket_details pt-5 mx-auto'>
      {Object.keys(ticketDetailsData).length > 0 ? (
        <div className='container_fluid'>
          <div className='text-end'>
            <button type='button' className='cblue_btn'>
              Resolve/Reopen ticket
            </button>
          </div>
          <div className='row gy-3'>
            <div className='col-md-3'>
              <label className='text-white'>Id</label>
            </div>
            <div className='col-md-9'>
              <div className='darkgray'>{ticketDetailsData.id}</div>
            </div>
            {/* <div className='col-md-3'>
            <label className='text-white'>Name</label>
          </div>
          <div className='col-md-9'>
            <div className='darkgray'>{ticketDetailsData.customer}</div>
          </div> */}
            <div className='col-md-3'>
              <label className='text-white'>Email</label>
            </div>
            <div className='col-md-9'>
              <div className='darkgray'>{ticketDetailsData.customer}</div>
            </div>
            <div className='col-md-3'>
              <label className='text-white'>Subject</label>
            </div>
            <div className='col-md-9'>
              <div className='darkgray'>{ticketDetailsData.subject}</div>
            </div>
            {/* <div className='col-md-3'>
            <label className='text-white'>Projects</label>
          </div>
          <div className='col-md-9'>
            <div className='darkgray'>Project 1</div>
          </div> */}
            <div className='col-md-3'>
              <label className='text-white'>Category</label>
            </div>
            <div className='col-md-9'>
              <div className='darkgray'>{ticketDetailsData.category.name}</div>
            </div>
            <div className='col-md-3'>
              <label className='text-white'>Last update</label>
            </div>
            <div className='col-md-9'>
              <div className='darkgray'>{ticketDetailsData.date_updated}</div>
            </div>
            {/* <div className='col-md-3'>
            <label className='text-white'>Amount</label>
          </div>
          <div className='col-md-9'>
            <div className='darkgray'>$99</div>
          </div> */}
            {/* <div className='col-md-3'>
            <label className='text-white'>Description</label>
          </div>
          <div className='col-md-9'>
            <div className='darkgray'>
              Aenean sollicitudin luctus erat bibendum ornare diam malesuada id In elementum egestas quis urna commodo
              varius aenean mi erat posuere vel tempus sit amet tincidunt odio.
            </div>
          </div> */}
            <div className='col-md-3'>
              <label className='text-white'>Status</label>
            </div>
            <div className='col-md-9'>
              <div className='darkgray'>
                <button type='button' className='in_progress'>
                  {ticketDetailsData.status.name}
                </button>
              </div>
            </div>
          </div>
          <div className='d-flex my-5 flex-wrap gap-3'>
            <button className='cblue_btn d-flex align-items-center'>
              <span className='d-inline-block ms-1'>Download attachment</span>
            </button>
            {listImage.map((ik: any) => {
              return <img src={ik} alt='123' width={250} height={250} />;
            })}
            {/* <button className='orange_btn d-flex align-items-center' onClick={() => changeTicketId('')}>
            <span className='d-inline-block ms-1'>Back</span>
          </button> */}
          </div>
          <section className='comments'>
            <div className='add_comment'>
              <img src={avatar} alt='' className='img-fluid avatar' />
              <div className='comment_box'>
                <form>
                  <textarea className='form-control comment_input' placeholder='Write here...' name='comment'>
                    {' '}
                  </textarea>
                  <div className='d-flex'>
                    <div className='d-flex file-upload flex-fill'>
                      <input className='form-control d-none' type='file' id='formFile' />
                      <input className='form-control file-inputbox' type='text' readOnly />
                      <button type='button' className='file-btn'>
                        <label htmlFor='formFile'>
                          <img src={attachment} alt='' /> Attach file
                        </label>
                      </button>
                    </div>
                    <button type='button' className='cblue_btn'>
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <section className='comment_listing mt-5'>
            <div className='comment_item mb-5'>
              <div className='usr_avatar'>
                <div className='usr_img_area'>
                  <img src={user_img} alt='' />
                </div>
                <div className='comment_details'>
                  <div className='user_name'>
                    <div>Twila Rose</div>
                    <div className='comment_time font14 darkgray'>3 hours ago</div>
                  </div>
                  <div className='comment_details darkgray'>
                    Curabitur efficitur pretium mauris sed egestas tellus rhoncus nulla arcu lectus finibus egestas
                  </div>
                </div>
              </div>
            </div>
            <div className='comment_item mb-5'>
              <div className='usr_avatar'>
                <div className='usr_img_area'>
                  <img src={user_img} alt='' />
                </div>
                <div className='comment_details'>
                  <div className='user_name'>
                    <div>Twila Rose</div>
                    <div className='comment_time font14 darkgray'>3 hours ago</div>
                  </div>
                  <div className='comment_details darkgray'>
                    Curabitur efficitur pretium mauris sed egestas tellus rhoncus nulla arcu lectus finibus egestas
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default TicketDetails;
