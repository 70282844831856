/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  items: [],
  status: null,
};

export const fetchSupportCategory: any = createAsyncThunk('projects/fetchSupportCategory', async (result:any,{getState}) => {
  // You can use useProjectQuery here to fetch data
  // console.log("useProjectQuery",useProjectQuery({'with-plugins':true}));
  const response = await axios({
    url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/supportcandy/v2/categories`,
    method: 'GET',
    auth: {
      username: result.data.user_name,
      password: result.data.app_name,
    },
  });
  return response.data;
});

const queueSlice = createSlice({
  name: 'getSupportCategory',
  initialState: {
    data: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchSupportCategory.pending]: (state: any, action: any) => {
      state.status = 'pending';
    },
    [fetchSupportCategory.fulfilled]: (state: any, action: any) => {
      state.status = 'success';
      state.data = action.payload;
    },
    [fetchSupportCategory.rejected]: (state: any, action: any) => {
      state.status = 'rejected';
    },
  },
});

export default queueSlice.reducer;
