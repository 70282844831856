import React, { FormEvent, useEffect, useState } from 'react';
import { useLoginMutation } from '../features/api';
import { faEnvelope, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import useAuthenticated from '../hooks/useAuthenticated';

const Login: React.FC = () => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [login] = useLoginMutation();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const navigate = useNavigate();
  const togglePassword = () => {
    setPasswordShow(!passwordShow);
  };
  const [error, setError] = useState<any>({});
  const authenticated = useAuthenticated();

  console.log("authenticated",authenticated)

  useEffect(() => {
    localStorage.removeItem("login_proceed")
  },[])

  const validateForm = (): any => {
    const newError: any = {};

    if (!formData.email) {
      newError.email = 'email is required';
    }
    if (!formData.password) {
      newError.password = 'password is required';
    }

    setError(newError);

    return Object.keys(newError).length === 0;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!validateForm()) {
      console.log('form validation failed');
      return;
    }

    setDisableButton(true);
    let data = {
      email: formData.email,
      password: formData.password,
    };

    try {
      let res = await login(data);
      setDisableButton(false);

      if ('error' in res) {
        // TODO: use the errors
        return;
      }

      const data2 = {
        email: btoa(formData.email),
        password: '',
      };
      localStorage.setItem('wordpresslogin', JSON.stringify(data2));
      console.log("res.data",res.data)
      if(Object.prototype.hasOwnProperty.call(res.data,"requires_otp")){
        localStorage.setItem("login_proceed",'true')
        setTimeout(() => {
          navigate('/authOtp');
        },100)

      }
      else if(Object.prototype.hasOwnProperty.call(res.data,"user")){
        if (res.data.user.otp_preference === true) {
          localStorage.setItem("login_proceed",'true')
          navigate('/authOtp');
        } else {
          if(Object.prototype.hasOwnProperty.call(res.data.user,'token')){
            localStorage.setItem('token', res.data.user.token!);
            setTimeout(() => {
              navigate('/authOtp');
            },100)
          } else {
            toast.error('Something went wrong')
          }
        }
      }
    } catch (err) {
      toast.error(`${err}`, { theme: 'colored', autoClose: 1000 });
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  return (
    <div className='fp_custom_login'>
      <form onSubmit={handleSubmit}>
        <div className='input-container'>
          <FontAwesomeIcon icon={faEnvelope} className='fa fa-envelope icon' />
          <input
            type='email'
            className='input-field'
            placeholder='Email Id'
            name='email'
            onChange={handleChange}
            value={formData.email}
          />
          {error.email && <span style={{ color: 'red', fontSize: '14px' }}>{error.email}</span>}
        </div>
        <div className='input-container'>
          <FontAwesomeIcon icon={faLock} className='fa fa-lock icon' />
          <input
            type={passwordShow ? 'text' : 'password'}
            className='input-field'
            placeholder='Password'
            name='password'
            onChange={handleChange}
            value={formData.password}
          />
          <FontAwesomeIcon
            icon={passwordShow ? faEye : faEyeSlash}
            className='icon icon_sec'
            onClick={togglePassword}
          />
          {error.password && <span style={{ color: 'red', fontSize: '14px' }}>{error.password}</span>}
        </div>
        <div className='forget_password'>
          <Link to='/forgot-password' className='fp_forgot_pass'>
            Forgot Password?
          </Link>
        </div>
        <button
          className='orange_btn w-100 mt-3'
          disabled={disableButton}
          style={{ cursor: disableButton ? 'not-allowed' : 'pointer' }}
        >
          {disableButton ? 'Loading...' : 'Login'}
        </button>
        <p className='log_reg_with'>OR Login with</p>
      </form>
    </div>
  );
};

export default Login;
