/* eslint-disable @typescript-eslint/no-unused-vars */
// // Import the functions you need from the SDKs you need
// import { initializeApp } from 'firebase/app';
// import { getAuth, GoogleAuthProvider } from 'firebase/auth';
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: 'AIzaSyBJEDWFAsPbwULc-C5PFiv7RECwQovACmY',
//   authDomain: 'fusepress-25fa1.firebaseapp.com',
//   projectId: 'fusepress-25fa1',
//   storageBucket: 'fusepress-25fa1.appspot.com',
//   messagingSenderId: '293395295001',
//   appId: '1:293395295001:web:fc8dd11cd6d461fdfd7388',
// };

// // Initialize Firebase
// export const app = initializeApp(firebaseConfig);
// export const auth = getAuth(app);

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB85Nsb9QDbmSMoJwrzJxqdYnH-HijFqtM',
  authDomain: 'fusepress.firebaseapp.com',
  projectId: 'fusepress',
  storageBucket: 'fusepress.appspot.com',
  messagingSenderId: '1060175255436',
  appId: '1:1060175255436:web:9f5ec2f73c414615c9a8a9',
  measurementId: 'G-GCM108TPD0',
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);

export const getTokens = (setTokenFound: any, setToken: any) => {
  return getToken(messaging, {
    vapidKey: 'BH_hYE3bEu2RkVYzBdYRGYJ_4LX7jzAqER6LmySGtwVE8C3etEQk44_m_FiCCchajYP34Jva-n-EtBbtd6aMlSw',
  })
    .then(currentToken => {
      if (currentToken) {
        // console.log(currentToken);
        setToken(currentToken);
        setTokenFound(true);
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
      }
    })
    .catch(err => {
      setTokenFound(false);
      // console.log('An error occurred while retrieving token. ', err);
    });
};

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("payload",payload)
//       resolve(payload);
//     });
// });
