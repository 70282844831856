import React from 'react';
import { getUserWiseRejectedPluginInterface } from '../Interface/appInterface';
import moment from 'moment';

const RejectedUserPackageCard: React.FC<getUserWiseRejectedPluginInterface> = (
  props: getUserWiseRejectedPluginInterface
) => {
  return (
    <>
      <tr>
        <td>
          <div>
            <h6 className='text-white'>{props.item.name}</h6>
          </div>
        </td>
        <td>
          <div>
            <p className='text-white'>{moment(props.item.created_at).format('DD MMM YYYY')}</p>
          </div>
        </td>
        <td>
          <div>
            <p className='text-white'>V {props.item.version}</p>
          </div>
        </td>
        <td>
          <div>
            <p
              className='text-white'
              style={{
                width: '350px',
                overflow: 'hidden',
                wordBreak: 'break-word',
                whiteSpace: 'break-spaces',
                textOverflow: 'ellipsis',
              }}
            >
              {props.item.description}
            </p>
          </div>
        </td>
        <td>
          <button type='button' className='cblue_btn my-2'>
            Review and resubmit
          </button>
          <br />
          <button type='button' className='red_btn my-2'>
            Delete
          </button>
        </td>
      </tr>
    </>
  );
};

export default RejectedUserPackageCard;
