import React from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { useProjectQuery } from '../features/api';
const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const { data: projectsData } = useProjectQuery({
    data: {},
  });

  let currentLink = '';
  let { projectid } = useParams();

  const crumbs = location.pathname
    .split('/')
    .filter((crumb, index) => location.pathname.split('/').indexOf(crumb) === index)
    .map((crumb, index) => {
      if (crumb === projectid) {
        const project: any = projectsData?.projects?.find((e: any) => e.id === Number(crumb));
        currentLink += `/${crumb}`;
        crumb = project?.name;
      } else if (crumb !== '') {
        currentLink += `/${crumb}`;
      } else {
        currentLink += ``;
        crumb = 'Home';
      }
      return (
        <div className='crumb' key={index}>
          <Link to={currentLink} className={currentLink === location.pathname ? 'activeCrumb' : ''}>
            {crumb}
          </Link>
        </div>
      );
    });

  return <div className='breadcrumbs'>{crumbs}</div>;
};

export default Breadcrumbs;
