/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import fusepress_logo from '../img/fusepress_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faGithub, faLinkedin, faAmazon } from '@fortawesome/free-brands-svg-icons';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import { auth } from '../firebase';
import { signInWithPopup, GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';
// import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { LoginSocialAmazon, LoginSocialLinkedin } from 'reactjs-social-login';
import { useUserCreateMutation } from '../features/api';
import { toast } from 'react-toastify';

const Auth: React.FC = () => {
  const [toggle, setToggle] = useState(true);
  const navigate = useNavigate();
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const source = localStorage.getItem('source');

    if (source === 'external') {
      setToggle(false);
      localStorage.removeItem('source');
    }
  }, []);

  const [userCreate] = useUserCreateMutation();

  const onGoogleAuth = async (): Promise<void> => {
    try {
      const googleProvider = new GoogleAuthProvider();
      await signInWithPopup(auth, googleProvider).then(res => {
        const data: any = {
          email: res.user.email,
          fname: res.user.displayName,
          lname: res.user.displayName,
          name: res.user.displayName,
          provider: 'google',
          provider_id: 'google.com',
        };
        const data1 = {
          email: btoa(data.email),
          password: '',
        };
        localStorage.setItem('wordpresslogin', JSON.stringify(data1));
        userCreate(data).then((res1: any) => {
          if ('data' in res1 && res1.data.passwordVal === null) {
            const params: any = {
              email: res1.data.email,
              provider: 'google',
              username: `${res1.data.fname} ${res1.data.lname}`,
              fname: res1.data.fname,
              lname: res1.data.lname,
            };
            navigate({
              pathname: '/set-password',
              search: `?${createSearchParams(params)}`,
            });
          } else {
            localStorage.setItem('welcome_flag', 'true');
            localStorage.setItem('userDetails', JSON.stringify(res?.user));

            if (res1.data.otp_preference === true) {
              console.log('res1.data.otp_preference', res1.data.otp_preference);
              navigate('/authOtp');
            } else {
              localStorage.setItem('token', 'data' in res1 && res1.data.token);
              setTimeout(() => {
                navigate('/');
              }, 1000);
            }
          }
        });
      });
    } catch (error) {
      console.log('err', error);
    }
  };

  // const { linkedInLogin } = useLinkedIn({
  //   clientId: '77g7ckcyck3v4q',
  //   redirectUri: `${typeof window === 'object' && window.location.origin}/linkedin`,
  //   onSuccess: code => {
  //     // console.log(code);
  //     // setCode(code);
  //   },
  //   scope: 'r_emailaddress',
  //   onError: error => {
  //     console.log(error);
  //     // setErrorMessage(error.errorMessage);
  //   },
  // });

  const onAmazonLog = async (provider: any, data: any): Promise<void> => {
    try {
      const data1 = {
        email: data.email,
        fname: data.name,
        lname: data.name,
        name: data.name,
        provider: provider,
        provider_id: 'amazon.com',
      };
      userCreate(data1).then((res1: any) => {
        if ('data' in res1 && res1.data.passwordVal === null) {
          const params: any = { email: res1.data.email, provider: 'amazon' };
          navigate({
            pathname: '/set-password',
            search: `?${createSearchParams(params)}`,
          });
        } else {
          localStorage.setItem('welcome_flag', 'true');
          localStorage.setItem('userDetails', JSON.stringify(data1));
          if (res1.data.otp_preference === true) {
            navigate('/authOtp');
          } else {
            localStorage.setItem('token', 'data' in res1 && res1.data.token);
            setTimeout(() => {
              navigate('/');
            }, 1000);
          }
        }
      });
    } catch (error) {
      console.error('Amazon login error', error);
    }
  };

  const onGithubLogin = async (): Promise<void> => {
    try {
      const githubProvider = new GithubAuthProvider();
      await signInWithPopup(auth, githubProvider).then(res => {
        const data: any = {
          email: res.user.email,
          fname: res.user.displayName,
          lname: res.user.displayName,
          name: res.user.displayName,
          provider: 'github',
          provider_id: 'github.com',
        };
        const data1 = {
          email: btoa(data.email),
          password: '',
        };
        localStorage.setItem('wordpresslogin', JSON.stringify(data1));
        userCreate(data).then((res1: any) => {
          if ('data' in res1 && res1.data.passwordVal === null) {
            const params: any = { email: res1.data.email, provider: 'github' };
            navigate({
              pathname: '/set-password',
              search: `?${createSearchParams(params)}`,
            });
          } else {
            localStorage.setItem('welcome_flag', 'true');
            localStorage.setItem('userDetails', JSON.stringify(res?.user));
            if (res1.data.otp_preference === true) {
              navigate('/authOtp');
            } else {
              localStorage.setItem('token', 'data' in res1 && res1.data.token);
              setTimeout(() => {
                navigate('/');
              }, 1000);
            }
          }
        });
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleToggle = () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      setToggle(prevToggle => !prevToggle);
    }, 300);
    setDebounceTimeout(timeout);
  };

  return (
    <main className='main'>
      <div className='main_auth_inner'>
        <div className='home_logo'>
          <img src={fusepress_logo} alt='home_logo' />
        </div>
        <div className='select_login_screen'>
          <div className='login_register'>
            <span className='login_screen'>Login</span>
            <label className='switch'>
              <input
                type='checkbox'
                id='select_login_screen'
                name='select_login_screen'
                onChange={handleToggle}
                checked={!toggle}
              />
              <span className='slider round'></span>
            </label>
            <span className='register_screen'>Register</span>
          </div>
        </div>
        {toggle ? <Login /> : <Register setToggle={setToggle} />}
        <div className='social_login'>
          <ul>
            <li title='Google' onClick={() => onGoogleAuth()}>
              <FontAwesomeIcon icon={faGoogle} className='fab fa-google' style={{ color: '#f28a03' }} />
            </li>
            {/*
            <LoginSocialLinkedin
              client_id={'77g7ckcyck3v4q'}
              client_secret={'SJ3o9lBVvSh89yUi'}
              redirect_uri={'https://app-qa.fusepress.co/auth'}
              // onLoginStart={onLoginStart}
              onResolve={({ provider, data }: any) => {
                console.log(provider, data);
              }}
              onReject={(err: any) => {
                console.log(err);
                toast.error(err.error);
              }}
            >
              <li title='LinkedIn'>
                <FontAwesomeIcon icon={faLinkedin} className='fab fa-google ' style={{ color: '#f28a03' }} />
              </li>
            </LoginSocialLinkedin>
            <LoginSocialAmazon
              client_id={'amzn1.application-oa2-client.0881f2445e664aff86b180844929dfb9'}
              redirect_uri={'https://app-qa.fusepress.co/auth'}
              onResolve={({ provider, data }: any) => onAmazonLog(provider, data)}
              onReject={(err: any) => {
                console.log(err);
                toast.error(err.error);
              }}
              // onLoginStart={onLoginStart}
            >
              <li title='Amazon'>
                {' '}
                <FontAwesomeIcon icon={faAmazon} className='fab fa-google' style={{ color: '#f28a03' }} />
              </li>
            </LoginSocialAmazon> */}
            {/* <li onClick={() => onAmazonLog()}><FontAwesomeIcon icon={faAmazon} className="fab fa-google" style={{color:"#f28a03"}} /></li> */}
            {/* <li><button onClick={() => logOut()}>logout</button></li> */}
            {/* <li><Link to=""><FontAwesomeIcon icon={faGooglePlus} className="fab fa-google-plus-g" /><i className="fab fa-google-plus-g"></i></Link></li> */}
            {/* <li title='Facebook'>
              <Link to=''>
                <FontAwesomeIcon icon={faFacebook} className='fab fa-facebook' />
              </Link>
            </li> */}
            <li title='Github' onClick={() => onGithubLogin()}>
              <FontAwesomeIcon icon={faGithub} className='fab fa-github' style={{ color: '#f28a03' }} />
              <i className='fab fa-github'></i>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
};

export default Auth;
