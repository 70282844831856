/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  items: [],
  status: null,
};

export const fetchSupportTicketsDetails: any = createAsyncThunk(
  'projects/fetchSupportTicketsDetails',
  async (id, { getState }) => {
    // You can use useProjectQuery here to fetch data
    // console.log("useProjectQuery",useProjectQuery({'with-plugins':true}));
    const response = await axios({
      url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/supportcandy/v2/tickets/${id}`,
      method: 'GET',
      auth: {
        username: process.env.REACT_APP_WORDPRESS_USERNAME as any,
        password: process.env.REACT_APP_WORDPRESS_APP_PASSWORD as any,
      },
    });
    return response.data;
  }
);

const queueSlice = createSlice({
  name: 'getSupportTicketsDetails',
  initialState: {
    data: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchSupportTicketsDetails.pending]: (state: any, action: any) => {
      state.status = 'pending';
    },
    [fetchSupportTicketsDetails.fulfilled]: (state: any, action: any) => {
      state.status = 'success';
      state.data = action.payload;
    },
    [fetchSupportTicketsDetails.rejected]: (state: any, action: any) => {
      state.status = 'rejected';
    },
  },
});

export default queueSlice.reducer;
