/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import axios from 'axios';
import useAuthenticated from '../hooks/useAuthenticated';

const PrivateRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const authenticated = useAuthenticated();
  const auth = { token: localStorage.getItem('token') ? true : false };
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/api/getUserDetails`,
          method: 'POST',
          data: {},
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (Object.prototype.hasOwnProperty.call(response.data, 'error')) {
          setIsAuthorized(false);
        } else {
          setIsAuthorized(true);
        }
      } catch (error) {
        setIsAuthorized(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (auth.token) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [location.pathname]);
  // const {isOnline, isRealOnline} = useNetworkStatus()
  // if(isOnline === false || isRealOnline === false){
  //   return (
  //     <main className='main'>
  //       <div className='text-white'>
  //         No Internet connection Please refresh the page
  //       </div>
  //     </main>
  //   )
  // }

  if (authenticated.token.length === 0) {
    return <Navigate to='/auth' />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthorized) {
    return <Navigate to='/auth' />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
