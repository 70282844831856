export function removeQueryString(key: any) {
  // Get the current URL
  const url = new URL((window as any).location);

  // Delete the query string parameter
  url.searchParams.delete(key);

  // Update the URL without reloading the page
  window.history.pushState({}, '', url);
}

export function addQueryString(key: any, value: any) {
  // Get the current URL
  const url = new URL((window as any).location);

  // Set the query string parameter
  url.searchParams.set(key, value);

  // Update the URL without reloading the page
  window.history.pushState({}, '', url);
}

export const getColor = (action: any) => {
  switch (action) {
    case 'update':
      return '#f08921';
    case 'add':
      return '#238636';
    case 'delete':
      return '#fd2525';
    case 'active':
      return '#6db1bc';
    case 'inactive':
      return '#8957e5';
    default:
      return '#888888'; // Fallback color
  }
};

export const popluarPlugins = [
  {
    name: 'Yoast SEO',
    plugin: 'yoast-seo',
  },
  {
    name: 'Elementor',
    plugin: 'elementor',
  },
  {
    name: 'WooCommerce',
    plugin: 'woocommerce',
  },
  {
    name: 'Contact Form 7',
    plugin: 'contact-form-7',
  },
  {
    name: 'WPForms',
    plugin: 'wpforms-lite',
  },
];
