/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faQuestion,
  faInfoCircle,
  faWarning,
  faExclamationTriangle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Link, createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useUserDetailsQuery,
  useAddQueueMutation,
  usePluginDataQuery,
  useAddEnviornmentQueueMutation,
  useStoreWpPluginMutation,
  useWordpressPluginSyncMutation,
  useEnvironmentChangeMutation,
} from '../features/api';
import { ComposerInterface } from '../Interface/appInterface';
import { useAppSelector, useCheckWp, useWpData } from '../features/hooks';
import { Buffer } from 'buffer';
import Loader from './Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Steps } from 'intro.js-react';
import { fetchProjectDetails } from '../features/projectdetailSlice';
import { useDispatch } from 'react-redux';
import { fetchWpPluginData } from '../features/getWpPluginData';
import { useTranslation } from 'react-i18next';
import CommonModal from './CommonModal';
import { useDeleteQueryString, useManageQueryString, useUpdateQueryString } from '../utils/queryStringFunc';
import { fetchQueueData } from '../features/queuedata';
import { updateTrigger } from '../features/introArray';
import { fetchEnvChangeaData } from '../features/getEnvironmentChanges';
import CommonAlert from './CommonAlert';
import { getColor } from '../utils/differentFunctions';
import { setQueueNumber } from '../features/notifyNumbers';
import ErrorPage from './ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
import { useMoveEnvMutation } from '../features/api';

const envList = ['Production', 'Staging', 'QA'];
const ProjectDetails: React.FC = () => {
  const [page, setCurentpage] = useState<number>(1);
  const [perPage, setPerpage] = useState<number>(5);
  const userPlugins = useAppSelector((state: any) => state.userplugins.value);
  const [syncQueue] = useAddQueueMutation();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [editedComposer, setEditedComoser] = useState(Object);
  const [environment, setEnvironment] = useState('0');
  const [totalPages, setTotalPages] = useState<number>(5);
  const [allVersions, setAllVersions] = useState<Array<any>>([]);
  const [pageItems, setPageItems] = useState<any>([]);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [storeRequire, setStoreRequire] = useState<Array<string>>([]);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [showUnAvailable, setShowUnAvailable] = useState<boolean>(false);
  const [showUpdateAvailable, setShowUpdateAvailable] = useState<boolean>(false);
  const [showPlugin, setShowPlugin] = useState<boolean>(false);
  const [showTheme, setShowTheme] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [showQueue, setShowQueue] = useState<boolean>(false);
  const [addListOfUpdate, setAddListOfUpdate] = useState<Array<any>>([]);
  const [envP, setEnvP] = useState<string>('');
  const [storeMeta, setStoreMeta] = useState<string>('');
  const [siteData, setSitedata] = useState<any>();
  const [plugins, setPlugins] = useState<any>([]);
  const [getStoreMeta, setGetStoreMeta] = useState<any>({});
  const [confEnv, setConfEnv] = useState<any>({
    username: '',
    password: '',
    composer_file: null,
    composer_require_array: [],
    composer_meta: {},
    authentication_file: null,
    auth: {},
    wp_url: '',
    composer_lock_file: null,
    composer_lock: {},
  });
  const [isFileAdded, setIsFileAdded] = useState<string>('');
  const [isFtpFileAdded, setIsFtpFileAdded] = useState<string>('');
  const [isSshFileAdded, setIsSshFileAdded] = useState<string>('');
  const [file, setFile] = useState<string>('');
  const [fileFtp, setFileFtp] = useState<string>('');
  const [fileSsh, setFileSsh] = useState<string>('');
  const [getMeta, setGetMeta] = useState<any>({});
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [checkboxFtp, setCheckboxFtp] = useState<boolean>(false);
  const [checkboxSsh, setCheckboxSsh] = useState<boolean>(false);
  const [updateNotifyModal, setUpdateNotifyModal] = useState<boolean>(false);
  const [storeQueueData, setStoreQueueData] = useState<any>({});
  const [isFileAddedOne, setIsFileAddedOne] = useState<string>('');
  const [fileOne, setFileOne] = useState<string>('');
  const [isFileAuthAdded, setIsFileAuthAdded] = useState<string>('');
  const [fileTwo, setFileTwo] = useState<string>('');
  const [fileThree, setFileThree] = useState<string>('');
  const [isLockFileAdded, setIsLockFileAdded] = useState<string>('');
  const [availableFilterPlugin, setAvailableFilterPlugin] = useState<Array<any>>([]);
  const navigate = useNavigate();
  const [item, setItem] = useState<any>({
    version: '',
    php_min: '',
    php_max: '',
    wp_min: '',
    wp_max: '',
    otherdependancy: '',
  });
  const [pluginData, setPluginData] = useState<any>({
    name: '',
    slug: '',
    image: '',
    file: null,
    type: 'private',
    URL: '',
    wp_author: '',
    short_description: '',
    description: '',
    version: [],
    flag: 'create',
  });
  const [privatePackages, setPrivatePackage] = useState<Array<any>>([]);
  const [privatePackagesModal, setPrivatePackageModal] = useState<boolean>(false);
  const [isSyncLoading, setIsSyncLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const queryString = new URLSearchParams(window.location.search);
  const envName: any = queryString.get('environment');
  const check = useCheckWp;
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [createEnv, setCreateEnv] = useState<any>({
    environment_name: '',
    site_url: '',
  });
  const [openEnvConfigModal, setOpenEnvConfigModal] = useState(false);

  const { data: userDetails } = useUserDetailsQuery({});
  const [createPrivateObject, setCreatePrivateObject] = useState<any>({});

  const pathname = window.location.pathname;
  const [stepsEnabled, setStepsEnabled] = useState<any>(null);
  const [getQueueByEnv, setGetQueueByEnv] = useState<any>({});
  const [notification, setNotification] = useState<any>(Number);
  const [addEnviornmentQueue] = useAddEnviornmentQueueMutation();

  const [wpError, setWpError] = useState<any>({});
  const [composerError, setComposerError] = useState<any>({});
  const [ftpError, setFtpError] = useState<any>({});
  const [sshError, setSshError] = useState<any>({});
  const [addEnvError, setEnvError] = useState<any>({});
  const [clickMoreData, setClickMoreData] = useState<boolean>(false);
  const [seeMoreData, setSeeMoreData] = useState<string>('');
  const [newWpPlugin, setNewWpPlugin] = useState<Array<any>>([]);
  const [isLoading1, setIsLoading1] = useState(true); // Initially show loading spinner
  const [isDisabled, setIsDisabled] = useState(false);
  const [wordpressPluginSync] = useWordpressPluginSyncMutation();
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const [newPluginsChanges, setNewPluginsChanges] = useState(false);
  const [privatePlugDisable, setPrivatePlugDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disable, setDisabled] = useState<boolean>(false)
  const [clickToVersion, setClickToVersion] = useState<() => void>(() => {});
  const [openVersionChangesPopup, setOpenVersionChangesPopup] = useState<boolean>(false)
  const [selectedEnvironments, setSelectedEnvironments] = useState({
    env1: '', 
    env2: '',
  })
  const [projectId, setProjectId] = useState('')

  // const queryString = new URLSearchParams(window.location.search)
  const location = useLocation();
  const deleteQueryString = useDeleteQueryString();
  const updateQueryString = useUpdateQueryString();
  const manageQueryString = useManageQueryString();
  const [EnvironmentChange] = useEnvironmentChangeMutation();
  const [moveProject] = useMoveEnvMutation();
  const introArray = useAppSelector(state => state.introArray.arrayData);
  const [projectConfWpDisableButton, setProjectConfWpDisableButton] = useState<boolean>(false);
  const [projectConfCompDisableButton, setProjectConfCompDisableButton] = useState<boolean>(false);
  // console.log("introArray",introArray)

  useEffect(() => {
    // console.log("introArray",introArray)
    if (introArray.length > 0) {
      const getspecificdata = introArray.find(ik => ik.page === 'project-details');
      if (getspecificdata !== undefined) {
        if (getspecificdata.trigger === true) {
          setStepsEnabled(true);
        }
      }
    }
  }, [introArray]);

  // useEffect(() => {

  //   console.log('hello',location.search)
  //   if(location.search.length > 0){
  //     if(queryString.get("intro")){
  //       setStepsEnabled(true)
  //     }
  //   }

  // },[location.search])

  const wpdata = useWpData;
  const { t } = useTranslation();
  let initialSteps = 0;
  let ComposerProjectDetailsSteps = [
    {
      title: t('intro.project-details.first.title'),
      // element: ".new-project-first",
      position: 'right',
      intro: t('intro.project-details.first.desc'),
    },
    {
      title: t('intro.project-details.two.title'),
      element: '.composer-project-details-first',
      position: 'left',
      intro: t('intro.project-details.two.desc'),
    },
    {
      title: t('intro.project-details.three.title'),
      element: '.composer-project-details-second',
      position: 'left',
      intro: t('intro.project-details.three.desc'),
    },
    {
      title: t('intro.project-details.four.title'),
      element: '.composer-project-details-third',
      position: 'left',
      intro: t('intro.project-details.four.desc'),
    },
    {
      title: t('intro.project-details.five.title'),
      element: '.composer-project-details-fourth',
      position: 'left',
      intro: t('intro.project-details.five.desc'),
    },
  ];

  let { projectid, type } = useParams();
  const [showPopover, setShowPopover] = useState(false);
  const [conditionalButtons, setConditionalButtons] = useState<'newplugin' | 'update'>('update');
  const [storeWpPlugin] = useStoreWpPluginMutation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading1(false); // Hide spinner after 20 seconds
    }, 13000); // 20 seconds in milliseconds

    return () => clearTimeout(timeoutId); // Cleanup function to prevent memory leaks
  }, []);

  function deepEqual(object1: any, object2: any) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }
    return true;
  }
  function isObject(object: Object) {
    return object != null && typeof object === 'object';
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProjectDetails(projectid));
    dispatch(fetchQueueData());
  }, [dispatch, projectid]);

  useEffect(() => {
    if (envP !== '') {
      dispatch(
        fetchEnvChangeaData({
          flag: 'get',
          env_id: envP,
        })
      );
    }
  }, [dispatch, envP]);

  const { data: projectDetails, isLoading, isError } = useAppSelector((state: any) => state.projects);
  const {
    data: queueData,
    isLoading: queueDataLoading,
    isError: queueDataError,
  } = useAppSelector((state: any) => state.queueData);
  const {
    data: envChanges,
    isLoading: envLoading,
    isError: envError,
  } = useAppSelector((state: any) => state.envChangesData);
  const { data: wpData, isLoading: wpDataLoading, isError: wpDataError } = useAppSelector((state: any) => state.wpData);
  const [openEnvChangesPopup, setOpenEnvChangesPopup] = useState<boolean>(false);
  // console.log("envChanges",envChanges,envChanges)

  // const { data: projectDetails, isLoading } = useProjectQuery({
  //   id: projectid,
  //   data: {},
  // });
  const project = projectDetails?.project;
  const environmentList: any = project ? project?.environment : [];

  useEffect(() => {
    if (typeof project !== 'undefined') {
      setGetStoreMeta({});
      if (project.meta !== null) {
        const projectFind = project.meta.find((ik: any) => {
          return ik.id === parseInt(envName);
        });
        if (projectFind !== undefined) {
          setEnvP(projectFind.id);
          setGetMeta(projectFind);
          if (projectFind.meta !== null) {
            const parseMeta = JSON.parse(projectFind.meta);

            setGetStoreMeta(parseMeta);
            if (parseMeta.type === 'wp') {
              setSitedata({
                siteUrl: projectFind.siteurl || '',
                appPassword: atob(parseMeta?.app_password) || '',
                userName: parseMeta.username || '',
              });
            } else if (parseMeta.type === 'composer') {
              if (Object.prototype.hasOwnProperty.call(parseMeta.composer, 'repositories')) {
                const privatePackage = Object.values(parseMeta.composer.repositories);

                const privatefilter = privatePackage.filter((ikk: any) => {
                  return ikk.type === 'package';
                });

                if (privatePackage.length > 0) {
                  setPrivatePackage(privatefilter);
                }
              }
              setSitedata({
                siteUrl: '',
                appPassword: '',
                userName: '',
              });
            }
          }
        }
      }
    }
  }, [envName, project, showSettings, openEnvConfigModal]);

  useEffect(() => {
    if (Object.keys(getMeta).length > 0) {
      const meta = JSON.parse(getMeta.meta);
      // console.log("meta", meta)
      if (meta !== null) {
        if (meta.type === 'wp') {
          dispatch(fetchWpPluginData(getMeta.id));
        }
      }
    }
  }, [dispatch, getMeta]);

  useEffect(() => {
    if (queueData !== null) {
      dispatch(setQueueNumber(queueData.length));
    }
  }, [queueData]);

  useEffect(() => {
    const meta: any = project ? project?.meta : [];
    if (meta !== null) {
      const findMeta = meta.find((j: any) => {
        return j.id === envP && j.meta !== null;
      });
      if (findMeta !== undefined) {
        setStoreMeta(findMeta.meta);
        const requireMeta = JSON.parse(findMeta.meta);
        if (requireMeta.type === 'composer') {
          if (Object.prototype.hasOwnProperty.call(requireMeta.composer, 'repositories')) {
            const privatePackage = Object.values(requireMeta.composer.repositories);

            const privatefilter = privatePackage.filter((ikk: any) => {
              return ikk.type === 'package';
            });
            // console.log('privatefilter', privatefilter);
            if (privatefilter.length > 0) {
              const privatePack = privatefilter.map((iik: any) => {
                return iik.package.name;
              });
              const objectReduce = privatefilter.reduce((result: any, obj: any) => {
                result[obj.package.name] = obj.package.version;
                return result;
              }, {});
              // console.log("objectReduce",objectReduce);
              setCreatePrivateObject(objectReduce);
              setStoreRequire([...Object.keys(requireMeta.composer.require), ...privatePack]);
            } else {
              setCreatePrivateObject({});
              setStoreRequire(Object.keys(requireMeta.composer.require));
            }
          } else {
            setCreatePrivateObject({});
            setStoreRequire(Object.keys(requireMeta.composer.require));
          }
        }
      }
    }
  }, [envP, getStoreMeta]);
  // console.log("store",storeRequire);

  useEffect(() => {
    if (queueData !== null) {
      if (queueData.length > 0) {
        const addqueue = queueData.find((i: any) => {
          return i.project.project_id === parseInt(projectid as any) && i.enviornment.id === parseInt(envP);
        });
        if (addqueue !== undefined) {
          setStoreQueueData(addqueue);
        }
      }
    }
  }, [queueData, getStoreMeta, envP, projectid]);

  function downloadComposer(id: number, name: string, composer: ComposerInterface): void {
    if (storeMeta.length > 0) {
      let oc = JSON.parse(storeMeta);
      let ocComposer = oc.composer;
      const buttonId: HTMLElement | null = document.getElementById(`composer${id}`);
      var composerData = 'text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(ocComposer, null, 4));
      (buttonId as HTMLAnchorElement).href = 'data:' + composerData;
      (buttonId as HTMLAnchorElement).download = `composer-${name}.json`;
    } else {
      toast.error('Please select minimum one environement');
    }
  }

  const getObjectOfString: Array<any> = useMemo<Array<any>>(() => {
    return storeRequire.map((kk: any) => {
      const ab = kk.split('/');
      if (ab.length === 1) {
        return {
          type: '',
          plugin: ab[0],
        };
      } else {
        return {
          type: ab[0],
          plugin: ab[1],
        };
      }
    });
  }, [storeRequire]);

  // const getLatestVersion = (pluginData: any): string => {
  //   if (pluginData && pluginData.versions && pluginData.versions.versions.length > 0) {
  //     const versions = pluginData.versions.versions;

  //     // Sort versions in descending order based on version numbers
  //     const sortedVersions = versions.slice().sort((a: any, b: any) => compareVersion(b.version, a.version));

  //     // Get the highest version number
  //     console.log("sort",sortedVersions)
  //     const highestVersion = sortedVersions[0].version;
  //     console.log("high",highestVersion)

  //     // Return "Latest" if the highest version is not an empty string, otherwise return the version number
  //     return highestVersion !== '' ? `Latest` : `v${highestVersion}`;
  //   }

  //   // Return an empty string if no versions are available
  //   return '';
  // };

  const getVersionOne = (pluginData: any): string => {
    // console.log("pluginData",pluginData);
    if (Object.keys(createPrivateObject).length > 0) {
      if (Object.keys(getStoreMeta).length > 0) {
        if (getStoreMeta.type === 'composer') {
          const convertJson = getStoreMeta;
          const abc: any =
            typeof convertJson.composer !== 'undefined'
              ? Object.keys({ ...convertJson.composer.require, ...createPrivateObject }).find(i => {
                  if (pluginData.type === null) {
                    return i === pluginData.plugin_slug;
                  } else {
                    return i === `${pluginData.type}/${pluginData.plugin_slug}`;
                  }
                })
              : '';
          //  console.log("{...convertJson.composer.require,...createPrivateObject}",{...convertJson.composer.require,...createPrivateObject});
          return { ...convertJson.composer.require, ...createPrivateObject }[abc];
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      if (Object.keys(getStoreMeta).length > 0) {
        if (getStoreMeta.type === 'composer') {
          const convertJson = getStoreMeta;
          const abc: any =
            typeof convertJson.composer !== 'undefined'
              ? Object.keys(convertJson.composer.require).find(i => {
                  if (pluginData.type === null) {
                    return i === pluginData.plugin_slug;
                  } else {
                    return i === `${pluginData.type}/${pluginData.plugin_slug}`;
                  }
                })
              : '';

          return convertJson?.composer.require[abc];
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  };

  let {
    data: availablePluginFromData,
    isLoading: avaliablePluginFromDataLoading,
    isError: avaliablePluginFromDataError,
  } = usePluginDataQuery(getObjectOfString);
  const listAvailablity: any = useMemo<any>(() => {
    if (typeof availablePluginFromData !== 'undefined') {
      if (availablePluginFromData.length > 0) {
        let list = [...availablePluginFromData]
          .filter((kb: any) => {
            if (showUnAvailable) {
              return kb.status === '0';
            } else {
              return kb;
            }
          })
          .filter(
            (item: { type: string; plugin_slug: string }) =>
              (item?.type?.toLowerCase() || '').includes(search?.toLowerCase() || '') ||
              (item?.plugin_slug?.toLowerCase() || '').includes(search?.toLowerCase() || '')
          )
          .sort((a: any, b: any) => {
            if (a.status === '1' && b.status === '0') {
              return -1;
            } else if (a.status === '0' && b.status === '1') {
              return 1;
            } else {
              return 0;
            }
          })
          .filter(ab => {
            return ab.status !== '0';
          })
          .map((kk: any) => {
            if (typeof allVersions !== 'undefined') {
              if (allVersions.length > 0) {
                const getPlugins = allVersions?.find(i => {
                  return i.plugin === kk.plugin_slug;
                });
                const newVersion =
                  typeof getPlugins !== 'undefined'
                    ? getPlugins.versions.map((kka: any) => {
                        if (
                          compareVersions(
                            kka.version,
                            getVersionOne(kk) !== undefined ? getVersionOne(kk)?.replace(/\^/g, '') : ''
                          )
                        ) {
                          return kka.version;
                        } else {
                          return null;
                        }
                      })
                    : [];
                const getActualversion = newVersion?.filter((kb: any) => kb !== null);

                return {
                  ...kk,
                  versions: getPlugins !== undefined ? getPlugins : {},
                  updated_version: getActualversion,
                  inqueue:
                    Object.keys(storeQueueData).length > 0
                      ? storeQueueData.plugins.some((ikkk: any) => {
                          return ikkk.slug === kk.plugin_slug;
                        })
                      : false,
                  dataType: kk.flag,
                  plugin_status: getActualversion.length > 0 ? '3' : '4',
                };
              } else {
                return kk;
              }
            } else {
              return kk;
            }
          });
        const filterUpdateAvailable = list && list.filter(item => item.plugin_status === '3');
        const filterPlugin = list && list.filter(item => item.flag === 'plugin');
        const filterTheme = list && list.filter(item => item.flag === 'theme');
        const filterQueue = list && list.filter(item => item.inqueue === true);
        return showUpdateAvailable
          ? filterUpdateAvailable
          : showPlugin
          ? filterPlugin
          : showTheme
          ? filterTheme
          : showQueue
          ? filterQueue
          : list;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [
    availablePluginFromData,
    search,
    showUnAvailable,
    allVersions,
    showUpdateAvailable,
    showTheme,
    showPlugin,
    showQueue,
    getStoreMeta,
  ]);
  console.log('listAvailablity', listAvailablity);
  const wpListAvailablity = useMemo<Array<any>>(() => {
    if (plugins.length > 0) {
      const allList = plugins
        .filter(
          (item: { name: string; plugin: string }) =>
            (item?.name?.toLowerCase() || '').includes(search?.toLowerCase() || '') ||
            (item?.plugin?.toLowerCase() || '').includes(search?.toLowerCase() || '')
        )
        .map((kk: any) => {
          if (typeof allVersions !== 'undefined') {
            if (allVersions.length > 0) {
              const getPlugins = allVersions?.find(i => {
                return i.plugin === kk.plugin.split('/')[0];
              });

              const newVersion =
                typeof getPlugins !== 'undefined'
                  ? getPlugins.versions.map((kka: any) => {
                      if (compareVersions(kka.version, kk.version)) {
                        return kka.version;
                      } else {
                        return null;
                      }
                    })
                  : [];
              const getActualversion = newVersion?.filter((kb: any) => kb !== null);
              return {
                ...kk,
                versions: getPlugins !== undefined ? getPlugins : {},
                updated_version: getActualversion,
                inqueue:
                  Object.keys(storeQueueData).length > 0
                    ? storeQueueData.plugins.some((ikkk: any) => {
                        return ikkk.slug === kk.plugin.split('/')[0];
                      })
                    : false,
                dataType: 'plugin',
                plugin_status: getActualversion.length > 0 ? '3' : '4',
              };
            } else {
              return kk;
            }
          } else {
            return kk;
          }
        });
      const filterUpdateAvailable = allList && allList.filter((item: any) => item.plugin_status === '3');
      console.log('filterUpdateAvailable', filterUpdateAvailable);
      setAvailableFilterPlugin(filterUpdateAvailable);
      const filterPlugin = allList && allList.filter((item: any) => item.dataType === 'plugin');
      const filterTheme = allList && allList.filter((item: any) => item.dataType === 'theme');
      const filterQueue = allList && allList.filter((item: any) => item.inqueue === true);
      return showUpdateAvailable
        ? filterUpdateAvailable
        : showPlugin
        ? filterPlugin
        : showTheme
        ? filterTheme
        : showQueue
        ? filterQueue
        : allList;
      // return allList;
    } else {
      return [];
    }
  }, [plugins, getStoreMeta, allVersions, search, showUpdateAvailable, showTheme, showPlugin, showQueue]);
  // console.log("pageit",pageItems)
  useEffect(() => {
    if (Object.keys(getStoreMeta).length > 0) {
      if (getStoreMeta.type === 'wp') {
        // const startIndex = (page - 1) * perPage;
        // const endIndex = startIndex + perPage;
        // setTotalPages(Math.ceil(wpListAvailablity.length / perPage));
        // const currentData = wpListAvailablity.slice(startIndex, endIndex);
        const currentData = wpListAvailablity;
        setPageItems(currentData);
      } else {
        // const startIndex = (page - 1) * perPage;
        // const endIndex = startIndex + perPage;
        // setTotalPages(Math.ceil(listAvailablity.length / perPage));
        // const currentData = listAvailablity.slice(startIndex, endIndex);
        const currentData = listAvailablity;
        setPageItems(currentData);
      }
    }
  }, [page, listAvailablity, perPage, plugins, getStoreMeta, wpListAvailablity]);

  const addInQueue = () => {
    let data = {
      project: projectid,
      env: environment,
      composer: editedComposer,
    };
    syncQueue(data);
  };

  const handleEnviornmentChangeSelect = (e: any): void => {
    if (typeof project !== 'undefined') {
      setGetStoreMeta({});
      setEnvP('');
      setGetMeta({});
      setSitedata({
        siteUrl: '',
        appPassword: '',
        userName: '',
      });
      setCreatePrivateObject({});
      setPrivatePackage([]);
      const projectFind = project.meta.find((ik: any) => {
        return ik.id === parseInt(e);
      });
      if (projectFind !== undefined) {
        setEnvP(projectFind.id);
        navigate(`?environment=${projectFind.id}`);
        setGetMeta(projectFind);
        if (projectFind.meta !== null) {
          const parseMeta = JSON.parse(projectFind.meta);

          setGetStoreMeta(parseMeta);
          if (parseMeta.type === 'wp') {
            setCreatePrivateObject({});
            setSitedata({
              siteUrl: projectFind.siteurl || '',
              appPassword: atob(parseMeta?.app_password) || '',
              userName: parseMeta.username || '',
            });
          } else if (parseMeta.type === 'composer') {
            if (Object.prototype.hasOwnProperty.call(parseMeta.composer, 'repositories')) {
              const privatePackage = Object.values(parseMeta.composer.repositories);

              const privatefilter = privatePackage.filter((ikk: any) => {
                return ikk.type === 'package';
              });
              const objectReduce = privatefilter.reduce((result: any, obj: any) => {
                result[obj.package.name] = obj.package.version;
                return result;
              }, {});
              // console.log("objectReduce",objectReduce);
              setCreatePrivateObject(objectReduce);
              if (privatePackage.length > 0) {
                setPrivatePackage(privatefilter);
              }
              setSitedata({
                siteUrl: '',
                appPassword: '',
                userName: '',
              });
            } else {
              setCreatePrivateObject({});

              setSitedata({
                siteUrl: '',
                appPassword: '',
                userName: '',
              });
            }
          }
        }
      }
    }
  };

  const handleEnviornmentMove = (e: any, dropdown:any): void => {
    setDisabled(true)
    if (typeof project !== 'undefined') {
      const projectFind = project.meta.find((ik: any) => {
        return ik.id === parseInt(e);
      });
      if (projectFind !== undefined) {
        const data = {
          source: envP,
          destination: projectFind.id,
          projectId:project.id
        };
        if(data.source !== '' && data.destination !== ''){
          moveProject(data).then((res: any) => {
            if (res) {
              toast.success(res.data.Message);
              setDisabled(false)
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    if (getMeta?.meta === null || getMeta?.meta === undefined) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [getMeta]);
  // console.log("00000", isDisabled)

  useEffect(() => {
    // console.log('siteData', siteData)
    if (siteData !== undefined) {
      // console.log('urlll????/',siteData.siteUrl)
      if (siteData.siteUrl.length > 0) {
        if (wpData !== null) {
          getPlugins();
          setConditionalButtons('newplugin');
        }
      }
    } else {
      // console.log("nhk")
    }
  }, [siteData, wpData]);

  const getPlugins = async () => {
    const encodedCredentials = Buffer.from(`${siteData?.userName}:${siteData?.appPassword}`).toString('base64');
    // console.log("ecccc", encodedCredentials)
    const headers = {
      Authorization: `Basic ${encodedCredentials}`,
    };
    if (Array.isArray(wpData) && wpData.length > 0) {
      try {
        const Plugins = await axios.get(`${siteData?.siteUrl}/wp-json/wp/v2/plugins`, { headers });

        //  console.log("ppppp",Plugins)

        const datas = Plugins.data.filter((i: any) =>
          wpData.some((k: any) => {
            return i.plugin.split('/')[0] === k.type && i.version === k.version;
          })
        );

        const newPlugin = Plugins.data.filter((i: any) => {
          return !wpData.some((k: any) => {
            return i.plugin.split('/')[0] === k.type && i.version === k.version;
          });
        });
        // console.log("hello world ",newPlugin, datas)
        setNewWpPlugin(newPlugin);
        setPlugins(datas);
      } catch (err) {
        console.log('err', err);
        toast.error('There is problem with api');
      }
    }
  };

  const addPlugin = async (slug: any) => {
    const encodedCredentials = Buffer.from(`${siteData?.userName}:${siteData?.appPassword}`).toString('base64');
    const headers = {
      Authorization: `Basic ${encodedCredentials}`,
    };
    let data = {
      slug: slug,
      status: 'active',
    };
    const Result = await axios.get(`${siteData?.siteUrl}/wp-json/wp/v2/plugins`, { headers });
    if (Result) {
      getPlugins();
    }
  };

  const deletePlugin = async (plugin: any) => {
    // console.log("version",plugin, plugin.version)
    const getPlugFind = envChanges.find((i: any) => {
      return i.plugin.slug === plugin.plugin.split('/')[0];
    });
    if (getPlugFind !== undefined) {
      const pluginDataArray = [];
      const newVersion = plugin.version;
      const pluginData = {
        plugin_slug: plugin.plugin.split('/')[0],
        plugin_version: newVersion,
        env_id: envP,
        project_id: projectid,
        version: plugin.version,
        action: 'delete',
        flag: 'customUpdate',
      };

      pluginDataArray.push(pluginData);
      EnvironmentChange(pluginData).then((res: any) => {
        if (res.data) {
          toast.success(res.data.message);
          dispatch(
            fetchEnvChangeaData({
              flag: 'get',
              env_id: envP,
            })
          );
        }
      });
    } else {
      const pluginDataArray = [];
      const newVersion = plugin.version;
      const pluginData = {
        plugin_slug: plugin.plugin.split('/')[0],
        plugin_version: newVersion,
        env_id: envP,
        project_id: projectid,
        version: plugin.version,
        action: 'delete',
        flag: 'add',
      };

      pluginDataArray.push(pluginData);
      EnvironmentChange({ data: pluginDataArray, flag: 'add' }).then((res: any) => {
        if (res.data) {
          toast.success(res.data.message);
          dispatch(
            fetchEnvChangeaData({
              flag: 'get',
              env_id: envP,
            })
          );
        }
      });
    }

    // const encodedCredentials = Buffer.from(`${siteData?.userName}:${siteData?.appPassword}`).toString('base64');
    // const headers = {
    //   Authorization: `Basic ${encodedCredentials}`,
    // };

    // try {
    //   const Result = await axios.delete(`${siteData?.siteUrl}/wp-json/wp/v2/plugins/${plugin.plugin}`, { headers });
    //   if (Result) {
    //     let data = {
    //       status: 'deleted',
    //     };
    //     toast.success(`${plugin.plugin} successfuly deleted`)
    //     // changeLocalStatus(plugin, data, "delete")
    //     getPlugins();
    //   }
    // } catch (err: any) {
    //   console.log("err", err)
    //   toast.error(err.response.data.message)
    // }
  };

  const updateStatus = async (plugin: any) => {
    // console.log("plugin",plugin, storeQueueData,envChanges)

    if (Object.keys(envChanges).length > 0) {
      const getPlugFind = envChanges.find((i: any) => {
        return i.plugin.slug === plugin.plugin.split('/')[0];
      });
      if (getPlugFind !== undefined) {
        const pluginDataArray = [];
        const newVersion = plugin.version;
        const pluginData = {
          plugin_slug: plugin.plugin.split('/')[0],
          plugin_version: newVersion,
          env_id: envP,
          project_id: projectid,
          version: plugin.version,
          action: plugin.status === 'active' ? 'inactive' : 'active',
          flag: 'customUpdate',
        };
        pluginDataArray.push(pluginData);
        EnvironmentChange(pluginData).then((res: any) => {
          if (res.data) {
            toast.success(res.data.message);
            dispatch(
              fetchEnvChangeaData({
                flag: 'get',
                env_id: envP,
              })
            );
          }
        });
      } else {
        const pluginDataArray = [];
        const newVersion = plugin.version;
        const pluginData = {
          plugin_slug: plugin.plugin.split('/')[0],
          plugin_version: newVersion,
          env_id: envP,
          project_id: projectid,
          version: plugin.version,
          action: plugin.status === 'active' ? 'inactive' : 'active',
          flag: 'add',
        };
        pluginDataArray.push(pluginData);
        EnvironmentChange({ data: pluginDataArray, flag: 'add' }).then((res: any) => {
          if (res.data) {
            toast.success(res.data.message);
            dispatch(
              fetchEnvChangeaData({
                flag: 'get',
                env_id: envP,
              })
            );
          }
        });
      }
    } else {
      const pluginDataArray = [];
      const newVersion = plugin.version;
      const pluginData = {
        plugin_slug: plugin.plugin.split('/')[0],
        plugin_version: newVersion,
        env_id: envP,
        project_id: projectid,
        version: plugin.version,
        action: plugin.status === 'active' ? 'inactive' : 'active',
        flag: 'add',
      };
      pluginDataArray.push(pluginData);
      EnvironmentChange({ data: pluginDataArray, flag: 'add' }).then((res: any) => {
        if (res.data) {
          toast.success(res.data.message);
          dispatch(
            fetchEnvChangeaData({
              flag: 'get',
              env_id: envP,
            })
          );
        }
      });
    }

    // const encodedCredentials = Buffer.from(`${siteData?.userName}:${siteData?.appPassword}`).toString('base64');
    // const headers = {
    //   Authorization: `Basic ${encodedCredentials}`,
    // };
    // let data = {
    //   slug: plugin.plugin.split('/')[1],
    //   status: plugin.status === 'active' ? 'inactive' : 'active',
    // };

    // const Result = await axios.put(`${siteData?.siteUrl}/wp-json/wp/v2/plugins/${plugin.plugin}`, data, { headers });
    // if (Result) {
    //   toast.success(`${plugin.plugin} status changed to ${data.status}`)
    //   // changeLocalStatus(plugin, data, "update")
    //   getPlugins();
    // }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurentpage(pageNumber);
  };
  const handleSearch = (event: any) => {
    setCurentpage(1);
    setSearch(event.target.value);
  };

  async function getAllValues(): Promise<Array<any>> {
    if (Object.keys(getStoreMeta).length > 0) {
      if (getStoreMeta.type === 'wp') {
        const versions = plugins.map((k: any) => {
          return {
            slug: k.plugin.split('/')[0],
          };
        });
        const data = await axios({
          url: `${process.env.REACT_APP_API_URL}/api/allVersion`,
          method: 'POST',
          data: {
            fp_api_key: process.env.REACT_APP_FP_API_KEY,
            slug: versions,
          },
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (Object.keys(data).length > 0) {
          const allver = data.data.map((k: any) => {
            if (Object.prototype.hasOwnProperty.call(k, 'items')) {
              return { plugin: k.plugin_name, versions: k.items };
            } else {
              return {};
            }
          });
          const filterversions = allver.filter((ki: any) => Object.keys(ki).length > 0);

          return filterversions;
        } else {
          return [];
        }
      } else {
        const versions = listAvailablity.map((k: any) => {
          return {
            slug: k.plugin_slug,
          };
        });
        const data = await axios({
          url: `${process.env.REACT_APP_API_URL}/api/allVersion`,
          method: 'POST',
          data: {
            fp_api_key: process.env.REACT_APP_FP_API_KEY,
            slug: versions,
          },
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (Object.keys(data).length > 0) {
          const allver = data.data.map((k: any) => {
            if (Object.prototype.hasOwnProperty.call(k, 'items')) {
              return { plugin: k.plugin_name, versions: k.items };
            } else {
              return {};
            }
          });
          const filterversions = allver.filter((ki: any) => Object.keys(ki).length > 0);

          return filterversions;
        } else {
          return [];
        }
      }
    } else {
      return [];
    }
  }

  useEffect(() => {
    if (Object.keys(getStoreMeta).length > 0) {
      if (getStoreMeta.type === 'composer') {
        if (listAvailablity.length > 0) {
          getAllValues().then(ik => {
            setAllVersions(ik);
          });
        }
      } else if (getStoreMeta.type === 'wp') {
        if (plugins.length > 0) {
          getAllValues().then(ik => {
            setAllVersions(ik);
          });
        }
      }
    }
  }, [listAvailablity.length > 0, plugins, getStoreMeta]);

  function compareVersions(version1: string, version2: string): boolean {
    const v1 = version1.split('.');
    const v2 = version2.split('.');

    for (let i = 0; i < v1.length; i++) {
      const num1 = parseInt(v1[i]);
      const num2 = parseInt((v2[i] as any) || 0);

      if (num1 > num2) {
        return true;
      } else if (num1 < num2) {
        return false;
      }
    }

    return false;
  }

  function openNotification(): void {
    // console.log("getUpdateVersionPlugin",getUpdateVersionPlugin,)
    // if(envChanges.length > 0){
    if (getStoreMeta.type === 'wp') {
      const findCommon = getUpdateVersionPlugin.filter((ik: any) => {
        return !envChanges.some((ikk: any) => {
          return ikk.plugin.slug === ik.plugin_name.split('/')[0];
        });
      });

      if (findCommon.length > 0) {
        const findCommonfilter = findCommon.map(ik => {
          const ab1 = {
            plugin_slug: ik.plugin_name.split('/')[0],
            plugin_version: ik.latest_version,
            env_id: envP,
            project_id: projectid,
            version: ik.plugin_details.version,
            action: 'update',
            flag: 'add',
          };
          return ab1;
        });
        EnvironmentChange({ data: findCommonfilter, flag: 'add' }).then((res12: any) => {
          // console.log("res12",res12)
          dispatch(
            fetchEnvChangeaData({
              flag: 'get',
              env_id: envP,
            })
          );
          toast.success(res12.data.message);
          setShowNotification(true);
        });
      } else {
        setShowNotification(true);
      }
    } else if (getStoreMeta.type === 'composer') {
      const findCommon = getUpdateVersionPlugin.filter((ik: any) => {
        return !envChanges.some((ikk: any) => {
          return ikk.plugin.slug === ik.plugin_name.split('/')[1];
        });
      });
      // console.log("findCommon",findCommon)
      if (findCommon.length > 0) {
        const findCommonfilter = findCommon.map(ik => {
          const ab1 = {
            plugin_slug: ik.plugin_name.split('/')[1],
            plugin_version: ik.latest_version,
            env_id: envP,
            project_id: projectid,
            version: ik.installed_version,
            action: 'update',
            flag: 'add',
          };
          return ab1;
        });
        EnvironmentChange({ data: findCommonfilter, flag: 'add' }).then((res12: any) => {
          // console.log("res12",res12)
          dispatch(
            fetchEnvChangeaData({
              flag: 'get',
              env_id: envP,
            })
          );
          toast.success(res12.data.message);
          setShowNotification(true);
        });
      } else {
        setShowNotification(true);
      }
      // }
    }
    // setShowNotification(true);
  }

  function closeNotification(): void {
    setShowNotification(false);
  }

  const getUpdateVersionPlugin: Array<any> = useMemo<Array<any>>(() => {
    if (Object.keys(getStoreMeta).length > 0) {
      if (getStoreMeta.type === 'composer') {
        if (listAvailablity.length > 0) {
          const filterPlugin = listAvailablity.map((k: any) => {
            if (typeof allVersions !== 'undefined') {
              const getPlugins = allVersions?.find(i => {
                return i.plugin === k.plugin_slug;
              });
              if (typeof getPlugins !== 'undefined') {
                // console.log("k",k.plugin_slug);
                const newVersion =
                  typeof getPlugins !== 'undefined'
                    ? getPlugins.versions.map((kk: any) => {
                        // console.log("k",k);
                        if (
                          compareVersions(
                            kk.version,
                            getVersionOne(k) !== undefined ? getVersionOne(k).replace(/\^/g, '') : ''
                          )
                        ) {
                          return kk.version;
                        } else {
                          return null;
                        }
                      })
                    : [];
                const getActualversion = newVersion?.filter((kb: any) => kb !== null);
                return {
                  plugin_name: `${k.type}/${k.plugin_slug}`,
                  plugin_details: k,
                  installed_version: getVersionOne(k) !== null ? getVersionOne(k)?.replace(/\^/g, '') : '',
                  latest_version: getActualversion.length > 0 ? getActualversion[getActualversion.length - 1] : null,
                };
              } else {
                return undefined;
              }
            } else {
              return [];
            }
          });
          const removeUndefined = filterPlugin.filter((kk: any) => {
            return kk !== undefined;
          });
          const removeNull = removeUndefined.filter((ka: any) => {
            return ka.latest_version !== null;
          });
          return removeNull;
        } else {
          return [];
        }
      } else if (getStoreMeta.type === 'wp') {
        if (plugins.length > 0) {
          const filterPlugin = plugins.map((k: any) => {
            const getPlugins = allVersions?.find(i => {
              return i.plugin === k.plugin.split('/')[0];
            });
            if (typeof getPlugins !== 'undefined') {
              const newVersion =
                typeof getPlugins !== 'undefined'
                  ? getPlugins.versions.map((kk: any) => {
                      if (compareVersions(kk.version, k.version)) {
                        return kk.version;
                      } else {
                        return null;
                      }
                    })
                  : [];
              const getActualversion = newVersion?.filter((kb: any) => kb !== null);
              return {
                plugin_name: k.plugin,
                plugin_details: k,
                latest_version: getActualversion.length > 0 ? getActualversion[getActualversion.length - 1] : null,
              };
            } else {
              return undefined;
            }
          });
          const removeUndefined = filterPlugin.filter((kk: any) => {
            return kk !== undefined;
          });
          const removeNull = removeUndefined.filter((ka: any) => {
            return ka.latest_version !== null;
          });
          return removeNull;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [listAvailablity, allVersions, plugins, getStoreMeta, createPrivateObject]);

  const checkAll = (check: string): void => {
    if (typeof document !== 'undefined') {
      const checkboxes = document.querySelectorAll('input[id^="compcheckbox-"]');
      setIsCheckAll(!isCheckAll);
      checkboxes.forEach(checkbox => {
        if (check === 'check') {
          if (checkbox instanceof HTMLInputElement) {
            checkbox.checked = true;
            const getData = envChanges.map((item: any) => {
              return {
                plugin_slug: item.plugin.slug,
                plugin_version: item.plugin_version,
                env_id: envP,
                project_id: projectid,
                version: item.version,
                // changeVersion:newVersion,
                action: item.action,
              };
            });
            setAddListOfUpdate(getData);
          }
        } else {
          if (checkbox instanceof HTMLInputElement) {
            checkbox.checked = false;
            setAddListOfUpdate([]);
          }
        }
      });
    }
  };

  // const addQueue = (ka: any, plugin: any): void => {
  //   const nowVersion = typeof getVersionOne(plugin) !== "undefined" ? getVersionOne(plugin).replace(/\^/g, '') : plugin.version
  //   const queue: any = localStorage.getItem("composer-queue") !== null ? JSON.parse(localStorage.getItem("composer-queue")!) : []
  //   const projectObject: any = {
  //     project_name: project.slug,
  //     environment:envP,
  //     queue: []
  //   }
  //   const ab: any = {
  //     id: new Date().getTime(),
  //     plugin_name: typeof plugin.type !== "undefined" ? `${plugin.type}/${plugin.plugin_slug}` : plugin.plugin,
  //     updated_version: ka,
  //     now_version: nowVersion,
  //     version_status: "Nil",
  //     updated_status: "queue",
  //     project_name: project.slug
  //   }
  //   if (queue.length === 0) {
  //     projectObject.queue.push(ab)
  //     queue.push(projectObject)
  //     toast.success(`New project queue genrated ${project?.slug} and added plugin ${ab.plugin_name} with environment ${envP}`)
  //     localStorage.setItem("composer-queue", JSON.stringify(queue))
  //   }
  //   else {
  //     const index = queue.findIndex((i: any) => { return i.project_name === project?.slug && i.environment === envP })
  //     if (index < 0) {
  //       projectObject.queue.push(ab)
  //       queue.push(projectObject)
  //       toast.success(`New project queue genrated ${project?.slug} and added plugin ${ab.plugin_name} with environment ${envP}`)
  //       localStorage.setItem("composer-queue", JSON.stringify(queue))
  //     }
  //     else {
  //       const getPlugin = queue[index].queue.filter((k: any) => { return k.plugin_name === ab.plugin_name })
  //       if (getPlugin.length > 0) {
  //         toast.error(`${ab.plugin_name} already added`)
  //       }
  //       else {
  //         queue[index].queue.push(ab)
  //         toast.success(`Added plugin ${ab.plugin_name}`)
  //         localStorage.setItem("composer-queue", JSON.stringify(queue))
  //       }
  //     }
  //   }
  // }

  const onSelectPluginVersion = (event: React.ChangeEvent<HTMLInputElement>, item: any, index: number): void => {
    const arrayList = addListOfUpdate;
    // console.log("event.target.checked",event.target.checked)
    if (event.target.checked) {
      const list1 = arrayList.filter((i: any) => {
        return i.plugin_name === item.plugin_name;
      });

      const ab: any = {
        plugin_slug: item.plugin.slug,
        plugin_version: item.plugin_version,
        env_id: envP,
        project_id: projectid,
        version: item.version,
        // changeVersion:newVersion,
        action: item.action,
      };
      // arrayList.push(ab);
      const arr1 = [...arrayList, ab];
      setAddListOfUpdate(arr1);
    } else {
      // console.log("arrayList",arrayList)
      const list = arrayList.filter((i: any) => {
        return i.plugin_slug !== item.plugin.slug;
      });
      setAddListOfUpdate(list);
    }
  };

  const saveLatestVersionDatabase = (): void => {
    if (envChanges !== null) {
      if (envChanges.length > 0) {
        setOpenEnvChangesPopup(true);
        closeNotification();
      }
    }
    // if (typeof window !== 'undefined') {
    //   if (addListOfUpdate.length > 0) {
    //     if (window.confirm('Are you sure that you want to update all the packges you selected \u{1F600}?')) {
    //       const resp = addListOfUpdate.map((i: any) => {
    //         if (getStoreMeta?.type !== 'wp') {
    //           return {
    //             plugin_slug: i.details.plugin_details.plugin_slug,
    //             plugin_version: i.updated_version,
    //             env_id: envP,
    //             project_id: projectid,
    //             version: i.now_version,
    //           };
    //         } else {
    //           return {
    //             plugin_slug: i.details.plugin_details.plugin.split('/')[0],
    //             plugin_version: i.updated_version,
    //             env_id: envP,
    //             project_id: projectid,
    //             version: i.details.plugin_details.version,
    //             action:'update'
    //           };
    //         }

    //         // addQueue(i.updated_version, ab)
    //       });

    //       addEnviornmentQueue({ data: resp }).then((ik: any) => {
    //         if (ik.error) {
    //           toast.error(ik.error.message.message);
    //         } else {
    //           const countNotification = notification - resp?.length;
    //           setNotification(countNotification);
    //           toast.success(ik.data.message);
    //           setShowNotification(false);
    //         }
    //       });
    //     } else {
    //       console.log('donothing');
    //     }
    //   } else {
    //     if (window.confirm('Please select minimum one plugin to update version \u{1F600}')) {
    //       console.log('donothin');
    //     } else {
    //       console.log('donothing');
    //     }
    //   }
    // }
  };

  const saveToQueue = (): void => {
    setLoading(true);
    if (addListOfUpdate.length > 0) {
      addEnviornmentQueue({ data: addListOfUpdate })
        .then((res: any) => {
          if (res.data) {
            setTimeout(() => {
              dispatch(
                fetchEnvChangeaData({
                  flag: 'get',
                  env_id: envP,
                })
              );
              setOpenEnvChangesPopup(false);
              toast.success(res.data.message);
              dispatch(fetchQueueData());
              setLoading(false);
            }, 2000);
          }
        })
        .finally(() => {
          setIsCheckAll(false);
        });
    }
  };

  function showPluginInNotification(item: any): boolean {
    if (Object.keys(storeQueueData).length > 0) {
      const queue = storeQueueData;
      if (Object.keys(queue).length > 0) {
        const index = queue.plugins.findIndex((i: any) => {
          return i.slug === item.plugin_name.split('/')[1];
        });

        if (index < 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }

    // const queue: any =
    //   localStorage.getItem("composer-queue") !== null
    //     ? JSON.parse(localStorage.getItem("composer-queue")!)
    //     : [];
    // if (queue.length === 0) {
    //   return true
    // }
    // else {
    //   const index = queue.findIndex((i: any) => { return i.project_name === project?.slug && i.environment === envP })
    //   if (index < 0) {
    //     return true
    //   }
    //   else {
    //     const getPlugin = queue[index].queue.filter((k: any) => { return k.plugin_name === item.plugin_name })
    //     if (getPlugin.length > 0) {
    //       return false
    //     }
    //     else {
    //       return true
    //     }
    //   }
    // }
  }

  function AddComposerQueueList(item: any): void {
    const queueUpdater: any =
      localStorage.getItem('composer-plugin-add') !== null
        ? JSON.parse(localStorage.getItem('composer-plugin-add')!)
        : [];
    const specificProject: any = {
      project_name: project?.slug,
      environment: envP,
      queue: [],
    };
    const pluginWithVersion: any = {
      plugin_name: `wpackagist-plugin/${item.slug}`,
      slug: item.slug,
      version_selected: item.versions.find((k: any) => {
        return k.id === item.selectedVersion;
      }).version,
    };
    if (queueUpdater === 0) {
      specificProject.queue.push(pluginWithVersion);
      queueUpdater.push(specificProject);
      toast.success(
        `New queue genrated for ${project?.slug} and added plugin ${pluginWithVersion.plugin_name} to add to composer with environment ${envP}`
      );
      localStorage.setItem('composer-plugin-add', JSON.stringify(queueUpdater));
    } else {
      const index = queueUpdater.findIndex((i: any) => {
        return i.project_name === project?.slug && i.environment === envP;
      });
      if (index < 0) {
        specificProject.queue.push(pluginWithVersion);
        queueUpdater.push(specificProject);
        toast.success(
          `New queue genrated for ${project?.slug} and added plugin ${pluginWithVersion.plugin_name} to add to composer with environment ${envP}`
        );
        localStorage.setItem('composer-plugin-add', JSON.stringify(queueUpdater));
      } else {
        const getPlugin = queueUpdater[index].queue.filter((k: any) => {
          return k.plugin_name === pluginWithVersion.plugin_name;
        });
        if (getPlugin.length > 0) {
          toast.error(`${pluginWithVersion.plugin_name} already added`);
        } else {
          queueUpdater[index].queue.push(pluginWithVersion);
          toast.success(`Added plugin ${pluginWithVersion.plugin_name}`);
          localStorage.setItem('composer-plugin-add', JSON.stringify(queueUpdater));
        }
      }
    }
  }

  const changeCompPlugVersionModel = (slug: string, value: string): void => {
    const queueUpdater: any =
      localStorage.getItem('composer-plugin-add') !== null
        ? JSON.parse(localStorage.getItem('composer-plugin-add')!)
        : [];
    const specificProject: any = {
      project_name: project?.slug,
      queue: [],
    };
    const pluginWithVersion: any = {
      plugin_name: `wpackagist-plugin/${slug}`,
      slug: slug,
      version_selected: value,
    };
    if (queueUpdater === 0) {
      specificProject.queue.push(pluginWithVersion);
      queueUpdater.push(specificProject);
      toast.success(
        `New queue genrated for ${project?.slug} and added plugin ${pluginWithVersion.plugin_name} to add to composer`
      );
      localStorage.setItem('composer-plugin-add', JSON.stringify(queueUpdater));
    } else {
      const index = queueUpdater.findIndex((i: any) => {
        return i.project_name === project?.slug;
      });
      if (index < 0) {
        specificProject.queue.push(pluginWithVersion);
        queueUpdater.push(specificProject);
        toast.success(
          `New queue genrated for ${project?.slug} and added plugin ${pluginWithVersion.plugin_name} to add to composer`
        );
        localStorage.setItem('composer-plugin-add', JSON.stringify(queueUpdater));
      } else {
        const getPlugin = queueUpdater[index].queue.filter((k: any) => {
          return k.plugin_name === pluginWithVersion.plugin_name;
        });
        if (getPlugin.length > 0) {
          const getPluginIndex = queueUpdater[index].queue.findIndex((k: any) => {
            return k.plugin_name === pluginWithVersion.plugin_name;
          });
          if (getPluginIndex >= 0) {
            const version = queueUpdater[index].queue[getPluginIndex].version_selected;
            if (version === pluginWithVersion.version_selected) {
              toast.error(
                `${pluginWithVersion.plugin_name} already added with specific version ${pluginWithVersion.version_selected}`
              );
            } else {
              queueUpdater[index].queue[getPluginIndex].version_selected = value;
              toast.success(
                `${pluginWithVersion.plugin_name} added with specific version ${pluginWithVersion.version_selected}`
              );
              localStorage.setItem('composer-plugin-add', JSON.stringify(queueUpdater));
            }
          }
        } else {
          queueUpdater[index].queue.push(pluginWithVersion);
          toast.success(`Added plugin ${pluginWithVersion.plugin_name}`);
          localStorage.setItem('composer-plugin-add', JSON.stringify(queueUpdater));
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem('introjs') === null) {
      const array = [];
      const homeScreen = { screen: 'composer-project-screen', status: 'true' };
      array.push(homeScreen);
      localStorage.setItem('introjs', JSON.stringify(array!));
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'composer-project-screen';
      });
      if (index < 0) {
        const homeScreen = { screen: 'composer-project-screen', status: 'true' };
        array.push(homeScreen);
        setStepsEnabled(homeScreen.status === 'true' ? true : false);
        // manageQueryString(homeScreen.status)
        localStorage.setItem('introjs', JSON.stringify(array!));
      } else {
        // manageQueryString(array[index].status)
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    }
  }, []);

  const onIntroExit = (e: any): void => {
    if (e === -1) {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'composer-project-screen';
      });
      if (index >= 0) {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'composer-project-screen';
      });
      if (index >= 0) {
        array[index].status = 'false';
        // manageQueryString(array[index].status)
        dispatch(updateTrigger({ page: 'project-details', trigger: false }));
        setStepsEnabled(array[index].status === 'false' ? false : true);
        localStorage.setItem('introjs', JSON.stringify(array!));
      }
    }
  };
  const onActivateIntro = () => {
    const getLocal = localStorage.getItem('introjs');
    if (getLocal !== null) {
      const getJson = JSON.parse(getLocal);
      const getIndex = getJson.findIndex((i: any) => {
        return i.screen === 'composer-project-screen';
      });

      if (getIndex > -1) {
        getJson[getIndex].status = 'true';
        setStepsEnabled(true);
        localStorage.setItem('introjs', JSON.stringify(getJson));
      }
    }
  };

  const openProjectSetting = (): void => {
    setShowSettings(true);
  };
  const handleSettingClose = () => setShowSettings(false);

  const handleFileChange = (e: any): void => {
    if (/\.(json)$/i.test(e.target.files[0].name) === true) {
      const fileReader = new FileReader();
      setConfEnv({
        ...confEnv,
        composer_file: e.target.files[0],
      });
      const filename = e.target.files[0].name;
      fileReader.readAsText(e.target.files[0], 'UTF-8');
      fileReader.onload = (event: any) => {
        const comp = JSON.parse(event.target.result);
        const compArray = Object.keys(comp.require).map(i => {
          const ab = i.split('/');
          if (ab.length === 1) {
            return {
              type: '',
              plugin: ab[0],
              version: comp.require[i],
            };
          } else {
            return {
              type: ab[0],
              plugin: ab[1],
              version: comp.require[i],
            };
          }
        });
        const composerMeta: any = {
          type: 'composer',
          composer: JSON.parse(event.target.result),
          file_path: '',
        };
        setConfEnv({
          ...confEnv,
          composer_file: e.target.files[0],
          composer_require_array: compArray,
          composer_meta: composerMeta,
        });

        setFile(filename);
        setIsFileAdded('valid');
        setComposerError((prevError: any) => ({
          ...prevError,
          composer_file: '',
        }));
      };
    } else {
      setFile('');
      setIsFileAdded('invalid');
      setComposerError((prevError: any) => ({
        ...prevError,
        composer_file: 'Only .json format is valid.',
      }));
    }
  };
  const handleFtpFileChange = (e: any) => {
    const fileReader = new FileReader();
    if (/\.(pem)$/i.test(e.target.files[0].name) === true) {
      fileReader.readAsText(e.target.files[0], 'UTF-8');

      fileReader.onload = () => {
        const fileContent = fileReader.result;

        setFtpData({
          ...ftpData,
          file: e.target.files[0],
          meta: {
            ...ftpData.meta,
            fileContent,
          },
        });

        setFileFtp(e.target.files[0].name);
        setIsFtpFileAdded('valid');
        setFtpError((prevError: any) => ({
          ...prevError,
          file: '',
        }));
      };

      fileReader.onerror = error => {
        setFileFtp('');
        setIsFtpFileAdded('invalid');
        setFtpError((prevError: any) => ({
          ...prevError,
          file: 'only .pem format is valid.',
        }));
      };
    } else {
      setFileFtp('');
      setIsFtpFileAdded('invalid');
      setFtpError((prevError: any) => ({
        ...prevError,
        file: 'only .pem format is valid.',
      }));
    }
  };
  const handleSshFileChange = (e: any) => {
    const fileReader = new FileReader();
    if (/\.(pem)$/i.test(e.target.files[0].name) === true) {
      fileReader.readAsText(e.target.files[0], 'UTF-8');

      fileReader.onload = () => {
        const fileContent = fileReader.result;

        setSshData({
          ...sshData,
          file: e.target.files[0],
          meta: {
            ...sshData.meta,
            fileContent,
          },
        });

        setFileSsh(e.target.files[0].name);
        setIsSshFileAdded('valid');
        setSshError((prevError: any) => ({
          ...prevError,
          file: '',
        }));
      };

      fileReader.onerror = error => {
        setFileSsh('');
        setIsSshFileAdded('invalid');
        setSshError((prevError: any) => ({
          ...prevError,
          file: 'only .pem format is valid.',
        }));
      };
    } else {
      setFileSsh('');
      setIsSshFileAdded('invalid');
      setSshError((prevError: any) => ({
        ...prevError,
        file: 'only .pem format is valid.',
      }));
    }
  };
  const [ftpData, setFtpData] = useState<any>({
    url: '',
    username: '',
    password: '',
    path_to_wp_content: '',
    file: '',
    subtype: '',
    meta: '',
  });
  const [sshData, setSshData] = useState<any>({
    url: '',
    username: '',
    password: '',
    path_to_wp_installation: '',
    path_to_wp_content_folder: '',
    file: '',
    subtype: '',
    meta: '',
  });

  const validatewpConnector = () => {
    let newError: any = {};
    if (!confEnv.username) {
      newError.username = `${t('project-list.validate-error.wp-connector.Username-is-required.')}`;
    }
    if (!confEnv.password) {
      newError.password = `${t('project-list.validate-error.wp-connector.Password-is-required..')}`;
    }
    if (!confEnv.wp_url) {
      newError.wp_url = `${t('project-list.validate-error.wp-connector.Wp-url-is-required.')}`;
    }
    setWpError(newError);
    return Object.keys(newError).length === 0;
  };

  const validateComposer = () => {
    let newError: any = {};
    if (!confEnv.composer_file) {
      newError.composer_file = `${t('project-list.validate-error.composer.Composer-file-is-required.')}`;
    }

    // if (!confEnv.authentication_file) {
    //   newError.authentication_file = 'Authentication file is required';
    // }

    // if (!confEnv.composer_lock_file) {
    //   newError.composer_lock_file = 'Composer lock file is required';
    // }

    setComposerError(newError);
    return Object.keys(newError).length === 0;
  };

  const validateFtp = () => {
    let newError: any = {};
    if (!ftpData.url) {
      newError.url = `${t('project-list.validate-error.ftp.Url-is-required.')}`;
    }
    if (!ftpData.username) {
      newError.username = `${t('project-list.validate-error.ftp.Username-is-required.')}`;
    }
    if (!ftpData.password) {
      newError.password = `${t('project-list.validate-error.ftp.Password-is-required.')}`;
    }
    if (!ftpData.path_to_wp_content) {
      newError.path_to_wp_content = `${t('project-list.validate-error.ftp.Path-to-wp-content-is-required.')}`;
    }
    if (!ftpData.file) {
      newError.file = `${t('project-list.validate-error.ftp.File-is-required.')}`;
    }
    if (!ftpData.subtype) {
      newError.subtype = `${t('project-list.validate-error.ftp.Subtype-is-required.')}`;
    }
    if (!ftpData.meta) {
      newError.meta = `${t('project-list.validate-error.ftp.Meta-is-required.')}`;
    }
    setFtpError(newError);
    return Object.keys(newError).length === 0;
  };

  const validateSsh = () => {
    let newError: any = {};
    if (!sshData.url) {
      newError.url = `${t('project-list.validate-error.ssh.Url-is-required.')}`;
    }
    if (!sshData.username) {
      newError.username = `${t('project-list.validate-error.ssh.Username-isrequired.')}`;
    }
    if (!sshData.password) {
      newError.password = `${t('project-list.validate-error.ssh.Password-is-required.')}`;
    }
    if (!sshData.path_to_wp_installation) {
      newError.path_to_wp_installation = `${t('project-list.validate-error.ssh.Path-to-wp-installation-is-required.')}`;
    }
    if (!sshData.path_to_wp_content_folder) {
      newError.path_to_wp_content_folder = `${t(
        'project-list.validate-error.ssh.Path-to-wp-content-folder-is-required.'
      )}`;
    }
    if (!sshData.file) {
      newError.file = `${t('project-list.validate-error.ssh.File-is-required.')}`;
    }
    if (!sshData.subtype) {
      newError.subtype = `${t('project-list.validate-error.ssh.Subtype-is-required.')}`;
    }
    if (!sshData.meta) {
      newError.meta = `${t('project-list.validate-error.ssh.Project-name-is-required.')}`;
    }
    setSshError(newError);
    return Object.keys(newError).length === 0;
  };

  const validatecreateEnv = () => {
    let newError: any = {};
    if (!createEnv.environment_name) {
      newError.environment_name = 'Environment name is required.';
    }
    if (!createEnv.site_url) {
      newError.site_url = 'Site url is required.';
    }
    setEnvError(newError);
    return Object.keys(newError).length === 0;
  };

  const onProjectConfigStep = async (e: React.FormEvent<HTMLFormElement>, type: string): Promise<void> => {
    e.preventDefault();
    if (type === 'composer') {
      if (validateComposer()) {
        setProjectConfCompDisableButton(true);
        const oneMeta = { ...confEnv.composer_meta, auth: confEnv.auth, composer_lock: confEnv.composer_lock };
        const payload = {
          project_id: project.id,
          project_env_id: envP,
          meta: JSON.stringify(oneMeta),
          composer_require_array: JSON.stringify(confEnv.composer_require_array),
          composer_file: confEnv.composer_file,
          authentication_file: confEnv.authentication_file,
          composer_lock_file: confEnv.composer_lock_file,
          project_type: 1,
          flag: 'project_env_create',
        };
        const data1: any = {
          id: 1,
          data: payload,
        };
        const formData = new FormData();
        formData.append('id', data1.id);

        for (const key in data1.data) {
          if (data1.data.hasOwnProperty(key)) {
            formData.append(key, data1.data[key]);
          }
        }
        formData.forEach((value, key) => {
          // console.log(key, value);
        });

        axios({
          url: `${process.env.REACT_APP_API_URL}/api/team/${data1.id}/project`,
          method: 'POST',
          data: formData,
          headers: {
            // Accept: "multipart/form-data",
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
          .then((res1: any) => {
            setProjectConfCompDisableButton(false);
            if (Object.keys(res1).length > 0) {
              if (res1.data.hasOwnProperty('error')) {
                toast.error(`${res1.data.message}`);
              } else {
                toast.success(`${res1.data.message}`);
                // dispatch(fetchData());

                setConfEnv({
                  username: '',
                  password: '',
                  composer_file: null,
                  composer_require_array: [],
                  composer_meta: {},
                  authentication_file: null,
                  auth: {},
                  composer_lock_file: null,
                  composer_lock: {},
                });
                closeEnvSetting();
                dispatch(fetchProjectDetails(projectid));
              }
            }
          })
          .catch((err1: any) => {
            setProjectConfCompDisableButton(false);
            console.log('err1', err1);
          });
      }
    } else if (type === 'wp_connector') {
      if (validatewpConnector()) {
        setProjectConfWpDisableButton(true);
        if (await check(getMeta.siteurl, confEnv.username, confEnv.password)) {
          const plugins: any = await wpdata(getMeta.siteurl, confEnv.username, confEnv.password);
          const wpMeta = {
            type: 'wp',
            last_checked: new Date().toISOString(),
            username: confEnv.username,
            app_password: btoa(confEnv.password),
          };
          const payload = {
            project_id: project.id,
            project_env_id: envP,
            meta: JSON.stringify(wpMeta),
            username: confEnv.username,
            app_password: btoa(confEnv.password),
            composer_require_array: JSON.stringify([]),
            project_type: 2,
            composer_file: 'null',
            authentication_file: 'null',
            composer_lock_file: 'null',
            flag: 'project_env_create',
            wp_plugin_array: Object.keys(plugins).length > 0 ? JSON.stringify(plugins.data) : JSON.stringify([]),
          };
          const data1: any = {
            id: 1,
            data: payload,
          };
          const formData = new FormData();
          formData.append('id', data1.id);

          for (const key in data1.data) {
            if (data1.data.hasOwnProperty(key)) {
              formData.append(key, data1.data[key]);
            }
          }
          formData.forEach((value, key) => {
            // console.log(key, value);
          });

          axios({
            url: `${process.env.REACT_APP_API_URL}/api/team/${data1.id}/project`,
            method: 'POST',
            data: formData,
            headers: {
              // Accept: "multipart/form-data",
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
            .then((res1: any) => {
              setProjectConfWpDisableButton(false);
              if (Object.keys(res1).length > 0) {
                if (res1.data.hasOwnProperty('error')) {
                  toast.error(`${res1.data.message}`);
                  // onEnvironmentClose()
                } else {
                  toast.success(`${res1.data.message}`);
                  // dispatch(fetchData());
                  setConfEnv({
                    username: '',
                    password: '',
                    composer_file: null,
                    composer_require_array: [],
                    composer_meta: {},
                    authentication_file: null,
                    auth: {},
                  });
                  closeEnvSetting();
                  dispatch(fetchProjectDetails(projectid));
                }
              }
            })
            .catch((err1: any) => {
              setProjectConfWpDisableButton(false);
              console.log('err1', err1);
            });
        } else {
          setProjectConfWpDisableButton(false);
          toast.error('invalid credentials');
        }
      }
    } else if (type === 'ftp') {
      if (validateFtp()) {
        const ftpMeta = {
          url: ftpData.url,
          username: ftpData.username,
          password: btoa(ftpData.password),
          path_to_wp_content: ftpData.path_to_wp_content,
          file: ftpData.meta,
        };
        const payload = {
          url: ftpData.url,
          username: ftpData.username,
          password: btoa(ftpData.password),
          path_to_wp_content: ftpData.path_to_wp_content,
          file: ftpData.file,
          meta: JSON.stringify(ftpMeta),
          subtype: checkboxFtp ? 'public_key' : 'password',
          project_id: project.id,
          project_env_id: envP,
          flag: 'ftp',
        };
        const data1: any = {
          id: 1,
          data: payload,
        };
        const formData = new FormData();
        formData.append('id', data1.id);

        for (const key in data1.data) {
          if (data1.data.hasOwnProperty(key)) {
            formData.append(key, data1.data[key]);
          }
        }
        // formData.forEach((value, key) => {
        //   console.log(key, value);
        // });

        axios({
          url: `${process.env.REACT_APP_API_URL}/api/team/${data1.id}/project`,
          method: 'POST',
          data: formData,
          headers: {
            // Accept: "multipart/form-data",
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
          .then((res1: any) => {
            if (Object.keys(res1).length > 0) {
              if (res1.data.hasOwnProperty('error')) {
                toast.error(`${res1.data.message}`);
                handleSettingClose();
              } else {
                toast.success(`${res1.data.message}`);
                // dispatch(fetchData());
                setFtpData({
                  url: '',
                  username: '',
                  password: '',
                  path_to_wp_content: '',
                  file: '',
                  subtype: '',
                });
                handleSettingClose();
                dispatch(fetchProjectDetails(projectid));
              }
            }
          })
          .catch((err1: any) => {
            console.log('err1', err1);
          });
      }
    } else if (type === 'ssh') {
      if (validateSsh()) {
        const sshMeta = {
          url: sshData.url,
          username: sshData.username,
          password: btoa(sshData.password),
          path_to_wp_installation: sshData.path_to_wp_installation,
          path_to_wp_content_folder: sshData.path_to_wp_content_folder,
          file: sshData.meta,
        };
        const payload = {
          url: sshData.url,
          username: sshData.username,
          password: btoa(sshData.password),
          path_to_wp_installation: sshData.path_to_wp_installation,
          path_to_wp_content_folder: sshData.path_to_wp_content_folder,
          file: sshData.file,
          meta: JSON.stringify(sshMeta),
          subtype: checkboxSsh ? 'public_key' : 'password',
          project_id: project.id,
          project_env_id: envP,
          flag: 'ssh',
        };
        const data1: any = {
          id: 1,
          data: payload,
        };
        const formData = new FormData();
        formData.append('id', data1.id);

        for (const key in data1.data) {
          if (data1.data.hasOwnProperty(key)) {
            formData.append(key, data1.data[key]);
          }
        }
        // formData.forEach((value, key) => {
        //   console.log(key, value);
        // });

        axios({
          url: `${process.env.REACT_APP_API_URL}/api/team/${data1.id}/project`,
          method: 'POST',
          data: formData,
          headers: {
            // Accept: "multipart/form-data",
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
          .then((res1: any) => {
            if (Object.keys(res1).length > 0) {
              if (res1.data.hasOwnProperty('error')) {
                toast.error(`${res1.data.message}`);
                handleSettingClose();
              } else {
                toast.success(`${res1.data.message}`);
                // dispatch(fetchData());
                setSshData({
                  url: '',
                  username: '',
                  password: '',
                  path_to_wp_installation: '',
                  path_to_wp_content_folder: '',
                  file: '',
                  subtype: '',
                });
                handleSettingClose();
                dispatch(fetchProjectDetails(projectid));
              }
            }
          })
          .catch((err1: any) => {
            console.log('err1', err1);
          });
      }
    }
  };

  const openUpdateNotifyModal = (): void => {
    setUpdateNotifyModal(true);
  };
  const closeUpdateNotifyModal = (): void => {
    setUpdateNotifyModal(false);
  };
  const handleFileChangeOne = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // console.log("e",e)
    if (e.target.files !== null) {
      const file = e.target.files[0];
      // console.log("files",file)
      if (file.type === 'application/zip') {
        setIsFileAddedOne('valid');
        setFileOne(file.name);
        setPluginData({ ...pluginData, file: e.target.files[0] });
      } else {
        setIsFileAddedOne('invalid');
        setFileOne('');
      }
    }
  };
  const handlePrivatePluginUpload = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setPrivatePlugDisable(true);
    const formData = new FormData();
    formData.append('name', pluginData.name);
    formData.append('slug', pluginData.slug);
    formData.append('image', pluginData.image);
    formData.append('file', pluginData.file);
    formData.append('type', pluginData.type);
    formData.append('URL', pluginData.URL);
    formData.append('wp_author', pluginData.wp_author);
    formData.append('short_description', pluginData.short_description);
    formData.append('description', pluginData.description);
    formData.append('version', JSON.stringify(item));
    formData.append('flag', pluginData.flag);
    formData.append('project_id', projectid as any);
    formData.append('project_env_id', envP as any);

    formData.forEach((value, key) => {
      // console.log(key, value);
    });
    axios({
      url: `${process.env.REACT_APP_API_URL}/api/addPlugins`,
      method: 'POST',
      data: formData,
      headers: {
        // Accept: "multipart/form-data",
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(res1 => {
        console.log('res', res1);
        if (Object.keys(res1).length > 0) {
          setPrivatePlugDisable(false);
          if (res1.data.hasOwnProperty('error')) {
            toast.error(`${res1.data.message}`);
          } else {
            toast.success(`${res1.data.Message}`);
            closeUpdateNotifyModal();
          }
        }
      })
      .catch(err1 => {
        setPrivatePlugDisable(false);
        toast.error('Something went wrong');
        console.log('err1', err1);
      });
  };

  const onAddFromStore = (): void => {
    // const params: any = {
    //   project: projectid,
    //   env: envP,
    //   redirect: `/projects/${projectid}?environment=${envP}`,
    // };
    // navigate({
    //   pathname: '/store',
    //   search: `?${createSearchParams(params)}`,
    // });
    navigate('/store');
  };

  const AddToDeploy = (): void => {
    navigate('/deploy-pipeline');
  };

  const disableButton = (): boolean => {
    if (typeof queueData !== 'undefined') {
      const findProject = queueData.find((i: any) => {
        return i.project.project_id === Number(projectid);
      });
      if (findProject !== undefined) {
        if (findProject.enviornment.id === Number(envP)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const openPrivatePackage = (): void => {
    setPrivatePackageModal(true);
  };
  const closePrivatePackage = (): void => {
    setPrivatePackageModal(false);
  };

  const onCreateProjectInsidePath = (): void => {
    setIsSyncLoading(true);
    const selectEnv = project.meta.find((ik: any) => {
      return ik.id === envP;
    });
    const meta = JSON.parse(selectEnv.meta);
    if (Object.prototype.hasOwnProperty.call(meta, 'auth')) {
      if (Object.keys(meta.auth).length > 0) {
        const composer = JSON.parse(selectEnv.meta).composer;
        // console.log('availablePluginFromData', availablePluginFromData);
        const packageNotFound = availablePluginFromData
          .map((i: any) => {
            if (i.status === '0') {
              if (i.type === null) {
                return i.plugin_slug;
              } else {
                return `${i.type}/${i.plugin_slug}`;
              }
            }
          })
          .filter((k: any) => {
            return k !== undefined;
          });
        const packageNotExist = [...availablePluginFromData]
          .filter((ik: any) => {
            return ik.status === '0';
          })
          .map((i: any) => {
            const newPlugin = { ...i };
            if (i.type === null) {
              newPlugin.version = JSON.parse(selectEnv.meta).composer.require[i.plugin_slug];
            } else {
              newPlugin.version = JSON.parse(selectEnv.meta).composer.require[`${i.type}/${i.plugin_slug}`];
            }

            return newPlugin;
          });
        // console.log(packageNotFound);
        const requireObject = packageNotFound.reduce((result: any, obj: any) => {
          result[obj] = composer.require[obj];
          return result;
        }, {});
        const resultedComposer = composer;
        resultedComposer.require = requireObject;
        // console.log('resultedComposer', resultedComposer, JSON.parse(selectEnv.meta).composer);
        const data = {
          package_not_exist: packageNotExist,
          real_composer: JSON.parse(selectEnv.meta).composer,
          composer: resultedComposer,
          auth: meta.auth,
          project_id: projectid,
          env_id: selectEnv.id,
          path: `${process.env.REACT_APP_AUTH_NODE_PATH}/${project.id}-${selectEnv.id}/`,
        };

        const commands = [
          'whoami',
          `composer install --working-dir=${data.path} -v`,
          `ls ${data.path}vendor`,
          `rm -rf ${data.path}`,
        ];

        const sendResponse = {
          host: process.env.REACT_APP_AUTH_NODE_API_IP,
          port: 22,
          username: process.env.REACT_APP_AUTH_NODE_API_USERNAME,
          password: '$jQgJh9hvN$b',
          data: data,
          commands: commands,
        };
        // console.log('sendResponse', sendResponse);

        axios({
          url: `${process.env.REACT_APP_AUTH_NODE_URL}/api/ssh/execute-ssh-commands-with-file-upload-with-auth`,
          data: { ...sendResponse },
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
          .then((res: any) => {
            // console.log('res', res);
            if (res) {
              toast.info(res.data.message);
            }
          })
          .catch(err => {
            toast.error('Sync Failed');
          })
          .finally(() => {
            setIsSyncLoading(false);
          });
      } else {
        console.log('no auth.json provided');
      }
    } else {
      const composer = JSON.parse(selectEnv.meta).composer;

      const data = {
        composer: composer,

        env_id: selectEnv.id,
        path: `${process.env.REACT_APP_AUTH_NODE_PATH}/${project.id}-${selectEnv.id}/`,
      };

      const commands = ['whoami', `composer install --working-dir=${data.path}`, `ls ${data.path}vendor`];

      const sendResponse = {
        host: '127.0.0.1',
        port: 22,
        username: 'sagar',
        password: '2023%SP%JS',
        data: data,
        commands: commands,
      };
      // console.log('sendResponse', sendResponse);

      axios({
        url: `${process.env.REACT_APP_AUTH_NODE_URL}/api/ssh/execute-ssh-commands-with-file-upload-composer`,
        data: { ...sendResponse },
        method: 'POST',
      }).then((res: any) => {
        // console.log('res', res);
      });
    }
  };

  const handleAuthFileChange = (e: any): void => {
    if (/\.(json)$/i.test(e.target.files[0].name) === true) {
      const fileReader = new FileReader();
      setConfEnv({
        ...confEnv,
        authentication_file: e.target.files[0],
      });
      const filename = e.target.files[0].name;
      fileReader.readAsText(e.target.files[0], 'UTF-8');
      fileReader.onload = (event: any) => {
        const auth = JSON.parse(event.target.result);
        setConfEnv({
          ...confEnv,
          authentication_file: e.target.files[0],
          auth: auth,
        });
        setFileTwo(filename);
        setIsFileAuthAdded('valid');
        setComposerError((prevError: any) => ({
          ...prevError,
          authentication_file: '',
        }));
      };
    } else {
      setFileTwo('');
      setIsFileAuthAdded('Invalid');
      setComposerError((prevError: any) => ({
        ...prevError,
        authentication_file: 'Only .json format is valid.',
      }));
    }
  };
  const downloadAuthJson = () => {
    if (typeof window !== 'undefined') {
      const meta = JSON.parse(getMeta.meta);
      if (Object.prototype.hasOwnProperty.call(meta, 'auth')) {
        if (meta.type === 'composer') {
          if (Object.keys(meta.auth).length > 0) {
            const download: any = document.getElementById('download_auth_json');
            const jsonBlob = new Blob([JSON.stringify(meta.auth, null, 2)], {
              type: 'application/json',
            });
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(jsonBlob);
            downloadLink.download = 'auth.json';
            download.appendChild(downloadLink);
            downloadLink.click();
            download.removeChild(downloadLink);
          }
        } else {
          toast.info('Download is only available for composer method');
        }
      }
    } else {
      toast.info('No Auth Json Provided');
    }
  };

  const redirectToAddUserPlugin = (plugin: any): void => {
    const params: any = {
      type: plugin.type,
      slug: plugin.plugin_slug,
      version: getVersionOne(plugin),
      project: projectid,
      env: envP,
      redirect_to: `/projects/${projectid}?environment=${envP}`,
    };

    navigate({
      pathname: '/add-user-package',
      search: createSearchParams(params).toString(),
    });
  };

  const onEnvSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (validatecreateEnv()) {
      const payload = {
        project_id: projectid,
        environment_name: createEnv.environment_name,
        site_url: createEnv.site_url,
        flag: 'environment_create',
      };

      const data1: any = {
        id: 1,
        data: payload,
      };
      const formData = new FormData();
      formData.append('id', data1.id);

      for (const key in data1.data) {
        if (data1.data.hasOwnProperty(key)) {
          formData.append(key, data1.data[key]);
        }
      }
      formData.forEach((value, key) => {
        // console.log(key, value);
      });

      axios({
        url: `${process.env.REACT_APP_API_URL}/api/team/${data1.id}/project`,
        method: 'POST',
        data: formData,
        headers: {
          // Accept: "multipart/form-data",
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then(async (res1: any) => {
          if (Object.keys(res1).length > 0) {
            if (res1.data.hasOwnProperty('error')) {
              toast.error(`${res1.data.message}`);
            } else {
              toast.success(`${res1.data.message}`);
              setCreateEnv({
                environment_name: '',
                site_url: '',
              });
              setShowSettings(false);
              dispatch(fetchProjectDetails(projectid));
              // onEnvironmentClose()
              // dispatch(fetchData());
            }
          }
        })
        .catch((err1: any) => {
          console.log('err1', err1);
        });
    }
  };

  const openEnvSetting = (): void => {
    if (project.meta !== null) {
      const projectFind = project.meta.find((ik: any) => {
        return ik.id === parseInt(envName);
      });
      if (projectFind !== undefined) {
        const details = {
          password: '',
          username: '',
          wp_url: projectFind.siteurl,
        };
        setConfEnv(details);
      }
    }

    setOpenEnvConfigModal(true);
  };
  const closeEnvSetting = (): void => {
    setOpenEnvConfigModal(false);
  };

  const handleLockFileChange = (e: any) => {
    if (/\.(lock)$/i.test(e.target.files[0].name) === true) {
      const fileReader = new FileReader();
      setConfEnv({
        ...confEnv,
        composer_lock_file: e.target.files[0],
      });
      const filename = e.target.files[0].name;
      fileReader.readAsText(e.target.files[0], 'UTF-8');
      fileReader.onload = (event: any) => {
        setConfEnv({
          ...confEnv,
          composer_lock_file: e.target.files[0],
          composer_lock: event.target.result,
        });

        setFileThree(filename);
        setIsLockFileAdded('valid');
        setComposerError((prevError: any) => ({
          ...prevError,
          composer_lock_file: '',
        }));
      };
    } else {
      setFileThree('');
      setIsLockFileAdded('invalid');
      setComposerError((prevError: any) => ({
        ...prevError,
        composer_lock_file: 'Only .lock format is valid.',
      }));
    }
  };

  useEffect(() => {
    var buttons = document.querySelectorAll('button');
    // console.log('buttons', buttons);
    // Disable each button
    if (isSyncLoading) {
      buttons.forEach(function (button) {
        button.disabled = true;
      });
    } else {
      buttons.forEach(function (button) {
        button.disabled = false;
      });
    }
  }, [isSyncLoading]);

  useEffect(() => {
    if (queueData !== null) {
      const getdata = queueData.find((ik: any) => {
        return ik.project.project_id === parseInt(projectid as any) && ik.enviornment.id === parseInt(envP as any);
      });
      if (getdata !== undefined) {
        setGetQueueByEnv(getdata);
        // getnotify();
        if (getStoreMeta.type === 'wp') {
          // const
        }
      }
      getnotify();
    }
  }, [queueData, envP, getUpdateVersionPlugin]);

  const getnotify = () => {
    const getresult: any = getUpdateVersionPlugin.filter((k: any) => {
      if (showPluginInNotification(k)) {
        return k;
      } else {
        return null;
      }
    })?.length;
    // console.log('getresult', getresult);
    setNotification(getresult);
  };

  useEffect(() => {
    const checkButtonStatus = () => {
      const storedData = localStorage.getItem('syncDataArray');
      if (storedData) {
        try {
          const syncDataArray = JSON.parse(storedData);
          const currentTime = new Date().toISOString();
          // console.log("currentTime",currentTime)
          const isDisabled = syncDataArray.some((item: any) => new Date(item.disabledTime) > new Date(currentTime));
          setIsButtonEnabled(!isDisabled);
        } catch (e) {
          console.error('Failed to parse syncDataArray from localStorage:', e);
          setIsButtonEnabled(true);
        }
      } else {
        setIsButtonEnabled(true);
      }
    };
    checkButtonStatus();
  }, []);

  const onManualWpSync = (): void => {
    setIsButtonEnabled(false);
    const currentTime = new Date().toISOString();
    const disabledTime = new Date(Date.now() + 1 * 60 * 60 * 1000).toISOString();

    const syncData = {
      project_env_id: envP,
      user_id: userDetails.id,
      currentTime: currentTime,
      disabledTime: disabledTime,
    };
    let existingData = localStorage.getItem('syncDataArray');
    let syncDataArray: any[] = [];
    if (existingData) {
      try {
        syncDataArray = JSON.parse(existingData);
        if (!Array.isArray(syncDataArray)) {
          throw new Error('Parsed data is not an array');
        }
      } catch (e) {
        console.error('Failed to parse syncDataArray from localStorage:', e);
        syncDataArray = [];
      }
    }
    const isDuplicate = syncDataArray.some(
      item => item.user_id === syncData.user_id && item.project_env_id === syncData.project_env_id
    );
    if (!isDuplicate) {
      syncDataArray.push(syncData);
      localStorage.setItem('syncDataArray', JSON.stringify(syncDataArray));
      // console.log("Updated sync data array:", syncDataArray);
    } else {
      // console.log("Duplicate entry found. No new data added.");
    }
    wordpressPluginSync({ flag: 'individual', project_env_id: envP }).then(res => {
      // console.log("res",res)
      dispatch(fetchProjectDetails(projectid));
    });
  };

  const handleCheckboxChange = (option: string) => {
    setShowUnAvailable(option === 'showUnAvailable' ? !showUnAvailable : false);
    setShowUpdateAvailable(option === 'showUpdateAvailable' ? !showUpdateAvailable : false);
    setShowPlugin(option === 'showPlugin' ? !showPlugin : false);
    setShowTheme(option === 'showTheme' ? !showTheme : false);
    setShowAll(option === 'showAll' ? !showAll : false);
    setShowQueue(option === 'showInQueue' ? !showQueue : false);
  };

  if (isLoading || queueDataLoading || envLoading || wpDataLoading || avaliablePluginFromDataLoading) {
    return <Loader />;
  }
  if (isError || queueDataError || envError || wpDataError || avaliablePluginFromDataError) {
    return <ErrorPage />;
  }
  // if (isSyncLoading) {
  //   return <SyncLoader />
  // }

  function compareVersion(versionA: any, versionB: any) {
    // Remove 'v' from versions and split by '.' and '-'
    const partsA = versionA.replace('v', '').split(/\.|-/);
    const partsB = versionB.replace('v', '').split(/\.|-/);

    // Compare each part numerically
    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = parseInt(partsA[i]) || partsA[i];
      const partB = parseInt(partsB[i]) || partsB[i];

      if (partA < partB) return -1;
      if (partA > partB) return 1;
    }

    // If all parts are equal, compare the whole version strings
    if (versionA < versionB) return -1;
    if (versionA > versionB) return 1;
    return 0;
  }

  const clickMore = (desc: any): void => {
    setClickMoreData(true);
    setSeeMoreData(desc);
  };
  const clickMoreClose = (): void => {
    setClickMoreData(false);
    setSeeMoreData('');
  };

  const handleVersionChange = (event: any, plugin: any ,type:any) => {
    setClickToVersion(() => () => confirmVersionUpdate(event, plugin, type));
    setOpenVersionChangesPopup(true);
  }
  
  const confirmVersionUpdate = (event: any, plugin: any, type: any) => {
    setLoading(true);
    if (type === "composer") {
      const pluginDataArray = [];
      const newVersion = event.target.value;
      const pluginData = {
        plugin_slug: plugin.plugin_slug,
        plugin_version: newVersion,
        env_id: envP,
        project_id: projectid,
        version: plugin.plugin_details.version,
        action:"update",
        flag:"add"
      }

      const ab = envChanges.find((ik: any) => { return ik.plugin.slug === plugin.plugin_slug })
      if (ab !== undefined) {
        const pluginData1 = {
          plugin_slug: plugin.plugin_slug,
          plugin_version: ab.plugin_version,
          env_id: envP,
          project_id: projectid,
          version: plugin.plugin_details.version,
          action: "update",
          changeVersion: newVersion,
          flag: "update"
        }
        EnvironmentChange(pluginData1).then((res12: any) => {
          dispatch(fetchEnvChangeaData(
            {
              flag: 'get',
              env_id: envP
            }
          ))
          toast.success('selected plugin version add into environment changes')
          setLoading(true);
          setOpenVersionChangesPopup(false);
        })
      } else {
        pluginDataArray.push(pluginData);
        EnvironmentChange({ data: pluginDataArray, flag: "add" }).then((res12: any) => {
          dispatch(fetchEnvChangeaData(
            {
              flag: 'get',
              env_id: envP
            }
          ))
          toast.success('selected plugin version add into environment changes')
          setOpenVersionChangesPopup(false);
        })
      }
    } else if (type === "wp") {
      const pluginDataArray = [];
      const newVersion = event.target.value;
      const pluginData = {
        plugin_slug: plugin.plugin.split('/')[0],
        plugin_version: newVersion,
        env_id: envP,
        project_id: projectid,
        version: plugin.version,
        action:"update",
        flag:'add'
      }

      const ab = envChanges.find((ik: any) => { return ik.plugin.slug === plugin.plugin.split('/')[0] })
      if (ab !== undefined) {
        const pluginData1 = {
          plugin_slug: plugin.plugin.split('/')[0],
          plugin_version: ab.plugin_version,
          env_id: envP,
          project_id: projectid,
          version: plugin.version,
          changeVersion: newVersion,
          action: "update",
          flag: 'update'
        }
        EnvironmentChange(pluginData1).then((res12: any) => {
          dispatch(fetchEnvChangeaData(
            {
              flag: 'get',
              env_id: envP
            }
          ))
          toast.success('selected plugin version add into environment changes')
          setOpenVersionChangesPopup(false);
        })
      } else {
        pluginDataArray.push(pluginData);
        EnvironmentChange({ data: pluginDataArray, flag: "add" }).then((res12: any) => {
          dispatch(fetchEnvChangeaData(
            {
              flag: 'get',
              env_id: envP
            }
          ))
          toast.success('selected plugin version add into environment changes')
          setOpenVersionChangesPopup(false);
        })
      }
    }
  }


  // const handleVersionChange = (event: any, plugin: any, type: any) => {
  //   if (type === 'composer') {
  //     // console.log("version",plugin,event.target.value)
  //     const pluginDataArray = [];
  //     const newVersion = event.target.value;
  //     const pluginData = {
  //       plugin_slug: plugin.plugin_slug,
  //       plugin_version: newVersion,
  //       env_id: envP,
  //       project_id: projectid,
  //       version: plugin.plugin_details.version,
  //       action: 'update',
  //       flag: 'add',
  //     };

  //     if (window.confirm(`Are you sure want to update version ${newVersion}?`)) {
  //       const ab = envChanges.find((ik: any) => {
  //         return ik.plugin.slug === plugin.plugin_slug;
  //       });
  //       if (ab !== undefined) {
  //         const pluginData1 = {
  //           plugin_slug: plugin.plugin_slug,
  //           plugin_version: ab.plugin_version,
  //           env_id: envP,
  //           project_id: projectid,
  //           version: plugin.plugin_details.version,
  //           action: 'update',
  //           changeVersion: newVersion,
  //           flag: 'update',
  //         };
  //         EnvironmentChange(pluginData1).then((res12: any) => {
  //           // console.log("res12",res12)
  //           dispatch(
  //             fetchEnvChangeaData({
  //               flag: 'get',
  //               env_id: envP,
  //             })
  //           );
  //           toast.success(res12.data.message);
  //         });
  //       } else {
  //         pluginDataArray.push(pluginData);
  //         EnvironmentChange({ data: pluginDataArray, flag: 'add' }).then((res12: any) => {
  //           // console.log("res12",res12)
  //           dispatch(
  //             fetchEnvChangeaData({
  //               flag: 'get',
  //               env_id: envP,
  //             })
  //           );
  //           toast.success(res12.data.message);
  //         });
  //       }

  //       // pluginDataArray.push(pluginData);

  //       // addEnviornmentQueue({ data: pluginDataArray }).then((res: any) => {
  //       //   if (res.data) {
  //       //     toast.success(res.data.message)
  //       //   }
  //       // })
  //     } else {
  //       console.log('Version not change.');
  //     }
  //   } else if (type === 'wp') {
  //     // console.log("version",plugin,event.target.value)
  //     const pluginDataArray = [];
  //     const newVersion = event.target.value;
  //     const pluginData = {
  //       plugin_slug: plugin.plugin.split('/')[0],
  //       plugin_version: newVersion,
  //       env_id: envP,
  //       project_id: projectid,
  //       version: plugin.version,
  //       action: 'update',
  //       flag: 'add',
  //     };

  //     if (window.confirm(`Are you sure want to update version ${newVersion}?`)) {
  //       const ab = envChanges.find((ik: any) => {
  //         return ik.plugin.slug === plugin.plugin.split('/')[0];
  //       });
  //       if (ab !== undefined) {
  //         const pluginData1 = {
  //           plugin_slug: plugin.plugin.split('/')[0],
  //           plugin_version: ab.plugin_version,
  //           env_id: envP,
  //           project_id: projectid,
  //           version: plugin.version,
  //           changeVersion: newVersion,
  //           action: 'update',
  //           flag: 'update',
  //         };
  //         EnvironmentChange(pluginData1).then((res12: any) => {
  //           // console.log("res12",res12)
  //           dispatch(
  //             fetchEnvChangeaData({
  //               flag: 'get',
  //               env_id: envP,
  //             })
  //           );
  //           toast.success(res12.data.message);
  //         });
  //       } else {
  //         pluginDataArray.push(pluginData);
  //         EnvironmentChange({ data: pluginDataArray, flag: 'add' }).then((res12: any) => {
  //           // console.log("res12",res12)
  //           dispatch(
  //             fetchEnvChangeaData({
  //               flag: 'get',
  //               env_id: envP,
  //             })
  //           );
  //           toast.success(res12.data.message);
  //         });
  //       }
  //       // pluginDataArray.push(pluginData);
  //       // EnvironmentChange({data:pluginDataArray,flag:"add"}).then((res12:any) => {
  //       //   console.log("res12",res12)
  //       // })
  //       // addEnviornmentQueue({ data: pluginDataArray }).then((res: any) => {
  //       //   if (res.data) {
  //       //     toast.success(res.data.message)
  //       //   }
  //       // })
  //     } else {
  //       console.log('Version not change.');
  //     }
  //   }
  // };

  const addNewWPPluginInQueue = (): void => {
    console.log('newWpPlugin', availableFilterPlugin);
    if (availableFilterPlugin.length > 0) {
      if (window.confirm(`Are you sure you want to add new plugins in queue`)) {
        // console.log("newPlugin", newWpPlugin)
        const wpData = availableFilterPlugin.map((i: any) => {
          return {
            // slug: i.plugin,
            // type: i.plugin.split("/")[0],
            // version: i.version,
            // project_env_id: envP,
            // action:"update"
            plugin_slug: i.plugin.split('/')[0],
            plugin_version: i.updated_version.length > 0 && i.updated_version[i.updated_version.length - 1],
            env_id: envP,
            project_id: projectid,
            version: i.version,
            // changeVersion:newVersion,
            action: 'update',
          };
        });

        // storeWpPlugin({ data: wpData }).then((ik: any) => {
        //   if (Object.prototype.hasOwnProperty.call(ik.data, 'error')) {
        //     toast.error(ik.data.message)
        //   } else {
        //     toast.success(ik.data.message)
        //     const newPlugin = newWpPlugin.filter((i: any) => { return !wpData.some((k: any) => { return i.plugin === k.slug }) })
        //     setNewWpPlugin(newPlugin)
        //     dispatch(fetchWpPluginData(getMeta.id))
        //     getPlugins()
        //     closeNotification()
        //   }
        // })
      }
    } else {
    }
  };
  // console.log("wpData", getQueueByEnv,getUpdateVersionPlugin,notification)

  const handleClick = () => {
    const searchParams = new URLSearchParams({
      project: projectid || '',
      environment: envP,
    });
    navigate({
      pathname: '/deploy-pipeline',
      search: searchParams.toString(),
    });
  };

  const deleteAChange = (item: any): void => {
    // console.log("item",item)
    if (window.confirm('Are you sure want to delete a change')) {
      EnvironmentChange({ id: item.id, flag: 'delete' }).then((res1: any) => {
        if (Object.prototype.hasOwnProperty.call(res1.data, 'error')) {
          toast.error('Some');
        } else {
          toast.error('deleted successfully');
          dispatch(
            fetchEnvChangeaData({
              flag: 'get',
              env_id: envP,
            })
          );
        }
      });
    }
  };
  // console.log("addListOfUpdate",addListOfUpdate)


  const handleMove = () => {
    const data = {
      source: envP,
      destination: selectedEnvironments.env2,
      projectId:projectId
    };
    if(data.source !== '' && data.destination !== ''){
      moveProject(data).then((res: any) => {
        if (res) {
          toast.success(res.data.Message);
        }
      });
    }
  };
  

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <>
        <>
          <Steps
            enabled={stepsEnabled}
            steps={ComposerProjectDetailsSteps}
            onExit={e => onIntroExit(e)}
            initialStep={initialSteps}
            options={{
              showProgress: true,
            }}
            onAfterChange={() => {
              const tooltip = document.querySelector('.introjs-tooltip');
              if (tooltip) {
                const footer = document.createElement('div');
                footer.classList.add('customTooltipFooter');
                footer.innerHTML = `
                <span style="font-size:small; color:white; display:flex; justify-content: center;">Check More Info:&nbsp;
                  <a href="${process.env.REACT_APP_WORDPRESS_STAGING_URL}/documentation" style="font-size:small; color:#F28A03;" target="_blank" rel="noopener noreferrer";>
                    Documentation.
                  </a>
                </span>

              `;
                // Append the footer only if it's not already appended
                if (!tooltip.querySelector('.customTooltipFooter')) {
                  tooltip.appendChild(footer);
                }
              }
            }}
          />
          <main className='main main-side'>
            <div className='container-fluid'>
              <div className='store_listab'>
                <ul className='nav nav-tabs project_detail_tabs' id='myTab' role='tablist'>
                  <li className='nav-item m-0' role='presentation'>
                    <button
                      className='nav-link active'
                      id='lists-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#lists'
                      type='button'
                      role='tab'
                      aria-controls='lists'
                      aria-selected='true'
                    >
                      {t('project-details.lists')}
                    </button>
                  </li>
                  {/* <li className='nav-item m-0' role='presentation'>
                  <button
                    className='nav-link'
                    id='team-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#team'
                    type='button'
                    role='tab'
                    aria-controls='team'
                    aria-selected='false'
                  >
                    {t('project-details.team')}
                  </button>
                </li>
                <li className='nav-item m-0' role='presentation'>
                  <button
                    className='nav-link'
                    id='settings-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#settings'
                    type='button'
                    role='tab'
                    aria-controls='settings'
                    aria-selected='false'
                  >
                    {t('project-details.settings')}
                  </button>
                </li> */}
                </ul>
                <div className='tab-content'>
                  <div className='tab-pane active' id='lists' role='tabpanel' aria-labelledby='lists-tab'>
                    <div className='projectlist'>
                      <div className='row align-items-center'>
                        <div className='col'>
                          {/* <button type='button' className='cblue_btn back_btn'>
                          <Link to={'/projects'} style={{ textDecoration: 'none', color: 'white' }}>
                            <span className='back_btn_icon'>
                              <img className='img-fluid' src={backarrow} alt='back-btn' />
                            </span>
                            <span>{t('project-details.buttons.back-to-ptojects')}</span>
                          </Link>
                        </button> */}


                          {/* <div className='page_title mt-4 mb-3 d-flex'>
                            <h1 className='m-0 text-white font20 me-4'>{project?.name}</h1> */}

                            {/* First Dropdown */}
                            {/* <div className='environment_select_dropdown'>
                              <div className='environment_select_dropdown_content'></div>
                              <select
                                className='environment-select form-select w-100 bg-none composer-project-details-first'
                                aria-label='Default select example'
                                value={selectedEnvironments.env1}
                                onChange={(e) => handleEnviornmentMove(e.target.value, 'env1')} 
                              >
                                <option value=''>{t('project-details.select-environment')}</option>
                                {project?.meta?.map((list: any) => (
                                  <option key={list.id} value={list.id}>
                                    {list.EnvironmentName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <p className='m-0 text-white me-2 ms-2'>to</p> */}

                            {/* Second Dropdown */}
                            {/* <div className=' d-flex environment_select_dropdown'>
                              <div className='environment_select_dropdown_content'></div>
                              <select
                                className='environment-select form-select w-100 bg-none composer-project-details-first'
                                aria-label='Default select example'
                                value={selectedEnvironments.env2}
                                onChange={(e) => handleEnviornmentMove(e.target.value, 'env2')} 
                              >
                                 <option value=''>{t('project-details.select-environment')}</option>
                                  {project?.meta
                                    ?.filter((list: any) => list.id !== selectedEnvironments.env1) 
                                    .map((list: any) => (
                                      <option key={list.id} value={list.id}>
                                        {list.EnvironmentName}
                                      </option>
                                ))}
                              </select>
                              <button className='orange_btn ms-4' style={{ height: '35px' }} onClick={handleMove}>
                              Move
                            </button>
                            </div>
                          </div> */}

                        </div>
                      </div>
                      <div className='row align-items-start top_select_btn_search'>
                        <div className={environment !== '0' ? 'col-md-4 ' : 'col-lg-4'}>
                          <div className='d-flex align-items-center environment_select_dropdown'>
                            <div className='environment_select_dropdown_content'></div>
                            <select
                              className='environment-select form-select w-100 bg-none composer-project-details-first'
                              aria-label='Default select example'
                              value={envP}
                              onChange={e => {
                                handleEnviornmentChangeSelect(e.target.value);
                              }}
                            >
                              <option value=''>{t('project-details.select-environment')}</option>
                              {/* <option> */}
                              {t('project-details.select-environment')}
                              {/* </option> */}

                              {project?.meta !== null
                                ? project?.meta.map((list: any) => {
                                    return (
                                      <option key={list.id} value={list.id}>
                                        {list.EnvironmentName}
                                      </option>
                                    );
                                  })
                                : null}
                            </select>
                            <div className='d-flex align-items-center environment_select_dropdown' style={{marginLeft:'5px'}}>
                              <div className='environment_select_dropdown_content'></div>
                              {/* <select
                                className='environment-select form-select w-100 bg-none composer-project-details-first'
                                aria-label='Default select example'
                                value={selectedEnvironments.env2}
                                onChange={(e) => handleEnviornmentMove(e.target.value, 'env2')} 
                              >
                                 <option value=''>{t('project-details.select-environment')}</option>
                                  {project?.meta
                                    ?.filter((list: any) => list.id !== envP) 
                                    .map((list: any) => (
                                      <option key={list.id} value={list.id}>
                                        {list.EnvironmentName}
                                      </option>
                                ))}
                              </select> */}

                              <div className='d-block'>
                                  <select
                                    disabled={disable}
                                    className='environment-select form-select orange'
                                    onChange={e => handleEnviornmentMove(e.target.value, 'env2')}
                                    value={selectedEnvironments.env2}
                                    style={{
                                      padding: '4px 44px 4px 44px',
                                      width:'200px',
                                      // fontSize: '16px',  
                                      height:'40px',
                                      backgroundColor: disable ? 'grey' : '#f08921',
                                      color: 'white',
                                      appearance: 'none',
                                      border: 'none',
                                      outline: 'none',
                                      cursor: 'pointer',
                                      transition: 'color 0.3s',
                                      borderRadius: '5px',
                                    }}
                                  >
                                    <option value=''>
                                    {disable ? 'Loading...' : 'Push Environment'}
                                      </option>
                                    {project?.meta
                                      ?.filter((list: any) => list.id !== envP)
                                      .map((list: any) => (
                                        <option key={list.id} value={list.id} style={{ color: '#f08921', borderRadius: '5px' }}>
                                          {list.EnvironmentName}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              <div className='d-block' style={{ position: 'absolute', top: '10px', right: '60px' }}>
                            </div>

                              {/* <button className='orange_btn ms-4' style={{ height: '35px' }} onClick={handleMove}>
                              Push Environment
                            </button> */}
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-8'>
                          <div className='btn_group'>
                            <div className='text-end d-flex justify-content-end flex-wrap'>
                              {Object.keys(getStoreMeta).length > 0 ? (
                                <>
                                  {privatePackages.length > 0 ? (
                                    <button
                                      type='button'
                                      className='cblue_btn me-2 mb-2'
                                      onClick={() => openPrivatePackage()}
                                    >
                                      <FontAwesomeIcon icon={faWarning} /> {privatePackages.length}
                                    </button>
                                  ) : null}

                                  {/* <button type="button" className="orange_btn me-2 mb-2" onClick={() => AddToDeploy()}>
                                  <span className="d-inline-block">{t('project-details.buttons.deployment')}</span>
                                </button> */}
                                  {/* <button
                                  type='button'
                                  className='cblue_btn me-2 mb-2'
                                  disabled={isSyncLoading ? true : false}
                                  onClick={() => onCreateProjectInsidePath()}
                                >
                                  <span className='d-inline-block'>
                                    {isSyncLoading ? t('project-details.buttons.sync-button.please-wait') : t('project-details.buttons.sync-button.sync-text')}
                                  </span>{' '}
                                  {isSyncLoading ? (
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  ) : null}
                                </button> */}
                                  {Object.keys(getStoreMeta).length > 0 && getStoreMeta.type === 'wp' ? (
                                    <button
                                      type='button'
                                      className='cblue_btn me-2'
                                      disabled={!isButtonEnabled}
                                      onClick={() => onManualWpSync()}
                                    >
                                      Manual Sync
                                    </button>
                                  ) : null}
                                  {/* <button type='button' className='orange_btn me-2 mb-2' onClick={() => onAddFromStore()}>
                                  <span className='d-inline-block'>{t('project-details.buttons.add-from-store')}</span>
                                </button> */}

                                  <button
                                    type='button'
                                    className='cblue_btn me-2'
                                    onClick={() => openUpdateNotifyModal()}
                                  >
                                    <span className='d-inline-block'>
                                      {t('project-details.buttons.upload-private-packages')}
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <>
                                  {queryString.get('environment') !== null ? (
                                    <button
                                      type='button'
                                      className='cblue_btn me-2 mb-2'
                                      onClick={() => openEnvSetting()}
                                    >
                                      <span className='d-inline-block ms-1'>
                                        {t('project-details.buttons.configure-environment')}
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      type='button'
                                      className='cblue_btn me-2 mb-2'
                                      onClick={() => openProjectSetting()}
                                    >
                                      <span className='d-inline-block ms-1'>
                                        {t('project-details.buttons.select-environment')}
                                      </span>
                                    </button>
                                  )}

                                  {/* <button
                                  type='button'
                                  className='orange_btn me-2 mb-2'
                                  disabled={isDisabled}
                                  onClick={() => onAddFromStore()}
                                >
                                  <span className='d-inline-block ms-1'>{t('project-details.buttons.add-from-store')}</span>
                                </button> */}
                                  {/* <button
                                  type='button'
                                  className='orange_btn me-2 mb-2'
                                  onClick={handleClick}
                                  disabled={isDisabled}
                                >
                                  <span className='d-inline-block'>
                                    <Link
                                      to={{
                                        pathname: '/deploy-pipeline',
                                        search: `${createSearchParams({
                                          project: projectid as any,
                                          environment: envP,
                                        })}`,
                                      }}
                                    >
                                    </Link>
                                  </span>
                                  {t('project-details.buttons.go-to-queue')}
                                </button> */}
                                </>
                              )}

                              <button
                                type='button'
                                className='roundedButton me-2 mb-2'
                                onClick={() => onActivateIntro()}
                              >
                                <FontAwesomeIcon icon={faQuestion} />
                              </button>
                              {Object.keys(getStoreMeta).length > 0 && getStoreMeta.type === 'wp' && (
                                <button
                                  className='orange_btn d-flex align-items-center composer-project-details-second mb-2 me-2'
                                  onClick={() => setNewPluginsChanges(true)}
                                >
                                  <span className='d-inline-block ms-1'>
                                    {/* <FontAwesomeIcon icon={faFile} /> */}
                                    Available Updates
                                  </span>

                                  <strong style={{ fontSize: 'larger', marginLeft: '5px' }}>
                                    {availableFilterPlugin && availableFilterPlugin.length > 0
                                      ? availableFilterPlugin.length
                                      : 0}
                                  </strong>
                                </button>
                              )}
                              <button
                                className='orange_btn d-flex align-items-center composer-project-details-second mb-2'
                                onClick={() => openNotification()}
                              >
                                <span className='d-inline-block ms-1'>
                                  {/* <FontAwesomeIcon icon={faArrowRotateRight} /> */}
                                  Environment Changes
                                </span>
                                <strong style={{ fontSize: 'larger', marginLeft: '5px' }}>
                                  {envChanges !== null && envChanges.length}
                                </strong>
                              </button>
                            </div>
                          </div>
                          <div className='search_part'>
                            {/* <div className='search'>
                            <div className='search_main'>
                              <input className='m-0' type='text' placeholder='Search here' onChange={handleSearch} />
                              <svg
                                aria-hidden='true'
                                focusable='false'
                                data-prefix='fas'
                                data-icon='magnifying-glass'
                                className='svg-inline--fa fa-magnifying-glass'
                                role='img'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 512 512'
                              >
                                <path
                                  fill='#6DB1BC'
                                  d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z'
                                ></path>
                              </svg>
                            </div>
                          </div> */}
                          </div>
                        </div>
                        <div className='table_filter justify-content-between align-items-center'>
                          <div>
                            <span>{t('project-details.filters.filter-by')}:</span>
                            {storeMeta.length > 0 ? (
                              <>
                                {/* <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='checkboxUnAvailable'
                                checked={showUnAvailable}
                                onChange={() => handleCheckboxChange('showUnAvailable')}
                              />
                              <label className='form-check-label text-secondary' htmlFor='checkboxUnAvailable'>
                                {t('project-details.filters.not-available')}
                              </label>
                            </div> */}
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='checkboxAll'
                                    checked={showAll}
                                    onChange={() => handleCheckboxChange('showAll')}
                                  />
                                  <label className='form-check-label text-secondary' htmlFor='checkboxAll'>
                                    {t('project-details.filters.all')}
                                  </label>
                                </div>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='checkboxTheme'
                                    checked={showTheme}
                                    onChange={() => handleCheckboxChange('showTheme')}
                                  />
                                  <label className='form-check-label text-secondary' htmlFor='checkboxTheme'>
                                    {t('project-details.filters.theme')}
                                  </label>
                                </div>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='checkboxPlugin'
                                    checked={showPlugin}
                                    onChange={() => handleCheckboxChange('showPlugin')}
                                  />
                                  <label className='form-check-label text-secondary' htmlFor='checkboxPlugin'>
                                    {t('project-details.filters.plugin')}
                                  </label>
                                </div>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='checkboxInQueue'
                                    checked={showQueue}
                                    onChange={() => handleCheckboxChange('showInQueue')}
                                  />
                                  <label className='form-check-label text-secondary' htmlFor='checkboxInQueue'>
                                    {t('project-details.filters.in-queue')}
                                  </label>
                                </div>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='checkboxUpdateAvailable'
                                    checked={showUpdateAvailable}
                                    onChange={() => handleCheckboxChange('showUpdateAvailable')}
                                  />
                                  <label className='form-check-label text-secondary' htmlFor='checkboxUpdateAvailable'>
                                    {t('project-details.filters.updates-available')}
                                  </label>
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className='search m-0'>
                            <div className='search_main'>
                              <input className='m-0' type='text' placeholder='Search here' onChange={handleSearch} />
                              <svg
                                aria-hidden='true'
                                focusable='false'
                                data-prefix='fas'
                                data-icon='magnifying-glass'
                                className='svg-inline--fa fa-magnifying-glass'
                                role='img'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 512 512'
                              >
                                <path
                                  fill='#6DB1BC'
                                  d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z'
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='table-responsive'>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th>{t('project-details.table.name')}</th>
                              <th>Versions</th>
                              <th>{t('project-details.table.type')}</th>
                              {/* <th>{t('project-details.table.license')}</th> */}
                              <th>{t('project-details.table.installed-version')}</th>
                              <th>{t('project-details.table.description')}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(getStoreMeta).length > 0 ? (
                              <>
                                {getStoreMeta.type === 'wp' ? (
                                  <>
                                    {pageItems.length > 0 ? (
                                      pageItems.map((plugin: any, index: any) => {
                                        return (
                                          <>
                                            <tr key={plugin.id}>
                                              <td>
                                                <div className='d-flex'>
                                                  <div className='filter'>
                                                    <div className='ck_main'>
                                                      <input className='form-check-input' type='checkbox' id='5' />
                                                    </div>
                                                  </div>
                                                  <div className='align-items-center plg_area mx-2'>
                                                    <span
                                                      className='text-white d-block mb-2'
                                                      style={{ lineHeight: '1' }}
                                                    >
                                                      {plugin.name ? plugin.name : plugin.slug}
                                                    </span>

                                                    <div className='inner_table_select'>
                                                      {/* {Object.prototype.hasOwnProperty.call(plugin, 'versions') && (
                                                      <>
                                                        {Object.prototype.hasOwnProperty.call(
                                                          plugin.versions,
                                                          'versions'
                                                        ) && (
                                                          <>

                                                            <select className='form-select' onChange={(e: any) => handleVersionChange(e, plugin,"wp")}>
                                                              {plugin.versions.versions.map((ikk: any) => {
                                                                return (
                                                                  <option value={ikk.version}>v{ikk.version}</option>
                                                                );
                                                              })}
                                                            </select>
                                                          </>
                                                          )}
                                                      </>
                                                    )} */}
                                                    </div>
                                                  </div>

                                                  {Object.prototype.hasOwnProperty.call(plugin, 'updated_version') && (
                                                    <div className='ms-2'>
                                                      {plugin.updated_version.length > 0 ? (
                                                        <>
                                                          <FontAwesomeIcon
                                                            icon={faInfoCircle}
                                                            style={{ color: '#dc8515' }}
                                                          />
                                                          <span className='ms-1'>
                                                            {t('project-details.table.labels.updates-available')}
                                                          </span>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {plugin.inqueue === true ? (
                                                            <>
                                                              <svg
                                                                width='12'
                                                                height='19'
                                                                viewBox='0 0 12 19'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                              >
                                                                <path
                                                                  d='M0.727539 0.0760498V18.111L6.36354 12.475L11.9995 18.111V0.0760498H0.727539Z'
                                                                  fill='#13A53F'
                                                                />
                                                              </svg>
                                                              <span className='ms-1'>
                                                                {t('project-details.table.labels.in-queue')}
                                                              </span>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <svg
                                                                width='12'
                                                                height='19'
                                                                viewBox='0 0 12 19'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                              >
                                                                <path
                                                                  d='M0.727539 0.0760498V18.111L6.36354 12.475L11.9995 18.111V0.0760498H0.727539Z'
                                                                  fill='#13A53F'
                                                                />
                                                              </svg>
                                                              {plugin.dataType === 'plugin' ? (
                                                                <span className='ms-1'>Plugin</span>
                                                              ) : (
                                                                <span className='ms-1'>Theme</span>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              </td>
                                              <td>
                                                {/* <div  className='inner_table_select'>
                                              {Object.prototype.hasOwnProperty.call(plugin, 'versions') && (
                                                      <>
                                                        {Object.prototype.hasOwnProperty.call(
                                                          plugin.versions,
                                                          'versions'
                                                        ) && (
                                                          <>

                                                            <select className='form-select2 form-select'
                                                               onFocus={(e) => (e.target.size = 10)}
                                                               onBlur={(e) => (e.target.size = 1)}
                                                               onChange={(e) => {
                                                                 e.target.size = 1;
                                                                 e.target.blur();
                                                                 handleVersionChange(e, plugin, "wp");
                                                               }}
                                                            >
                                                              {plugin.versions.versions
                                                                .map((ikk: any) => {
                                                                return (

                                                                  <option key={ikk.id} value={ikk.version}>v{ikk.version}</option>
                                                                );
                                                              })}
                                                            </select>
                                                          </>
                                                          )}
                                                      </>
                                                    )}
                                              </div> */}
                                                <div className='inner_table_select'>
                                                  {Object.prototype.hasOwnProperty.call(plugin, 'versions') && (
                                                    <>
                                                      {Object.prototype.hasOwnProperty.call(
                                                        plugin.versions,
                                                        'versions'
                                                      ) && (
                                                        <>
                                                          {plugin.versions.versions.length === 1 ? (
                                                            <span>{`v${plugin.versions.versions[0].version}`}</span>
                                                          ) : (
                                                            <select
                                                              className='form-select form-select2'
                                                              onFocus={e => (e.target.size = 10)}
                                                              onBlur={e => (e.target.size = 1)}
                                                              onChange={e => {
                                                                e.target.size = 1;
                                                                e.target.blur();
                                                                handleVersionChange(e, plugin, 'wp');
                                                              }}
                                                            >
                                                              {plugin.versions.versions.map((ikk: any) => (
                                                                <option key={ikk.id} value={ikk.version}>
                                                                  v{ikk.version}
                                                                </option>
                                                              ))}
                                                            </select>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                              <td>{plugin.dataType}</td>
                                              {/* <td>126356</td> */}
                                              <td>
                                                <div className='d-flex align-items-center plg_area'>
                                                  <span>{plugin?.version}</span>
                                                </div>
                                              </td>
                                              <td>
                                                <div
                                                  className='description'
                                                  dangerouslySetInnerHTML={{
                                                    __html: plugin.description?.raw,
                                                  }}
                                                ></div>
                                              </td>
                                              <td>
                                                {Object.prototype.hasOwnProperty.call(plugin, 'versions') && (
                                                  <>
                                                    {Object.prototype.hasOwnProperty.call(
                                                      plugin.versions,
                                                      'versions'
                                                    ) && (
                                                      <>
                                                        {plugin.versions.versions.filter((ik: any) =>
                                                          ik.version.includes(plugin.version)
                                                        ).length > 0 && (
                                                          <>
                                                            <button
                                                              className='cblue_btn me-2'
                                                              type='button'
                                                              onClick={() => updateStatus(plugin)}
                                                            >
                                                              {plugin.status}
                                                            </button>
                                                            <button
                                                              className='orange_btn'
                                                              type='button'
                                                              onClick={() => deletePlugin(plugin)}
                                                            >
                                                              Delete
                                                            </button>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan={6} className='text-center text-white font-weight-bold'>
                                          {isLoading1 ? (
                                            <div className='spinner-border m-5' role='status'>
                                              <span className='visually-hidden'>Loading...</span>
                                            </div>
                                          ) : (
                                            t('project-details.labels.no-plugin-found')
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {pageItems.length > 0 &&
                                      pageItems?.map((plugin: any, index: any) => {
                                        const getPlugins = allVersions?.find(i => {
                                          return i.plugin === plugin.plugin_slug;
                                        });
                                        const newVersion =
                                          typeof getPlugins !== 'undefined'
                                            ? getPlugins.versions.map((kk: any) => {
                                                if (
                                                  compareVersions(
                                                    kk.version,
                                                    getVersionOne(plugin) !== undefined
                                                      ? getVersionOne(plugin).replace(/\^/g, '')
                                                      : ''
                                                  )
                                                ) {
                                                  return kk.version;
                                                } else {
                                                  return null;
                                                }
                                              })
                                            : [];
                                        const getActualversion = newVersion?.filter((kb: any) => kb !== null);

                                        return (
                                          <tr key={plugin.id}>
                                            <td>
                                              <div className='d-flex'>
                                                <div className='filter'>
                                                  <div className='ck_main'>
                                                    <input className='form-check-input' type='checkbox' id='5' />
                                                  </div>
                                                </div>
                                                <div className='align-items-center plg_area ms-2'>
                                                  <span
                                                    className='text-white d-block mb-2'
                                                    style={{ lineHeight: '1' }}
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='top'
                                                    title={
                                                      Object.prototype.hasOwnProperty.call(plugin, 'plugin_details')
                                                        ? plugin.plugin_details.name !== ''
                                                          ? plugin.plugin_details.name
                                                          : plugin.plugin_slug
                                                        : plugin.plugin_slug
                                                    }
                                                  >
                                                    {Object.prototype.hasOwnProperty.call(plugin, 'plugin_details')
                                                      ? (plugin.plugin_details.name !== ''
                                                          ? plugin.plugin_details.name
                                                          : plugin.plugin_slug
                                                        ).substring(0, 18)
                                                      : plugin.plugin_slug.substring(0, 18)}
                                                  </span>

                                                  {/* {Object.prototype.hasOwnProperty.call(plugin, 'versions') && (
                                                  <>
                                                    {Object.prototype.hasOwnProperty.call(
                                                      plugin.versions,
                                                      'versions'
                                                    ) && (
                                                        <div className='inner_table_select'>
                                                          {plugin.versions.versions.length > 0 && (
                                                            <>
                                                              <select className='form-select select-dropdown' onChange={(e: any) => handleVersionChange(e, plugin,"composer")} >
                                                                <option>{getVersionOne(plugin)}</option>
                                                                {plugin.versions.versions
                                                                  .slice()
                                                                  .sort((a: any, b: any) => compareVersion(b.version, a.version))
                                                                  .map((ikk: any, ksindex: number) => (
                                                                    <option key={ksindex} value={ikk.version}>
                                                                      {ksindex === 0 ? 'Latest' : `v${ikk.version}`}
                                                                    </option>
                                                                  ))}
                                                              </select>
                                                            </>
                                                          )}
                                                        </div>

                                                      )}
                                                  </>
                                                )} */}
                                                </div>
                                                <div className='ms-2'>
                                                  {Object.prototype.hasOwnProperty.call(plugin, 'updated_version') ? (
                                                    <>
                                                      {getActualversion.length > 0 ? (
                                                        <>
                                                          <FontAwesomeIcon
                                                            icon={faInfoCircle}
                                                            style={{ color: '#dc8515' }}
                                                          />
                                                          <span className='ms-1'>
                                                            {t('project-details.table.labels.updates-available')}
                                                          </span>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {plugin.inqueue === true ? (
                                                            <>
                                                              <svg
                                                                width='12'
                                                                height='19'
                                                                viewBox='0 0 12 19'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                              >
                                                                <path
                                                                  d='M0.727539 0.0760498V18.111L6.36354 12.475L11.9995 18.111V0.0760498H0.727539Z'
                                                                  fill='#13A53F'
                                                                />
                                                              </svg>
                                                              <span className='ms-1'>in Queue</span>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <svg
                                                                width='12'
                                                                height='19'
                                                                viewBox='0 0 12 19'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                              >
                                                                <path
                                                                  d='M0.727539 0.0760498V18.111L6.36354 12.475L11.9995 18.111V0.0760498H0.727539Z'
                                                                  fill='#13A53F'
                                                                />
                                                              </svg>
                                                              <span className='ms-1'>
                                                                {plugin.flag === 'plugin' ? 'Plugin' : 'Theme'}
                                                              </span>
                                                            </>
                                                          )}
                                                          <div className='md-2'>
                                                            {plugin.status === '1' ? null : (
                                                              <button
                                                                className='orange_btn'
                                                                type='button'
                                                                onClick={() => redirectToAddUserPlugin(plugin)}
                                                              >
                                                                {t('project-details.table.buttons.upload-plugins')}
                                                              </button>
                                                            )}
                                                          </div>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <svg
                                                        width='12'
                                                        height='19'
                                                        viewBox='0 0 12 19'
                                                        fill='none'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                      >
                                                        <path
                                                          d='M0.727539 0.0760498V18.111L6.36354 12.475L11.9995 18.111V0.0760498H0.727539Z'
                                                          fill={plugin.status === '1' ? '#13A53F' : 'red'}
                                                        />
                                                      </svg>
                                                      <span className='ms-1'>
                                                        {plugin.status === '1'
                                                          ? t('project-details.table.labels.available')
                                                          : t('project-details.table.labels.not-available')}
                                                      </span>
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <div className='inner_table_select'>
                                                {Object.prototype.hasOwnProperty.call(plugin, 'versions') && (
                                                  <>
                                                    {Object.prototype.hasOwnProperty.call(
                                                      plugin.versions,
                                                      'versions'
                                                    ) && (
                                                      <div className='inner_table_select'>
                                                        {plugin.versions.versions.length > 0 && (
                                                          <>
                                                            <select
                                                              className='form-select2 form-select'
                                                              onFocus={e => (e.target.size = 10)}
                                                              onBlur={e => (e.target.size = 1)}
                                                              // onChange={(e) => {
                                                              //   e.target.size = 1;
                                                              //   e.target.blur();
                                                              //   handleVersionChange(e, plugin, 'wp');
                                                              // }}
                                                              onChange={(e: any) => {
                                                                e.target.size = 1;
                                                                e.target.blur();
                                                                handleVersionChange(e, plugin, 'composer');
                                                              }}
                                                            >
                                                              <option>{getVersionOne(plugin)}</option>
                                                              {plugin.versions.versions
                                                                .slice()
                                                                .sort((a: any, b: any) =>
                                                                  compareVersion(b.version, a.version)
                                                                )
                                                                .map((ikk: any, ksindex: number) => (
                                                                  <option key={ikk.id} value={ikk.version}>
                                                                    {`v${ikk.version}`}
                                                                  </option>
                                                                ))}
                                                            </select>
                                                          </>
                                                        )}
                                                      </div>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            </td>
                                            <td>{plugin.dataType}</td>
                                            {/* <td>126356</td> */}
                                            <td>
                                              <div className='d-flex align-items-center plg_area'>
                                                <span style={{ color: 'white' }}>{getVersionOne(plugin)}</span>
                                              </div>
                                            </td>
                                            <td>
                                              {plugin?.plugin_details ? (
                                                <div
                                                  className='description'
                                                  style={{
                                                    padding: '15px',
                                                    whiteSpace: 'normal',
                                                    wordBreak: 'break-word',
                                                    lineHeight: '1.5',
                                                    width: '600px', // Adjust the width to control the number of words per line
                                                  }}
                                                >
                                                  {plugin.plugin_details.short_description !== ''
                                                    ? plugin.plugin_details.short_description
                                                    : plugin.plugin_details.description}
                                                  <span
                                                    style={{
                                                      cursor: 'pointer',
                                                      marginLeft: '10px',
                                                      color: 'white',
                                                      textDecoration: 'none',
                                                    }}
                                                    onClick={() => clickMore(plugin.plugin_details.description)}
                                                  >
                                                    Click here
                                                  </span>
                                                </div>
                                              ) : null}
                                            </td>

                                            <td>
                                              <div className='text-end'>
                                                <div className='dropdown'>
                                                  <button
                                                    className='btn btn-secondary dropdown-toggle rounded-circle project-dropdown secondary-bg'
                                                    type='button'
                                                    id='dropdownMenuButton1'
                                                    data-bs-toggle='dropdown'
                                                    aria-expanded='false'
                                                  >
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                  </button>
                                                  <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                                                    {/* <li>
                                                    <Link className='dropdown-item' to='#'>
                                                      {t('project-details.table.select.edit')}
                                                    </Link>
                                                  </li> */}
                                                    {/* <li>
                                                    <Link className='dropdown-item' to='#'>
                                                      {t('project-details.table.select.move-or-copy')}
                                                    </Link>
                                                  </li> */}
                                                    <li>
                                                      <Link className='dropdown-item' to='#'>
                                                        {t('project-details.table.select.view-history')}
                                                      </Link>
                                                    </li>
                                                    {/* <li>
                                                    <Link className='dropdown-item' to='#'>
                                                      {t('project-details.table.select.quickly-add-subtasks')}
                                                    </Link>
                                                  </li> */}
                                                    <li>
                                                      <Link
                                                        className='dropdown-item'
                                                        to={`/store/${plugin.plugin_slug}`}
                                                      >
                                                        {t('project-details.table.select.view')}
                                                      </Link>
                                                    </li>
                                                    {/* <li>
                                                    <Link className='dropdown-item' to='#'>
                                                      {t('project-details.table.select.properties')}
                                                    </Link>
                                                  </li> */}
                                                    {/* <li>
                                                    <Link className='dropdown-item' to='#'>
                                                      {t('project-details.table.select.duplicate')}
                                                    </Link>
                                                  </li> */}
                                                    {/* <li>
                                                    <Link className='dropdown-item' to='#'>
                                                      {t('project-details.table.select.status')}
                                                    </Link>
                                                  </li> */}
                                                    <li>
                                                      <Link className='dropdown-item' to='#'>
                                                        {t('project-details.table.select.delete')}
                                                      </Link>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </>
                                )}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={6} style={{ textAlign: 'center' }}>
                                  {isLoading ? (
                                    <div className='spinner-border m-5' role='status'>
                                      <span className='visually-hidden'>Loading...</span>
                                    </div>
                                  ) : (
                                    t('project-details.table.labels.no-plugin-found')
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      {/* {pageItems.length && (
                      <PaginatedList
                        page={page}
                        setPage={(value: number) => handlePageChange(value)}
                        pages={totalPages}
                      />
                    )} */}
                    </div>
                  </div>
                  <div className='tab-pane' id='team' role='tabpanel' aria-labelledby='team-tab'>
                    <div>
                      <h1 className='text-white'>{t('project-details.teams.feature-coming-soon')}</h1>
                    </div>
                  </div>
                  <div className='tab-pane' id='settings' role='tabpanel' aria-labelledby='settings-tab'></div>
                </div>
              </div>
              <div id='download_auth_json'></div>
            </div>
            <CommonModal className='composer-model' show={show} onHide={handleClose} animation={false} size='lg'>
              <CommonModal.Header>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                  <h4 className='text-white'>{t('project-details.modals.auth-file.label')}</h4>
                  <FontAwesomeIcon icon={faClose} onClick={handleClose} style={{ color: 'white' }} size='lg' />
                </div>
              </CommonModal.Header>
              <CommonModal.Body>
                <h5 className='mb-2 secondary-color'>
                  {t('project-details.modals.auth-file.sub-label')} : {envList[+environment - 1]}
                </h5>
                <div>
                  <div className='row text-white'>
                    <div className='col-md-6'>{t('project-details.modals.auth-file.table.plugin-theme')}</div>
                    <div className='col-md-4'>{t('project-details.modals.auth-file.table.version')}</div>
                    <div className='col-md-2'>{t('project-details.modals.auth-file.table.action')}</div>
                  </div>
                  {userPlugins?.plugins.map((item: any) => {
                    return (
                      <div key={item.id} className='row my-3 text-secondary'>
                        <div className='col-md-6'>{item.name}</div>
                        <div className='col-md-4'>
                          <select
                            className='environment-select form-select w-auto bg-none'
                            aria-label='Default select example'
                            onChange={e => {
                              changeCompPlugVersionModel(item.slug, e.target.value);
                            }}
                          >
                            {item.versions.length > 0 ? (
                              item.versions.map((v: any) => {
                                return (
                                  <option key={v.id} value={v.version}>
                                    {v.version}
                                  </option>
                                );
                              })
                            ) : (
                              <option value='3'>No</option>
                            )}
                          </select>
                        </div>
                        <div className='col-md-2'>
                          <button className='orange_btn' onClick={(e: any) => AddComposerQueueList(item)}>
                            {t('project-details.modals.auth-file.table.button.add')}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </CommonModal.Body>
              <CommonModal.Footer>
                <button className='orange_btn' onClick={handleClose}>
                  {t('project-details.modals.auth-file.table.button.close')}
                </button>
              </CommonModal.Footer>
            </CommonModal>

            <CommonModal
              className='composer-model'
              show={clickMoreData}
              onHide={clickMoreClose}
              animation={false}
              size='lg'
            >
              <CommonModal.Header>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                  <FontAwesomeIcon icon={faClose} onClick={clickMoreClose} style={{ color: 'white' }} size='lg' />
                </div>
              </CommonModal.Header>
              <CommonModal.Body>
                <div dangerouslySetInnerHTML={{ __html: seeMoreData }} className='text-white' />
              </CommonModal.Body>
              <CommonModal.Footer>
                <button className='orange_btn' onClick={clickMoreClose}>
                  {t('project-details.modals.auth-file.table.button.close')}
                </button>
              </CommonModal.Footer>
            </CommonModal>

            <CommonModal
              className='composer-model'
              show={privatePackagesModal}
              onHide={closePrivatePackage}
              animation={false}
              size='lg'
            >
              <CommonModal.Header>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                  <h4 className='text-white'>{t('project-details.modals.private-plugin.label')}</h4>
                  <button className='orange_btn inline-block' type='button' onClick={() => downloadAuthJson()}>
                    {t('project-details.modals.private-plugin.buttons.auth-file-download')}
                  </button>
                  <FontAwesomeIcon icon={faClose} onClick={closePrivatePackage} style={{ color: 'white' }} size='lg' />
                </div>
              </CommonModal.Header>
              <CommonModal.Body>
                <div>
                  <h6 className='text-white'>{t('project-details.modals.private-plugin.description')}</h6>
                </div>
                <div>
                  <div className='row text-white'>
                    <div className='col-md-6'>{t('project-details.modals.private-plugin.table.plugin-theme')}</div>
                    <div className='col-md-4'>{t('project-details.modals.private-plugin.table.version')}</div>
                  </div>
                  {privatePackages.map((item: any) => {
                    return (
                      <div key={item.id} className='row my-3 text-secondary'>
                        <div className='col-md-6'>{item.package.name}</div>
                        <div className='col-md-6'>{item.package.version}</div>
                      </div>
                    );
                  })}
                </div>
              </CommonModal.Body>
              <CommonModal.Footer>
                <button className='orange_btn' onClick={closePrivatePackage}>
                  {t('project-details.modals.private-plugin.buttons.close')}
                </button>
              </CommonModal.Footer>
            </CommonModal>
            <CommonAlert
              onHide={setOpenEnvChangesPopup}
              show={openEnvChangesPopup}
              loading={loading}
              message={'Are you sure you want to save all changes to queue'}
              clickFunc={() => saveToQueue()}
            />
 
            <CommonAlert
              onHide={() => setOpenVersionChangesPopup(false)}
              show={openVersionChangesPopup}
              loading={loading}
              message={"Are you sure want to update version"}
              clickFunc={() => clickToVersion()}
            />

            <CommonModal
              className='composer-model'
              show={showNotification}
              onHide={closeNotification}
              animation={false}
            >
              <CommonModal.Header>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                  <h4 className='mb-2 secondary-color'>Environment Changes</h4>
                  <FontAwesomeIcon icon={faClose} onClick={closeNotification} style={{ color: 'white' }} size='lg' />
                </div>
              </CommonModal.Header>
              <CommonModal.Body style={{ maxWidth: '100%', maxHeight: '400px', overflowY: 'auto', overflowX: 'auto' }}>
                <div className='table-responsive'>
                  {/* {getStoreMeta?.type === 'wp' ?
                  <div className="alert alert-warning" role="alert">
                    <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;&nbsp; {t('project-details.modals.updates.message.error')}
                  </div>
                  : null} */}
                  {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {getStoreMeta?.type === "wp" &&
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="new-plugin-tab" data-bs-toggle="tab" data-bs-target="#new-plugin-tab-pane" type="button" role="tab" aria-controls="new-plugin-tab-pane" aria-selected="true" onClick={() => setConditionalButtons('newplugin')}>{t('project-details.modals.updates.new-plugins.label')} {newWpPlugin.length}</button>
                    </li>}
                  <li className="nav-item" role="presentation">
                    <button className={getStoreMeta?.type !== "wp" ? "nav-link active" : "nav-link"} id="update-tab" data-bs-toggle="tab" data-bs-target="#update-tab-pane" type="button" role="tab" aria-controls="update-tab-pane" aria-selected="false" onClick={() => setConditionalButtons('update')}>{t('project-details.modals.updates.update.label')} {getUpdateVersionPlugin.length}</button>
                  </li>

                </ul> */}
                  <table className='table'>
                    <thead>
                      <tr>
                        {/* <th className='text-white fw-bold '>{t('project-details.modals.updates.update.table.select')}</th> */}
                        <th className='text-white fw-bold '>
                          {t('project-details.modals.updates.update.table.plugin-name')}
                        </th>
                        <th className='text-white fw-bold '>
                          {t('project-details.modals.updates.update.table.env-ver')}
                        </th>
                        <th className='text-white fw-bold '>
                          {t('project-details.modals.updates.update.table.available-version')}
                        </th>
                        {/* <th className='text-white fw-bold '>{t('project-details.modals.updates.update.table.slug')}</th> */}
                        <th className='text-white fw-bold '>Action performed</th>
                        <th className='text-white fw-bold '>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {envChanges !== null &&
                        envChanges.length > 0 &&
                        envChanges.map((item: any, index: number) => {
                          return (
                            <tr key={item.id}>
                              {/* <td>

                                  </td> */}
                              <td>
                                <div className='d-flex justify-content-between'>
                                  <input
                                    id={`compcheckbox-${index}`}
                                    type='checkbox'
                                    className='form-check-input'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                      onSelectPluginVersion(e, item, index)
                                    }
                                  />
                                  <span style={{ background: 'none', border: 'none' }}>{item.plugin?.slug}</span>
                                </div>
                              </td>
                              <td>{item.version}</td>
                              <td className='text-white fw-bold '>{item.plugin_version}</td>
                              {/* <td>{item.plugin.slug}</td> */}
                              <td>
                                <span style={{ color: getColor(item.action), border: 'none', background: 'none' }}>
                                  {item.action}
                                </span>
                              </td>

                              {/* <td>
                                    {Object.prototype.hasOwnProperty.call(item, 'plugin') ? (
                                      <div
                                        dangerouslySetInnerHTML={{ __html: item.plugin.description }}
                                      />
                                    ) : null}
                                  </td> */}
                              <td>
                                <button className='remove_btn' onClick={() => deleteAChange(item)}>
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <hr />
                </div>
              </CommonModal.Body>
              <CommonModal.Footer>
                <button
                  type='button'
                  className='orange_btn'
                  onClick={isCheckAll ? () => checkAll('decheck') : () => checkAll('check')}
                >
                  {isCheckAll
                    ? t('project-details.modals.updates.buttons.check.deselect')
                    : t('project-details.modals.updates.buttons.check.select')}
                </button>
                <button
                  type='button'
                  className='orange_btn mx-2'
                  onClick={() => saveLatestVersionDatabase()}
                  disabled={addListOfUpdate.length > 0 ? false : true}
                >
                  {t('project-details.modals.updates.buttons.save')}
                </button>
              </CommonModal.Footer>
            </CommonModal>
            <CommonModal
              className='composer-model'
              show={newPluginsChanges}
              onHide={() => setNewPluginsChanges(false)}
              animation={false}
            >
              <CommonModal.Header>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                  <h4 className='mb-2 secondary-color'>Available updates</h4>
                  <FontAwesomeIcon
                    icon={faClose}
                    onClick={() => setNewPluginsChanges(false)}
                    style={{ color: 'white' }}
                    size='lg'
                  />
                </div>
              </CommonModal.Header>
              <CommonModal.Body style={{ maxWidth: '100%', maxHeight: '400px', overflowY: 'auto', overflowX: 'auto' }}>
                <div className='table-responsive'>
                  {/* {getStoreMeta?.type === 'wp' ?
                  <div className="alert alert-warning" role="alert">
                    <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;&nbsp; {t('project-details.modals.updates.message.error')}
                  </div>
                  : null} */}
                  {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {getStoreMeta?.type === "wp" &&
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="new-plugin-tab" data-bs-toggle="tab" data-bs-target="#new-plugin-tab-pane" type="button" role="tab" aria-controls="new-plugin-tab-pane" aria-selected="true" onClick={() => setConditionalButtons('newplugin')}>{t('project-details.modals.updates.new-plugins.label')} {newWpPlugin.length}</button>
                    </li>}
                  <li className="nav-item" role="presentation">
                    <button className={getStoreMeta?.type !== "wp" ? "nav-link active" : "nav-link"} id="update-tab" data-bs-toggle="tab" data-bs-target="#update-tab-pane" type="button" role="tab" aria-controls="update-tab-pane" aria-selected="false" onClick={() => setConditionalButtons('update')}>{t('project-details.modals.updates.update.label')} {getUpdateVersionPlugin.length}</button>
                  </li>

                </ul> */}
                  <div>
                    {getStoreMeta?.type === 'wp' ? (
                      <table className='table'>
                        <thead>
                          <tr>
                            <th className='text-white fw-bold '>
                              {t('project-details.modals.updates.new-plugins.table.plugin-name')}
                            </th>
                            <th className='text-white fw-bold '>
                              {t('project-details.modals.updates.new-plugins.table.env-ver')}
                            </th>
                            <th className='text-white fw-bold '>
                              {t('project-details.modals.updates.new-plugins.table.available-version')}
                            </th>
                            <th className='text-white fw-bold '>
                              {t('project-details.modals.updates.new-plugins.table.slug')}
                            </th>
                            <th className='text-white fw-bold '>
                              {t('project-details.modals.updates.new-plugins.table.description')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {newWpPlugin.length > 0 &&
                            newWpPlugin
                              .map((item: any, index: number) => {
                                return (
                                  <tr>

                                    <td>
                                      {item.name}
                                    </td>
                                    <td>
                                      {item.version}
                                    </td>
                                    <td className='text-white fw-bold '>{item.version}</td>
                                    <td>{item.plugin}</td>
                                    <td>
                                      <div
                                        dangerouslySetInnerHTML={{ __html: item.description.rendered }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })} */}
                          {availableFilterPlugin &&
                            availableFilterPlugin.length > 0 &&
                            availableFilterPlugin.map((item: any, index: number) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.name}</td>
                                  <td>{item.version}</td>
                                  <td className='text-white fw-bold '>
                                    {item.updated_version.length > 0 &&
                                      item.updated_version[item.updated_version.length - 1]}
                                  </td>
                                  <td>{item.plugin}</td>
                                  <td>
                                    <div dangerouslySetInnerHTML={{ __html: item.description.rendered }} />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    ) : null}
                  </div>

                  <hr />
                </div>
              </CommonModal.Body>
              <CommonModal.Footer>
                {/* <button type='button' className='orange_btn' onClick={() => addNewWPPluginInQueue()}>
                    {t('project-details.modals.updates.buttons.save')}
                  </button> */}
              </CommonModal.Footer>
            </CommonModal>
            <CommonModal
              className='composer-model'
              show={showSettings}
              onHide={handleSettingClose}
              animation={false}
              size='lg'
            >
              <CommonModal.Body>
                <form className='addproject' onSubmit={e => onEnvSubmit(e)}>
                  <div className='input-group mb-3'>
                    <span className='input-group-text'>{t('project-details.modals.environment.environment')}</span>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Environment name'
                      name='environment'
                      value={createEnv.environment_name}
                      onChange={e => {
                        setCreateEnv({ ...createEnv, environment_name: e.target.value });
                        if (addEnvError.environment_name) {
                          setEnvError((prevErrors: any) => ({ ...prevErrors, environment_name: null }));
                        }
                      }}
                    />
                  </div>
                  {addEnvError.environment_name && (
                    <div style={{ color: 'red', fontSize: '14px' }}>{addEnvError.environment_name}</div>
                  )}
                  <div className='input-group mb-3'>
                    <span className='input-group-text'>{t('project-details.modals.environment.site-url')}</span>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Environment Url'
                      name='site_url'
                      value={createEnv.site_url}
                      onChange={e => {
                        setCreateEnv({ ...createEnv, site_url: e.target.value });
                        if (addEnvError.site_url) {
                          setEnvError((prevErrors: any) => ({ ...prevErrors, site_url: null }));
                        }
                      }}
                    />
                  </div>
                  {addEnvError.site_url && <div style={{ color: 'red', fontSize: '14px' }}>{addEnvError.site_url}</div>}
                  <div className='d-flex justify-content-end my-5'>
                    <button className='orange_btn mx-1'>
                      {t('project-details.modals.environment.buttons.save-project')}
                    </button>
                  </div>
                </form>
              </CommonModal.Body>
            </CommonModal>
            <CommonModal
              className='composer-model'
              show={openEnvConfigModal}
              onHide={closeEnvSetting}
              animation={false}
              size='lg'
            >
              <CommonModal.Body>
                <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='pills-wp-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-wp'
                      type='button'
                      role='tab'
                      aria-controls='pills-home'
                      aria-selected='true'
                    >
                      {t('project-details.modals.config-project.labels.wp-connector')}
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-composer-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-composer'
                      type='button'
                      role='tab'
                      aria-controls='pills-profile'
                      aria-selected='false'
                    >
                      {t('project-details.modals.config-project.labels.composer')}
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-ftp-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-ftp'
                      type='button'
                      role='tab'
                      aria-controls='pills-contact'
                      aria-selected='false'
                      disabled={true}
                    >
                      {t('project-details.modals.config-project.labels.ftp')}
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-ssh-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-ssh'
                      type='button'
                      role='tab'
                      aria-controls='pills-contact'
                      aria-selected='false'
                      disabled={true}
                    >
                      {t('project-details.modals.config-project.labels.ssh')}
                    </button>
                  </li>
                </ul>
                <div className='tab-content' id='pills-tabContent'>
                  <div
                    className='tab-pane fade show active text-white'
                    id='pills-wp'
                    role='tabpanel'
                    aria-labelledby='pills-wp-tab'
                  >
                    <form className='addproject' onSubmit={e => onProjectConfigStep(e, 'wp_connector')}>
                      <div className='alert alert-warning' role='alert'>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        &nbsp;&nbsp;
                        {/* {t('project-details.modals.config-project.wp-connector.message.error')} */}
                        Please download{' '}
                        <a
                          href='https://fusepress.fra1.cdn.digitaloceanspaces.com/Wp-connector/plugins-updater.zip'
                          download
                        >
                          Plugin updater
                        </a>
                        &nbsp; and install this plugin to use REST API connection method
                      </div>
                      <div className='input-group mb-3'>
                        <span className='input-group-text'>
                          {t('project-details.modals.config-project.wp-connector.username')}
                        </span>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Enter user name'
                          name='username'
                          value={confEnv.username}
                          onChange={e => {
                            setConfEnv({ ...confEnv, username: e.target.value });
                            if (wpError.username) {
                              setWpError((prevErrors: any) => ({ ...prevErrors, username: null }));
                            }
                          }}
                        />
                      </div>
                      {wpError.username && <div style={{ color: 'red', fontSize: '14px' }}>{wpError.username}</div>}
                      <div className='input-group mb-3'>
                        <span className='input-group-text'>
                          {t('project-details.modals.config-project.wp-connector.password')}
                        </span>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Enter user password'
                          name='password'
                          value={confEnv.password}
                          onChange={e => {
                            setConfEnv({ ...confEnv, password: e.target.value });
                            if (wpError.password) {
                              setWpError((prevErrors: any) => ({ ...prevErrors, password: null }));
                            }
                          }}
                        />
                      </div>
                      {wpError.password && <div style={{ color: 'red', fontSize: '14px' }}>{wpError.password}</div>}
                      <div className='input-group mb-3'>
                        <span className='input-group-text'>
                          {t('project-details.modals.config-project.wp-connector.wp-url')}
                        </span>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Enter wp url'
                          name='WP URL'
                          value={confEnv.wp_url}
                          readOnly
                          onChange={e => {
                            setConfEnv({ ...confEnv, wp_url: e.target.value });
                            if (wpError.wp_url) {
                              setWpError((prevErrors: any) => ({ ...prevErrors, wp_url: null }));
                            }
                          }}
                        />
                      </div>
                      {wpError.wp_url && <div style={{ color: 'red', fontSize: '14px' }}>{wpError.wp_url}</div>}
                      <div className='d-flex justify-content-end my-5'>
                        <button
                          className='orange_btn mx-1'
                          disabled={projectConfWpDisableButton === true ? true : false}
                        >
                          {projectConfWpDisableButton === true
                            ? `Loading...`
                            : t('project-details.modals.config-project.wp-connector.buttons.save-project')}
                        </button>
                      </div>
                    </form>
                  </div>
                  <div
                    className='tab-pane fade text-white'
                    id='pills-composer'
                    role='tabpanel'
                    aria-labelledby='pills-composer-tab'
                  >
                    <div className='flex justify-content-start'>
                      <div className='login_register' style={{ justifyContent: 'start' }}>
                        <span>{t('project-details.modals.config-project.composer.composer-upload.label')}</span>
                        <label className='switch'>
                          <input
                            type='checkbox'
                            checked={checkbox}
                            id='select_login_screen'
                            name='select_login_screen'
                            onChange={e => setCheckbox(!checkbox)}
                          />
                          <span className='slider round'></span>
                        </label>
                        <span>{t('project-details.modals.config-project.composer.composer-repository.label')}</span>
                      </div>
                    </div>
                    {!checkbox ? (
                      <div>
                        <form className='addproject' onSubmit={e => onProjectConfigStep(e, 'composer')}>
                          <div className='input-group mb-3'>
                            <span className='input-group-text upload'>
                              {t('project-details.modals.config-project.composer.composer-upload.upload-composer-json')}
                            </span>
                          </div>
                          <div className='d-flex mb-3'>
                            <input
                              className='form-control d-none'
                              type='file'
                              id='formFile'
                              onChange={handleFileChange}
                            />
                            <input
                              className={
                                !isFileAdded
                                  ? 'form-control'
                                  : isFileAdded === 'valid'
                                  ? 'form-control green_border'
                                  : 'form-control red_border'
                              }
                              type='text'
                              value={file}
                              readOnly
                            />
                            <button type='button' className='ms-2 file-btn'>
                              <label htmlFor='formFile'>
                                {t('project-details.modals.config-project.composer.composer-upload.buttons.browse')}
                              </label>
                            </button>
                          </div>
                          {composerError.composer_file && (
                            <div style={{ color: 'red', fontSize: '14px' }}>{composerError.composer_file}</div>
                          )}
                          <div className='input-group mb-3'>
                            <span className='input-group-text upload'>
                              {t('project-details.modals.config-project.composer.composer-upload.upload-auth-json')}
                            </span>
                          </div>
                          <div className='d-flex mb-3'>
                            <input
                              className='form-control d-none'
                              type='file'
                              id='formFile1'
                              onChange={handleAuthFileChange}
                            />
                            <input
                              className={
                                !isFileAuthAdded
                                  ? 'form-control'
                                  : isFileAuthAdded === 'valid'
                                  ? 'form-control green_border'
                                  : 'form-control red_border'
                              }
                              type='text'
                              value={fileTwo}
                              readOnly
                            />
                            <button type='button' className='ms-2 file-btn'>
                              <label htmlFor='formFile1'>
                                {t('project-details.modals.config-project.composer.composer-upload.buttons.browse')}
                              </label>
                            </button>
                          </div>

                          <div className='input-group mb-3'>
                            <span className='input-group-text upload'>
                              {t('project-details.modals.config-project.composer.composer-upload.upload-composer-lock')}
                            </span>
                          </div>
                          <div className='d-flex mb-3'>
                            <input
                              className='form-control d-none'
                              type='file'
                              id='formFile2'
                              onChange={handleLockFileChange}
                            />
                            <input
                              className={
                                !isLockFileAdded
                                  ? 'form-control'
                                  : isLockFileAdded === 'valid'
                                  ? 'form-control green_border'
                                  : 'form-control red_border'
                              }
                              type='text'
                              value={fileThree}
                              readOnly
                            />
                            <button type='button' className='ms-2 file-btn'>
                              <label htmlFor='formFile2'>
                                {t('project-details.modals.config-project.composer.composer-upload.buttons.browse')}
                              </label>
                            </button>
                          </div>

                          <div className='d-flex justify-content-end my-5'>
                            <button
                              className='orange_btn mx-1'
                              disabled={projectConfCompDisableButton === true ? true : false}
                            >
                              {projectConfCompDisableButton === true
                                ? `Loading...`
                                : t(
                                    'project-details.modals.config-project.composer.composer-upload.buttons.save-project'
                                  )}
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div>
                        <form className='addproject'>
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>
                              {t('project-details.modals.config-project.composer.composer-repository.repository-url')}
                            </span>
                            <input type='text' className='form-control' placeholder='https://' readOnly />
                          </div>
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>
                              {t('project-details.modals.config-project.composer.composer-repository.http-user')}
                            </span>
                            <input type='text' className='form-control' placeholder='Enter name' readOnly />
                          </div>
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>
                              {t('project-details.modals.config-project.composer.composer-repository.ssh-password')}
                            </span>
                            <input type='text' className='form-control' placeholder='Enter SSH Password' readOnly />
                          </div>
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>
                              {t('project-details.modals.config-project.composer.composer-repository.composer-path')}
                            </span>
                            <input type='text' className='form-control' placeholder='Enter path' readOnly />
                          </div>
                          <div className='d-flex justify-content-end my-5'>
                            <button className='orange_btn mx-1' disabled={true}>
                              {t(
                                'project-details.modals.config-project.composer.composer-repository.buttons.save-projects'
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                  <div
                    className='tab-pane fade text-white'
                    id='pills-ftp'
                    role='tabpanel'
                    aria-labelledby='pills-ftp-tab'
                  >
                    <div>
                      <form className='addproject' onSubmit={e => onProjectConfigStep(e, 'ftp')}>
                        <div className='input-group mb-3'>
                          <span className='input-group-text'>
                            {t('project-details.modals.config-project.ftp.address')}
                          </span>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='https://'
                            name='url'
                            value={ftpData.url}
                            onChange={e => {
                              setFtpData({
                                ...ftpData,
                                url: e.target.value,
                              });
                              if (ftpError.url) {
                                setFtpError((prevErrors: any) => ({ ...prevErrors, url: null }));
                              }
                            }}
                          />
                        </div>
                        {ftpError.url && <div style={{ color: 'red', fontSize: '14px' }}>{ftpError.url}</div>}
                        <div className='input-group mb-3'>
                          <span className='input-group-text'>
                            {t('project-details.modals.config-project.ftp.user')}
                          </span>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter User'
                            name='username'
                            value={ftpData.username}
                            onChange={e => {
                              setFtpData({
                                ...ftpData,
                                username: e.target.value,
                              });
                              if (ftpError.username) {
                                setFtpError((prevErrors: any) => ({ ...prevErrors, username: null }));
                              }
                            }}
                          />
                        </div>
                        {ftpError.username && <div style={{ color: 'red', fontSize: '14px' }}>{ftpError.username}</div>}
                        <div
                          className='tab-pane fade text-white active show'
                          id='pills-composer'
                          role='tabpanel'
                          aria-labelledby='pills-composer-tab'
                        >
                          <div className='flex justify-content-start'>
                            <div className='login_register' style={{ justifyContent: 'start' }}>
                              <span>{t('project-details.modals.config-project.ftp.password')}</span>
                              <label className='switch'>
                                <input
                                  type='checkbox'
                                  checked={checkboxFtp}
                                  id='select_login_screen'
                                  name='select_login_screen'
                                  onChange={e => setCheckboxFtp(!checkboxFtp)}
                                />
                                <span className='slider round'></span>
                              </label>
                              <span>{t('project-details.modals.config-project.ftp.private-key')}</span>
                            </div>
                          </div>
                          {!checkboxFtp ? (
                            <div>
                              <div className='input-group mb-3'>
                                <span className='input-group-text'>
                                  {t('project-details.modals.config-project.ftp.password')}
                                </span>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Enter Password'
                                  name='password'
                                  value={ftpData.password}
                                  onChange={e => {
                                    setFtpData({
                                      ...ftpData,
                                      password: e.target.value,
                                    });
                                    if (ftpError.password) {
                                      setFtpError((prevErrors: any) => ({ ...prevErrors, password: null }));
                                    }
                                  }}
                                />
                              </div>
                              {ftpError.password && (
                                <div style={{ color: 'red', fontSize: '14px' }}>{ftpError.password}</div>
                              )}
                              <div className='input-group mb-3'>
                                <span className='input-group-text'>
                                  {t('project-details.modals.config-project.ftp.wp-content-path')}
                                </span>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Enter path'
                                  name='path_to_wp_content'
                                  value={ftpData.path_to_wp_content}
                                  onChange={e => {
                                    setFtpData({
                                      ...ftpData,
                                      path_to_wp_content: e.target.value,
                                    });
                                    if (ftpError.path_to_wp_content) {
                                      setFtpError((prevErrors: any) => ({ ...prevErrors, path_to_wp_content: null }));
                                    }
                                  }}
                                />
                              </div>
                              {ftpError.path_to_wp_content && (
                                <div style={{ color: 'red', fontSize: '14px' }}>{ftpError.path_to_wp_content}</div>
                              )}
                            </div>
                          ) : (
                            <div>
                              <div className='input-group mb-3'>
                                <span className='input-group-text upload'>
                                  {t('project-details.modals.config-project.ftp.key-file')}
                                </span>
                              </div>
                              <div className='d-flex mb-3'>
                                <input
                                  className='form-control d-none'
                                  type='file'
                                  id='formftpFile'
                                  onChange={handleFtpFileChange}
                                />
                                <input
                                  className={
                                    !isFtpFileAdded
                                      ? 'form-control'
                                      : isFtpFileAdded === 'valid'
                                      ? 'form-control green_border'
                                      : 'form-control red_border'
                                  }
                                  type='text'
                                  value={fileFtp}
                                  readOnly
                                />
                                <button type='button' className='ms-2 file-btn'>
                                  <label htmlFor='formftpFile'>
                                    {t('project-details.modals.config-project.ftp.buttons.browse')}
                                  </label>
                                </button>
                                {ftpError.file && <div style={{ color: 'red', fontSize: '14px' }}>{ftpError.file}</div>}
                              </div>
                              <div className='input-group mb-3'>
                                <span className='input-group-text'>
                                  {t('project-details.modals.config-project.ftp.wp-content-path')}
                                </span>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Enter path'
                                  name='path_to_wp_content'
                                  value={ftpData.path_to_wp_content}
                                  onChange={e => {
                                    setFtpData({
                                      ...ftpData,
                                      path_to_wp_content: e.target.value,
                                    });
                                    if (ftpError.path_to_wp_content) {
                                      setFtpError((prevErrors: any) => ({ ...prevErrors, path_to_wp_content: null }));
                                    }
                                  }}
                                />
                              </div>
                              {ftpError.path_to_wp_content && (
                                <div style={{ color: 'red', fontSize: '14px' }}>{ftpError.path_to_wp_content}</div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className='d-flex justify-content-end my-5'>
                          <button className='cblue_btn mx-1'>
                            {t('project-details.modals.config-project.ftp.buttons.test-connection')}
                          </button>
                          <button className='orange_btn mx-1'>
                            {t('project-details.modals.config-project.ftp.buttons.save-project')}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade text-white'
                    id='pills-ssh'
                    role='tabpanel'
                    aria-labelledby='pills-ssh-tab'
                  >
                    <div>
                      <form className='addproject'>
                        <div className='input-group mb-3'>
                          <span className='input-group-text'>
                            {t('project-details.modals.config-project.ssh.address')}
                          </span>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='https://'
                            name='url'
                            value={sshData.url}
                            onChange={e => {
                              setSshData({
                                ...sshData,
                                url: e.target.value,
                              });
                              if (sshError.url) {
                                setSshError((prevErrors: any) => ({ ...prevErrors, url: null }));
                              }
                            }}
                          />
                        </div>
                        {sshError.url && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.url}</div>}
                        <div className='input-group mb-3'>
                          <span className='input-group-text'>
                            {t('project-details.modals.config-project.ssh.user')}
                          </span>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter User'
                            name='username'
                            value={sshData.username}
                            onChange={e => {
                              setSshData({
                                ...sshData,
                                username: e.target.value,
                              });
                              if (sshError.username) {
                                setSshError((prevErrors: any) => ({ ...prevErrors, username: null }));
                              }
                            }}
                          />
                        </div>
                        {sshError.username && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.username}</div>}
                        <div
                          className='tab-pane fade text-white active show'
                          id='pills-composer'
                          role='tabpanel'
                          aria-labelledby='pills-composer-tab'
                        >
                          <div className='flex justify-content-start'>
                            <div className='login_register' style={{ justifyContent: 'start' }}>
                              <span>{t('project-details.modals.config-project.ssh.password')}</span>
                              <label className='switch'>
                                <input
                                  type='checkbox'
                                  checked={checkboxSsh}
                                  id='select_login_screen'
                                  name='select_login_screen'
                                  onChange={e => setCheckboxSsh(!checkboxSsh)}
                                />
                                <span className='slider round'></span>
                              </label>
                              <span>{t('project-details.modals.config-project.ssh.private-key')}</span>
                            </div>
                          </div>
                          {!checkboxSsh ? (
                            <div>
                              <div className='input-group mb-3'>
                                <span className='input-group-text'>
                                  {t('project-details.modals.config-project.ssh.password')}
                                </span>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Enter Password'
                                  name='password'
                                  value={sshData.password}
                                  onChange={e => {
                                    setSshData({
                                      ...sshData,
                                      password: e.target.value,
                                    });
                                    if (sshError.password) {
                                      setSshError((prevErrors: any) => ({ ...prevErrors, password: null }));
                                    }
                                  }}
                                />
                              </div>
                              {sshError.password && (
                                <div style={{ color: 'red', fontSize: '14px' }}>{sshError.password}</div>
                              )}
                              <div className='input-group mb-3'>
                                <span className='input-group-text'>
                                  {t('project-details.modals.config-project.ssh.path-wordpress-install')}
                                </span>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Enter path'
                                  name='path_to_wp_installation'
                                  value={sshData.path_to_wp_installation}
                                  onChange={e => {
                                    setSshData({
                                      ...sshData,
                                      path_to_wp_installation: e.target.value,
                                    });
                                    if (sshError.path_to_wp_installation) {
                                      setSshError((prevErrors: any) => ({
                                        ...prevErrors,
                                        path_to_wp_installation: null,
                                      }));
                                    }
                                  }}
                                />
                              </div>
                              {sshError.path_to_wp_installation && (
                                <div style={{ color: 'red', fontSize: '14px' }}>{sshError.path_to_wp_installation}</div>
                              )}
                              <div className='input-group mb-3'>
                                <span className='input-group-text'>
                                  {t('project-details.modals.config-project.ssh.wp-content-path')}
                                </span>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Enter path'
                                  name='path_to_wp_content_folder'
                                  value={sshData.path_to_wp_content_folder}
                                  onChange={e => {
                                    setSshData({
                                      ...sshData,
                                      path_to_wp_content_folder: e.target.value,
                                    });
                                    if (sshError.path_to_wp_content_folder) {
                                      setSshError((prevErrors: any) => ({
                                        ...prevErrors,
                                        path_to_wp_content_folder: null,
                                      }));
                                    }
                                  }}
                                />
                              </div>
                              {sshError.path_to_wp_content_folder && (
                                <div style={{ color: 'red', fontSize: '14px' }}>
                                  {sshError.path_to_wp_content_folder}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              <div className='input-group mb-3'>
                                <span className='input-group-text upload'>
                                  {t('project-details.modals.config-project.ssh.key-file')}
                                </span>
                              </div>
                              <div className='d-flex mb-3'>
                                <input
                                  className='form-control d-none'
                                  type='file'
                                  id='formSshFile'
                                  onChange={handleSshFileChange}
                                />
                                <input
                                  className={
                                    !isSshFileAdded
                                      ? 'form-control'
                                      : isSshFileAdded === 'valid'
                                      ? 'form-control green_border'
                                      : 'form-control red_border'
                                  }
                                  type='text'
                                  value={fileSsh}
                                  readOnly
                                />
                                <button type='button' className='ms-2 file-btn'>
                                  <label htmlFor='formSshFile'>
                                    {t('project-details.modals.config-project.ssh.buttons.browse')}
                                  </label>
                                </button>
                                {sshError.file && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.file}</div>}
                              </div>
                              <div className='input-group mb-3'>
                                <span className='input-group-text'>
                                  {t('project-details.modals.config-project.ssh.path-wordpress-install')}
                                </span>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Enter path'
                                  name='path_to_wp_installation'
                                  value={sshData.path_to_wp_installation}
                                  onChange={e => {
                                    setSshData({
                                      ...sshData,
                                      path_to_wp_installation: e.target.value,
                                    });
                                    if (sshError.path_to_wp_installation) {
                                      setSshError((prevErrors: any) => ({
                                        ...prevErrors,
                                        path_to_wp_installation: null,
                                      }));
                                    }
                                  }}
                                />
                              </div>
                              {sshError.path_to_wp_installation && (
                                <div style={{ color: 'red', fontSize: '14px' }}>{sshError.path_to_wp_installation}</div>
                              )}
                              <div className='input-group mb-3'>
                                <span className='input-group-text'>
                                  {t('project-details.modals.config-project.ssh.wp-content-path')}
                                </span>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Enter path'
                                  name='path_to_wp_content_folder'
                                  value={sshData.path_to_wp_content_folder}
                                  onChange={e => {
                                    setSshData({
                                      ...sshData,
                                      path_to_wp_content_folder: e.target.value,
                                    });
                                    if (sshError.path_to_wp_content_folder) {
                                      setSshError((prevErrors: any) => ({
                                        ...prevErrors,
                                        path_to_wp_content_folder: null,
                                      }));
                                    }
                                  }}
                                />
                              </div>
                              {sshError.path_to_wp_content_folder && (
                                <div style={{ color: 'red', fontSize: '14px' }}>
                                  {sshError.path_to_wp_content_folder}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className='d-flex justify-content-end my-5'>
                          <button className='cblue_btn mx-1'>
                            {t('project-details.modals.config-project.ssh.buttons.test-connection')}
                          </button>
                          <button className='orange_btn mx-1'>
                            {t('project-details.modals.config-project.ssh.buttons.save-project')}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </CommonModal.Body>
            </CommonModal>
            <CommonModal
              className='composer-model private_plugin_modal'
              show={updateNotifyModal}
              onHide={closeUpdateNotifyModal}
              animation={false}
              fullscreen={true}
            >
              <CommonModal.Header>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                  <h4 className='mb-2 secondary-color'>{t('project-details.modals.private-plugin-upload.label')}</h4>
                  <FontAwesomeIcon
                    icon={faClose}
                    onClick={closeUpdateNotifyModal}
                    style={{ color: 'white' }}
                    size='lg'
                  />
                </div>
              </CommonModal.Header>
              <CommonModal.Body style={{ width: '100%', overflowX: 'auto' }}>
                <div className='m-3'>
                  <form className='addproject ' onSubmit={e => handlePrivatePluginUpload(e)}>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('project-details.modals.private-plugin-upload.name')}</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='name'
                        name='name'
                        onChange={e => setPluginData({ ...pluginData, name: e.target.value })}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('project-details.modals.private-plugin-upload.slug')}</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='slug'
                        name='slug'
                        onChange={e => setPluginData({ ...pluginData, slug: e.target.value })}
                      />
                    </div>
                    <div>
                      <span className='input-group-text mb-3 upload'>
                        {t('project-details.modals.private-plugin-upload.upload-plugin')}
                      </span>
                      <div className='d-flex mb-3'>
                        <input
                          className='form-control d-none'
                          type='file'
                          id='formFilePrivate'
                          onChange={e => handleFileChangeOne(e)}
                        />
                        <input
                          className={
                            !isFileAddedOne
                              ? 'form-control'
                              : isFileAddedOne === 'valid'
                              ? 'form-control green_border'
                              : 'form-control red_border'
                          }
                          type='text'
                          value={fileOne}
                          readOnly
                        />
                        <button type='button' className='ms-2 file-btn'>
                          <label htmlFor='formFilePrivate'>
                            {t('project-details.modals.private-plugin-upload.buttons.browse')}
                          </label>
                        </button>
                      </div>
                    </div>

                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('project-details.modals.private-plugin-upload.url')}</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='URL'
                        name='URL'
                        onChange={e => setPluginData({ ...pluginData, URL: e.target.value })}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>
                        {t('project-details.modals.private-plugin-upload.author')}
                      </span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Author'
                        name='wp_author'
                        onChange={e => setPluginData({ ...pluginData, wp_author: e.target.value })}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>
                        {t('project-details.modals.private-plugin-upload.short-description')}
                      </span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Short Description'
                        name='short_description'
                        onChange={e => setPluginData({ ...pluginData, short_description: e.target.value })}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>
                        {t('project-details.modals.private-plugin-upload.description')}
                      </span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Description'
                        name='description'
                        onChange={e => setPluginData({ ...pluginData, description: e.target.value })}
                      />
                    </div>

                    <div className='dependancy mt-5'>
                      <h6 className='text-white w-100'>
                        {t('project-details.modals.private-plugin-upload.package-dependency')}
                      </h6>
                      <p className='text-white w-100'>
                        {t('project-details.modals.private-plugin-upload.plugin-version')}
                      </p>
                      <div className='input-group mb-3'>
                        <span className='input-group-text'>
                          {t('project-details.modals.private-plugin-upload.version')}
                        </span>
                        <input
                          type='text'
                          placeholder='version'
                          value={item.version}
                          className='form-control'
                          onChange={e => setItem({ ...item, version: e.target.value })}
                        />
                      </div>
                      <div className='input-group mb-3'>
                        <span className='input-group-text'>
                          {t('project-details.modals.private-plugin-upload.wp-min')}
                        </span>
                        <input
                          type='text'
                          placeholder='Wp min'
                          value={item.wp_min}
                          className='form-control'
                          onChange={e => setItem({ ...item, wp_min: e.target.value })}
                        />
                      </div>
                      <div className='input-group mb-3'>
                        <span className='input-group-text'>
                          {t('project-details.modals.private-plugin-upload.wp-max')}
                        </span>
                        <input
                          type='text'
                          placeholder='Wp max'
                          value={item.wp_max}
                          className='form-control '
                          onChange={e => setItem({ ...item, wp_max: e.target.value })}
                        />
                      </div>
                      <div className='input-group mb-3'>
                        <span className='input-group-text'>
                          {t('project-details.modals.private-plugin-upload.php-min')}
                        </span>
                        <input
                          type='text'
                          placeholder='Php min'
                          value={item.php_min}
                          className='form-control '
                          onChange={e => setItem({ ...item, php_min: e.target.value })}
                        />
                      </div>
                      <div className='input-group mb-3'>
                        <span className='input-group-text'>
                          {t('project-details.modals.private-plugin-upload.php-max')} :{' '}
                        </span>
                        <input
                          type='text'
                          placeholder='php max'
                          value={item.php_max}
                          className='form-control '
                          onChange={e => setItem({ ...item, php_max: e.target.value })}
                        />
                      </div>
                      <div className='input-group mb-3'>
                        <span className='input-group-text'>
                          {t('project-details.modals.private-plugin-upload.other-dependency')}:{' '}
                        </span>
                        <textarea
                          cols={5}
                          rows={5}
                          value={item.otherdependancy}
                          className='form-control '
                          onChange={e => setItem({ ...item, otherdependancy: e.target.value })}
                        ></textarea>
                      </div>
                    </div>
                    <div className='d-flex justify-content-end my-5'>
                      <button className='cblue_btn mx-1' disabled={privatePlugDisable ? true : false}>
                        {privatePlugDisable
                          ? 'Loading ...'
                          : t('project-details.modals.private-plugin-upload.buttons.add')}
                      </button>
                    </div>
                  </form>
                </div>
              </CommonModal.Body>
              <CommonModal.Footer>
                <button className='orange_btn' onClick={closeUpdateNotifyModal}>
                  Close
                </button>
              </CommonModal.Footer>
            </CommonModal>
          </main>
        </>
      </>
    </ErrorBoundary>
  );
};
export default ProjectDetails;
