/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
// import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Steps } from 'intro.js-react';
// import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import CommonModal from './CommonModal';

const Billing: React.FC = () => {
  const [showModal, setShow] = useState<boolean>(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!showModal);
  const [stepsEnabled, setStepsEnabled] = useState<any>(null);
  const { t } = useTranslation();

  // const location = useLocation();
  const pathname = window.location.pathname;

  let initialSteps = 0;
  let StoreSteps = [
    {
      title: t('intro.bill.first.title'),
      position: 'center',
      intro: t('intro.bill.first.desc'),
    },
    {
      title: t('intro.bill.two.title'),
      element: '.bill-first',
      position: 'left',
      intro: t('intro.bill.two.desc'),
    },
    {
      title: t('intro.bill.three.title'),
      element: '.bill-second',
      position: 'left',
      intro: t('intro.bill.three.desc'),
    },
    {
      title: t('intro.bill.four.title'),
      element: '.bill-third',
      position: 'left',
      intro: t('intro.bill.four.desc'),
    },
  ];

  useEffect(() => {
    if (localStorage.getItem('introjs') === null) {
      const array = [];
      const homeScreen = { screen: 'bill-screen', status: 'true' };
      array.push(homeScreen);
      localStorage.setItem('introjs', JSON.stringify(array!));
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'bill-screen';
      });
      if (index < 0) {
        const homeScreen = { screen: 'bill-screen', status: 'true' };
        array.push(homeScreen);
        setStepsEnabled(homeScreen.status === 'true' ? true : false);
        localStorage.setItem('introjs', JSON.stringify(array!));
      } else {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    }
  }, []);

  const onIntroExit = (e: any): void => {
    if (e === -1) {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'bill-screen';
      });
      if (index >= 0) {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'bill-screen';
      });
      if (index >= 0) {
        array[index].status = 'false';
        setStepsEnabled(array[index].status === 'false' ? false : true);
        localStorage.setItem('introjs', JSON.stringify(array!));
      }
    }
  };

  const onActivateIntro = () => {
    const getLocal = localStorage.getItem('introjs');
    if (getLocal !== null) {
      const getJson = JSON.parse(getLocal);
      const getIndex = getJson.findIndex((i: any) => {
        return i.screen === 'bill-screen';
      });

      if (getIndex > -1) {
        getJson[getIndex].status = 'true';
        setStepsEnabled(true);
        localStorage.setItem('introjs', JSON.stringify(getJson));
      }
    }
  };

  return (
    <>
      {pathname === '/billing' && (
        <Steps
          enabled={stepsEnabled}
          steps={StoreSteps}
          onExit={e => onIntroExit(e)}
          initialStep={initialSteps}
          options={{
            showProgress: true,
          }}
        />
      )}

      <main className='main'>
        <div className='container-fluid'>
          <div className='store_listab'>
            <div className='store_list'>
              <ul className='nav nav-tabs store-first store-first' id='myTab' role='tablist'>
                <li className='nav-item m-0' role='presentation'>
                  <button
                    className='nav-link active'
                    id='allbills-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#allbills'
                    type='button'
                    role='tab'
                    aria-controls='allbills'
                    aria-selected='true'
                    onClick={handleShow}
                  >
                    {t('billing.All-bills')}
                  </button>
                  {/* <CommonModal show={showModal} onHide={handleClose}>
        <CommonModal.Header>
          <h4>Common Modal Header</h4>
         

        </CommonModal.Header>
        <CommonModal.Body>
          <div>Under Development</div>
        </CommonModal.Body>
      </CommonModal> */}

                  {/* <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <div>{'Under Development'}</div>
                </Modal.Body>
              </Modal> */}
                </li>
                <li className='nav-item m-0' role='presentation'>
                  <button
                    className='nav-link'
                    id='associated-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#associated'
                    type='button'
                    role='tab'
                    aria-controls='associated'
                    aria-selected='false'
                    onClick={handleShow}
                  >
                    {t('billing.Associated-project')}
                  </button>
                  {/* <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <div>{'Under Development'}</div>
                </Modal.Body>
              </Modal> */}
                </li>
                <li className='nav-item m-0' role='presentation'>
                  <button
                    className='nav-link'
                    id='recurring-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#recurring'
                    type='button'
                    role='tab'
                    aria-controls='recurring'
                    aria-selected='false'
                    onClick={handleShow}
                  >
                    {t('billing.Recurring-bills')}
                  </button>
                  {/* <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <div>{'Under Development'}</div>
                </Modal.Body>
              </Modal> */}
                </li>
                <li className='nav-item m-0' role='presentation'>
                  <button
                    className='nav-link'
                    id='liceanses-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#liceanses'
                    type='button'
                    role='tab'
                    aria-controls='liceanses'
                    aria-selected='false'
                    onClick={handleShow}
                  >
                    {t('billing.Licenses')}
                  </button>
                  {/* <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <div>{'Under Development'}</div>
                </Modal.Body>
              </Modal> */}
                </li>
              </ul>
            </div>

            <div className='tab-content'>
              <div className='tab-pane active' id='allbills' role='tabpanel' aria-labelledby='allbills-tab'>
                <div className='projectlist'>
                  <div className='row align-items-center '>
                    <div className='col'>
                      <div className='page_title mt-4 mb-4'>
                        <h1 className='m-0 text-white font20'> {t('billing.All-bills')}</h1>
                      </div>
                    </div>
                    <div className='col-auto '>
                      <div className='search'>
                        <div className='search_main'>
                          <input type='text' placeholder='Search here' />
                          <svg
                            aria-hidden='true'
                            focusable='false'
                            data-prefix='fas'
                            data-icon='magnifying-glass'
                            className='svg-inline--fa fa-magnifying-glass '
                            role='img'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                          >
                            <path
                              fill='#6DB1BC'
                              d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z'
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className='col-auto '>
                      <div className='text-end d-flex justify-content-end'>
                        <div className='text-end d-flex justify-content-end bill-third'>
                          <button className='cblue_btn d-flex align-items-center me-2'>
                            <span className='d-inline-block ms-1'> {t('billing.Filters')}</span>
                          </button>
                          <button className='orange_btn d-flex align-items-center me-2'>
                            <span className='d-inline-block ms-1'> {t('billing.Send-bill')}</span>
                          </button>
                        </div>
                        <button type='button' className='roundedButton' onClick={() => onActivateIntro()}>
                          <FontAwesomeIcon icon={faQuestion} />
                        </button>

                        {/* <button type='button' className='roundedButton d-flex align-items-center me-2' style={{position:'absolute', top:'10px', right:'140px'}} onClick={() => onActivateIntro()}>
                    <FontAwesomeIcon icon={faQuestion}/>
                  </button> */}
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive bill-second'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th scope='col' className='font-weight-normal'>
                            Type
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Name
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Payment type
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Vendor
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Sent date
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Amount
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Due date
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Status
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Download invoice
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Plugin</td>
                          <td>WooCommerce</td>
                          <td>
                            <select
                              className='environment-select form-select w-auto bg-none'
                              aria-label='Default select example'
                            >
                              <option>Monthly</option>
                              <option>Free</option>
                              <option>Yearly</option>
                            </select>
                          </td>
                          <td>By Maurteen Biologist</td>
                          <td>20-05-2022</td>
                          <td>$99</td>
                          <td>20-11-2022</td>
                          <td>Paid</td>
                          <td>
                            <button className='cblue_btn d-flex align-items-center'>Download</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='tab-pane' id='associated' role='tabpanel' aria-labelledby='associated-tab'>
                <div className='projectlist'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      <div className='page_title mt-4 mb-4'>
                        <h1 className='m-0 text-white font20'> {t('billing.Associated-project')}</h1>
                      </div>
                    </div>
                    <div className='col-auto'>
                      <select
                        className='environment-select form-select w-auto bg-none'
                        aria-label='Default select example'
                      >
                        <option>Select user</option>
                      </select>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th scope='col' className='font-weight-normal'>
                            Payment type
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Projects
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Invoice date
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Amount
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Next payment date
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Send invoice
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <select
                              className='environment-select form-select w-auto bg-none'
                              aria-label='Default select example'
                            >
                              <option>Monthly</option>
                              <option>Free</option>
                              <option>Yearly</option>
                            </select>
                          </td>
                          <td>
                            <select
                              className='environment-select form-select w-auto bg-none'
                              aria-label='Default select example'
                            >
                              <option>Project1</option>
                            </select>
                          </td>
                          <td>20-05-2022</td>
                          <td>$99</td>
                          <td>20-11-2022</td>
                          <td>
                            <button className='cblue_btn d-flex align-items-center'>Send invoice</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='tab-pane' id='recurring' role='tabpanel' aria-labelledby='recurring-tab'>
                <div className='projectlist'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      <div className='page_title mt-4 mb-4'>
                        <h1 className='m-0 text-white font20'> {t('billing.Recurring-bills')}</h1>
                      </div>
                    </div>
                    <div className='col-auto'>
                      <div className='search'>
                        <div className='search_main'>
                          <input type='text' placeholder='Search here' />
                          <svg
                            aria-hidden='true'
                            focusable='false'
                            data-prefix='fas'
                            data-icon='magnifying-glass'
                            className='svg-inline--fa fa-magnifying-glass '
                            role='img'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                          >
                            <path
                              fill='#6DB1BC'
                              d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z'
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className='col-auto'>
                      <div className='text-end d-flex justify-content-end'>
                        <button className='cblue_btn d-flex align-items-center me-2'>
                          <span className='d-inline-block ms-1'> {t('billing.Filters')}</span>
                        </button>
                        <button className='orange_btn d-flex align-items-center me-2'>
                          <span className='d-inline-block ms-1'>{t('billing.Send-bill')}</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th scope='col' className='font-weight-normal'>
                            Type
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Name
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Payment type
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Vendor
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Sent date
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Amount
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Due date
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Status
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Download invoice
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Plugin</td>
                          <td>WooCommerce</td>
                          <td>
                            <select
                              className='environment-select form-select w-auto bg-none'
                              aria-label='Default select example'
                            >
                              <option>Monthly</option>
                              <option>Free</option>
                              <option>Yearly</option>
                            </select>
                          </td>
                          <td>By Maurteen Biologist</td>
                          <td>20-05-2022</td>
                          <td>$99</td>
                          <td>20-11-2022</td>
                          <td>Paid</td>
                          <td>
                            <button className='cblue_btn d-flex align-items-center'>Download</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='tab-pane' id='liceanses' role='tabpanel' aria-labelledby='liceanses-tab'>
                <div className='projectlist'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      <div className='page_title mt-4 mb-4'>
                        <h1 className='tab-pane' aria-labelledby='team-tab'>
                          {t('billing.Licenses')}
                        </h1>
                      </div>
                    </div>
                    <div className='col-auto'>
                      <div className='search'>
                        <div className='search_main'>
                          <input type='text' placeholder='Search here' />
                          <svg
                            aria-hidden='true'
                            focusable='false'
                            data-prefix='fas'
                            data-icon='magnifying-glass'
                            className='svg-inline--fa fa-magnifying-glass '
                            role='img'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                          >
                            <path
                              fill='#6DB1BC'
                              d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z'
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className='col-auto'>
                      <div className='text-end d-flex justify-content-end'>
                        <button className='cblue_btn d-flex align-items-center me-2'>
                          <span className='d-inline-block ms-1'>{t('billing.Filters')}</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th scope='col' className='font-weight-normal'>
                            Name
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Version
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Vendor
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Liceance type
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Active date
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Due date
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            Status
                          </th>
                          <th scope='col' className='font-weight-normal'>
                            View details
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>WooCommerce</td>
                          <td>
                            <select
                              className='environment-select form-select w-auto bg-none'
                              aria-label='Default select example'
                            >
                              <option>v 5.5.8</option>
                              <option>v 5.5.1</option>
                              <option>v 6.0</option>
                            </select>
                          </td>
                          <td>By Maurteen Biologist</td>
                          <td>
                            <select
                              className='environment-select form-select w-auto bg-none'
                              aria-label='Default select example'
                            >
                              <option>Monthly</option>
                              <option>Quartery</option>
                              <option>Yearly</option>
                            </select>
                          </td>
                          <td>20-05-2022</td>
                          <td>20-11-2022</td>
                          <td>Active</td>
                          <td>
                            <button className='cblue_btn d-flex align-items-center'>View details</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Billing;
