import React, { useEffect, useState } from 'react';
import { useUpdateOtpPreferenceMutation, useUserDetailsQuery } from '../features/api';
import { toast } from 'react-toastify';
import Loader from './Loader';

const UserProfile: React.FC = () => {
  const [userDetail, setUserDetail] = useState<any>({});
  const [updateOtpPreference] = useUpdateOtpPreferenceMutation();
  const { data: userdetails, isLoading: accountLoad } = useUserDetailsQuery({});

  useEffect(() => {
    if (typeof userdetails !== 'undefined') {
      setUserDetail(userdetails);
    }
  }, [userdetails]);

  const preferOtp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserDetail((prevUserDetail: any) => ({
      ...prevUserDetail,
      otp_preference: event.target.checked,
    }));

    const data = {
      flag: 'otp_preference',
      otp_preference: event.target.checked === false ? '0' : '1',
    };

    updateOtpPreference(data).then((res: any) => {
      // console.log('res',res)
      if (Object.prototype.hasOwnProperty.call(res, 'success')) {
        toast.success(res.data.message);
      } else {
        toast.error(JSON.stringify(res.data.message));
      }
    });
  };

  if (accountLoad) {
    return <Loader />;
  }

  return (
    <main className='main'>
      <div className='container-fluid'>
        <form>
          {/* <h1 className='text-white'>hello world</h1> */}
          {Object.keys(userDetail).length > 0 ? (
            <div>
              <span className='text-white'>Otp Preferences</span>
              <label className='switch'>
                <input
                  type='checkbox'
                  id='select_login_screen'
                  name='select_login_screen'
                  checked={userDetail.otp_preference}
                  onChange={e => preferOtp(e)}
                />
                <span className='slider round'></span>
              </label>
            </div>
          ) : null}
        </form>
      </div>
    </main>
  );
};

export default UserProfile;
