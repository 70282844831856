import { useNavigate } from 'react-router-dom';

/**
 * Updates the query string in the URL.
 * @param {string} key - The query parameter key.
 * @param {string} value - The value to set for the query parameter.
 */
export const useUpdateQueryString = () => {
  const navigate = useNavigate();

  const updateQueryString = (key: any, value: any) => {
    const queryParams = new URLSearchParams(window.location.search);

    // Set the query parameter
    queryParams.set(key, value);

    // Update the URL with the new query string
    navigate({
      search: queryParams.toString(),
    });
  };

  return updateQueryString;
};

export const useDeleteQueryString = () => {
  const navigate = useNavigate();

  const deleteQueryString = (key: any) => {
    const queryParams = new URLSearchParams(window.location.search);

    // Set the query parameter
    queryParams.delete(key);

    // Update the URL with the new query string
    navigate({
      search: queryParams.toString(),
    });
  };

  return deleteQueryString;
};

export const useManageQueryString = () => {
  const deleteQueryString = useDeleteQueryString();
  const updateQueryString = useUpdateQueryString();
  const manageQueryString = (status: any) => {
    if (status === 'true') {
      updateQueryString('intro', 'true');
    } else {
      deleteQueryString('intro');
    }
  };
  return manageQueryString;
};
