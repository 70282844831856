import React, { useState } from 'react';
import { getUserWisePluginInterface } from '../Interface/appInterface';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';
import img from '../img/store-thumbnail.svg';
import { createSearchParams, useNavigate } from 'react-router-dom';

const UserPackagesCard: React.FC<getUserWisePluginInterface> = (props: getUserWisePluginInterface) => {
  const { item: plugin } = props;
  const [src, setSrc] = useState(img);
  const navigate = useNavigate();

  const handleImageError = () => {
    setSrc(img);
  };

  const isFavorite = false; // Replace with your actual favorite logic
  const isPremium = plugin.isPremium ? 'Premium' : 'Free'; // Example condition for premium status
  const isSelectedActive = false; // Replace with your actual selection logic

  const onAddPlugin = (plugin: any, event: React.MouseEvent) => {
    // Logic to add the plugin
  };
  const navigate1 = () => {
    navigate(`/store/${plugin.slug}`);
  };

  return (
    <div className='col-xxl-4 col-xl-4 col-md-6 col-sm-12 mt-2' onClick={() => navigate1()}>
      <span className='plug_grid' style={{ cursor: 'pointer' }}>
        <div className='plugins_info'>
          <div className='plugins_item_header'>
            <div className='plugin_icon'>
              <img
                className='img-fluid'
                src={src} // Add a fallback image
                onError={handleImageError}
                alt='plugin_icon'
              />
            </div>
            <div className='plugins_title_author'>
              <div className='plugins_item_title' title={plugin.name ? plugin.name : plugin.slug}>
                <h6>{plugin.name ? plugin.name.substr(0, 20) + '...' : plugin.slug}</h6>
              </div>
              <div className='plugins_item_author'>
                <p>
                  by <span className='plugins_item_author_name'>{plugin.wp_author}</span>
                </p>
              </div>
              {/* <div className={`product_like ${isFavorite ? 'favorite' : ''}`} style={{ width: '35px', height: '35px' }} onClick={(e) => {
                e.stopPropagation();
                // Logic to add to wishlist
              }}>
                <FontAwesomeIcon icon={faHeart} style={{ color: 'white' }} size='lg' />
              </div> */}
              <div className='plugins_item_ratings'>
                {/* Implement your rating rendering logic here */}
                {plugin.rating ? <p>{plugin.rating}</p> : null}
              </div>
            </div>
          </div>
          <div className='plugins_item_description'>
            <p>{plugin.short_description}</p>
          </div>
          <div style={{ height: '30px' }}>
            {plugin.last_updated && (
              <span className='text-white'>
                Last Updated:{' '}
                <span style={{ color: '#f08921' }}>&nbsp;{moment(plugin.last_updated).format('DD-MM-YYYY')}</span>
              </span>
            )}
          </div>
        </div>
        <div className='plugins_footer'>
          <div className='plugins_item_pricing'>
            <p>Private</p>
          </div>
          <div className='plugins_item_additional_info d-flex align-items-center'>
            {/* {true && (
      <div
        className='plugin_select_btn me-2'
        onClick={e => {
          e.stopPropagation();
          onAddPlugin(plugin, e);
        }}
      >
        <FontAwesomeIcon
          icon={isSelectedActive ? faCircleMinus : faCirclePlus}
          size='2xl'
          color={isSelectedActive ? '#fd2525' : '#f08921'}
        />
      </div>
    )} */}
            <div
              className='plugin_select_btn me-2'
              onClick={e => {
                e.stopPropagation();
                const params = {
                  slug: plugin.slug,
                  flag: 'update',
                };
                // handleRemove(plugin.id);
                navigate({
                  pathname: '/add-user-package',
                  search: `?${createSearchParams(params)}`,
                });
              }}
            >
              <FontAwesomeIcon icon={faFileCirclePlus} size='xl' color='#fd2525' />
            </div>
            <div
              className='plugin_select_btn'
              onClick={e => {
                e.stopPropagation();
                // handleRemove(plugin.id);
              }}
            >
              <FontAwesomeIcon icon={faTrash} size='xl' color='#fd2525' />
            </div>
          </div>
        </div>
      </span>
    </div>
  );
};

export default UserPackagesCard;
