/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import RejectedUserPackageCard from './RejectedUserPackageCard';
import { useGetUserWisePluginQuery } from '../features/api';
import Loader from './Loader';

const RejectedUserPackage: React.FC = () => {
  const [userPlugin, setUserPlugin] = useState<Array<any>>([]);

  const { data: userData, isLoading } = useGetUserWisePluginQuery({});

  useEffect(() => {
    if (typeof userData !== 'undefined') {
      setUserPlugin(userData);
    }
  }, [userData]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <main className='main main-side-sort overflow-hidden'>
      {/* <div className="col-md-2 mx-4">
        <button className='orange_btn' type='button'>Submit new plugin</button>
      </div> */}
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>Item name</th>
              <th>Submit date</th>
              <th>Version</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userPlugin.map((i: any) => {
              if (i.approval_status === 'reject') {
                return <RejectedUserPackageCard item={i} />;
              }
            })}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default RejectedUserPackage;
