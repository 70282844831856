// Notification.tsx
import React, { useState, useEffect } from 'react';

interface NotificationProps {
  message: string;
  duration: number;
}

const Notification: React.FC<NotificationProps> = ({ message, duration }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      localStorage.setItem('welcome_flag', 'false');
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  if (!visible) return null;

  return <div className='notification'>{message}</div>;
};

export default Notification;
