import { useAppSelector } from '../features/hooks';


interface authenticatedInterface {
  token: string;
  otp: "true" | 'false';
  isData:boolean
}

export default function useAuthenticated():authenticatedInterface {
  const data = useAppSelector(state => state.auth);
  if(data.token.length === 0){
    return {
      ...data,
      isData:false
    }
  } else {
    return {...data,isData:true}
  }
}
