// import React from 'react';
// import { Link } from 'react-router-dom';

// export default function ErrorPage() {
//   return (
//     <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center bg-black position-relative overflow-hidden">
//       {/* Animated Particle Background */}
//       <div className="particle-background position-absolute w-100 h-100"></div>

//       <div className="text-center z-index-1 position-relative">
//         {/* Enhanced Heading with Larger Font Size */}
//         <h1 className="fw-bold mb-4 glowing-text" style={{ fontSize: '10rem' }}>
//           Oops!
//         </h1>

//         {/* Subtitle */}
//         <h2 className="fs-1 mb-3" style={{ color: '#6db1bc' }}>SOMETHING WENT WRONG!</h2>

//         {/* Error Description */}
//         <p className="lead mb-4 text-light">
//           The page you are looking for might have been removed,
//           <br />
//           had its name changed or is temporarily unavailable.
//         </p>

//         {/* Call-to-Action Button */}
//         <Link to="/" className="orange_btn btn-lg px-4 py-2 shadow-lg" style={{textDecoration:'none'}}>
//           GO TO HOMEPAGE
//         </Link>
//       </div>

//       <style>{`
//         /* Particle Background */
//         .particle-background {
//           background: radial-gradient(circle at 50% 50%, #1a1a1a, #101010, #0a0a0a);
//           animation: particleAnimation 15s ease infinite;
//           z-index: 0;
//         }

//         @keyframes particleAnimation {
//           0% { background-position: 0% 0%; }
//           50% { background-position: 100% 100%; }
//           100% { background-position: 0% 0%; }
//         }

//         /* Glowing Text Effect */
//         .glowing-text {
//           color: #fff;
//           text-shadow: 0 0 10px rgba(255, 111, 97, 0.8), 0 0 20px rgba(255, 111, 97, 0.6), 0 0 30px rgba(255, 111, 97, 0.4);
//           animation: glowAnimation 2s ease-in-out infinite alternate;
//           z-index: 1;
//         }

//         @keyframes glowAnimation {
//           from { text-shadow: 0 0 10px rgba(233, 108, 12, 0.6), 0 0 20px rgba(233, 108, 12, 0.6), 0 0 30px rgba(255, 111, 97, 0.4); }
//           to { text-shadow: 0 0 20px rgba(233, 108, 12, 0.6), 0 0 40px rgba(240, 137, 33, 0.7), 0 0 50px rgba(240, 137, 33, 0.5); }
//         }

//         /* Button Pulse Effect */
//         .hover-pulse {
//           transition: all 0.3s ease;
//           z-index: 1;
//         }

//         .hover-pulse:hover {
//           background-color: #ff6f61;
//           box-shadow: 0 0 20px rgba(233, 108, 12, 0.6);
//           transform: scale(1.05);
//         }

//         /* Subtitle and Error Description */
//         h2, p {
//           color: rgba(255, 255, 255, 0.8);
//           z-index: 1;
//         }
//       `}</style>
//     </div>
//   );
// }

import React from 'react';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
  return (
    <div className='main'>
      <div className='error_block'>
        <div className='error_block_inner'>
          <h2>Oops!</h2>
          <p>This page seems to have taken a little detour. Don’t worry! You can navigate back to safety!</p>
          <div className='text-center mt-5'>
            <button type='button' className='orange_btn'>
              Take Me Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
