import React from 'react';

interface CommandLineInterface {
  commandSSH:any
}

function CommandLine({commandSSH}:CommandLineInterface) {
  return (
    <>

        <div className='terminal' id='terminal'>
          <div className='terminal-header'>
            <span className='close' id='close-terminal-button'>
              &#10005;
            </span>
          </div>
          <div className='terminal-content'>
            {commandSSH.map((ik:any,index:number) => {
              return (
            <div>
            <div className='line'>
              <span className='prompt'>user@fusepress:~$</span>
              <span className='command text-white'> {ik.command}</span>
              {index === commandSSH.length -1 &&
                <span className='cursor'></span>
              }

              {/* <div></div> */}
            </div>
            <br/>
            <div className='line text-white'>
            {ik.log}
            </div>
            </div>
              )
            })}
            {/* <div className='line'>
              <span className='prompt'>user@fusepress:~$</span>
              <span className='command text-white'> echo "Hello, Terminal!"</span>
              <span className='cursor'></span>
            </div> */}
          </div>
        </div>

    </>
  );
}

export default CommandLine;
