/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  items: [],
  data: null,
  status: null,
  isLoading: false,
  isError: false,
  errorMessage: null,
};

export const getSSHDetails: any = createAsyncThunk('projects/getSSHDetails', async (_, { rejectWithValue }) => {
  // You can use useProjectQuery here to fetch data
  // console.log("useProjectQuery",useProjectQuery({'with-plugins':true}));
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/getSSHDetail`,
      method: 'POST',
      data: {},
      headers: {
        // Accept: "multipart/form-data",
        // 'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

const productSlice = createSlice({
  name: 'getSSHDetails',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getSSHDetails.pending]: (state: any, action: any) => {
      state.status = 'pending';
      state.isLoading = true;
      state.isError = false; // Reset error state when starting a new request
      state.errorMessage = null; // Clear previous error message
    },
    [getSSHDetails.fulfilled]: (state: any, action: any) => {
      state.status = 'success';
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    [getSSHDetails.rejected]: (state: any, action: any) => {
      state.status = 'rejected';
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
  },
});

export default productSlice.reducer;
