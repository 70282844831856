/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
// import { Modal } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import TicketDetails from './TicketDetails';
import TicketList from './TicketList';
import { useTranslation } from 'react-i18next';
import { Steps } from 'intro.js-react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faSmile } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../features/hooks';
import { useDispatch } from 'react-redux';
import { fetchUserDetails } from '../features/userDetailsSlice';
import Loader from './Loader';
import axios from 'axios';
import { fetchSupportCustomer } from '../features/getSupportCustomer';
import { fetchSupportStatus } from '../features/getSupportStatus';
import { fetchSupportCategory } from '../features/getSupportCategory';
import { fetchSupportTickets } from '../features/getSupportTickets';
import { toast } from 'react-toastify';

const options = [
  { label: 'Select Subject 01', value: 'sub1' },
  { label: 'Select Subject 02', value: 'sub2' },
  { label: 'Select Subject 03', value: 'sub3' },
];

const Suports: React.FC = () => {
  const [ticketId, setTicketId] = useState('');
  const [showModal, setShow] = useState<boolean>(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!showModal);
  const [selected, setSelected] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);

  const [stepsEnabled, setStepsEnabled] = useState<any>(null);
  const [filename, setFilename] = useState<string>('');
  const [file, setFile] = useState<any>(null);
  const [listFile, setListFile] = useState<Array<any>>([]);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const pathname = window.location.pathname;
  const dispatch = useDispatch();
  const [ticketData, setTicketData] = useState<Array<any>>([]);
  const [wordpressLoginUrl, setWordpressLoginUrl] = useState<any>({});
  const [wordpressLinkLoader, setWordpressLinkLoader] = useState<boolean>(true);
  const token = localStorage.getItem('token') || '';
  // const selector =useSelector();

  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    subject: '',
    description: '',
    description_attachments: '',
  });

  useEffect(() => {
    dispatch(fetchUserDetails());
    // dispatch(fetchSupportCategory());
    // dispatch(fetchSupportCustomer());
    // dispatch(fetchSupportStatus());
  }, [dispatch]);

  const { data: userdetails } = useAppSelector((state: any) => state.userDetails);


  useEffect(() => {
    if (userdetails !== null) {
      const fetchLoginUrl = async () => {
        try {
          const url = (await loginToWordPress()) || '';
          localStorage.setItem('wordpressloginurl', url);
          setWordpressLoginUrl(url);
        } catch (error) {
          setWordpressLoginUrl({});
          console.error('Error fetching login URL:', error);
        }
      };

      fetchLoginUrl();
    }
  }, [userdetails]);

  const loginToWordPress = async (): Promise<string | null> => {
    try {
      const formData = new FormData();
      formData.append('Email', userdetails.email);
      formData.append('token', token);
      // formData.append("Password",atob(data.password))
      const response = await axios.post(
        `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/signin-user/myaccount`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const redirectUrl = response.data;
      setWordpressLinkLoader(false);
      if (Object.prototype.hasOwnProperty.call(redirectUrl, 'success')) {
        if (redirectUrl.success === false) {
          const email = userdetails.email;
          const atIndex = email.indexOf('@');
          if (atIndex !== -1) {
            const emailBeforeAt = email.substring(0, atIndex);
            axios({
              url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp/user-sync.php`,
              method: 'POST',
              headers: {},
              params: {
                action: 'get_user_data',
                fname: userdetails.displayName,
                lname: userdetails.displayName,
                username: emailBeforeAt,
                email: userdetails.email,
                role: 'customer',
              },
              data: {},
            })
              .then(res1 => {
                console.log('res1', res1);
                setWordpressLinkLoader(false);
              })
              .catch((err1: any) => {
                console.log('err1', err1);
              });
          }
          setWordpressLinkLoader(false);
          return redirectUrl;
        } else {
          setWordpressLinkLoader(false);
          return redirectUrl;
        }
      } else {
        return redirectUrl;
      }
    } catch (error) {
      console.error('Error during login:', error);
      return null;
    }
  };

  useEffect(() => {
    if(Object.keys(wordpressLoginUrl).length > 0){
      dispatch(fetchSupportCategory(wordpressLoginUrl));
      dispatch(fetchSupportCustomer(wordpressLoginUrl));
      dispatch(fetchSupportStatus(wordpressLoginUrl));
    }
  },[wordpressLoginUrl])

  
  const { data: supportStatus } = useAppSelector((state: any) => state.supportStatus);
  const { data: supportCategory } = useAppSelector((state: any) => state.supportCategory);
  const { data: supportCustomers } = useAppSelector((state: any) => state.supportCustomers);
  const { data: supportTickets } = useAppSelector((state: any) => state.supportTickets);

  useEffect(() => {
    if (userdetails !== null && Object.keys(wordpressLoginUrl).length > 0) {
      dispatch(fetchSupportTickets({...wordpressLoginUrl,email:userdetails.email}));
    }
  }, [dispatch, userdetails,wordpressLoginUrl]);


  useEffect(() => {
    if (userdetails !== null) {
      setUserDetails({
        name: userdetails.name,
        email: userdetails.email,
        subject: '',
        description: '',
        description_attachments: '',
      });
    }
  }, [userdetails]);

  useEffect(() => {
    if (
      supportStatus !== null &&
      supportCategory !== null &&
      supportTickets != null &&
      userdetails !== null
    ) {
      const getTecketDetails = supportTickets.results.map((ik: any) => {
        return {
          ...ik,
          email: userdetails.email,
          status: supportStatus.find((ikk: any) => {
            return ikk.id === ik.status;
          }),
          category: supportCategory.find((ikkk: any) => {
            return ikkk.id === ik.category;
          }),
        };
      });
      setTicketData(getTecketDetails);
    }
  }, [supportStatus, supportCategory, supportTickets, userdetails]);

  let initialSteps = 0;
  let StoreSteps = [
    {
      title: t('intro.support.first.title'),
      position: 'center',
      intro: t('intro.support.first.desc'),
    },
    {
      title: t('intro.support.two.title'),
      element: '.support-first',
      position: 'left',
      intro: t('intro.support.two.desc'),
    },
    {
      title: t('intro.support.three.title'),
      element: '.support-second',
      position: 'left',
      intro: t('intro.support.three.desc'),
    },
  ];

  useEffect(() => {
    if (localStorage.getItem('introjs') === null) {
      const array = [];
      const homeScreen = { screen: 'support-screen', status: 'true' };
      array.push(homeScreen);
      localStorage.setItem('introjs', JSON.stringify(array!));
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'support-screen';
      });
      if (index < 0) {
        const homeScreen = { screen: 'support-screen', status: 'true' };
        array.push(homeScreen);
        setStepsEnabled(homeScreen.status === 'true' ? true : false);
        localStorage.setItem('introjs', JSON.stringify(array!));
      } else {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    }
  }, []);

  const onIntroExit = (e: any): void => {
    if (e === -1) {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'support-screen';
      });
      if (index >= 0) {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'support-screen';
      });
      if (index >= 0) {
        array[index].status = 'false';
        setStepsEnabled(array[index].status === 'false' ? false : true);
        localStorage.setItem('introjs', JSON.stringify(array!));
      }
    }
  };

  const onActivateIntro = () => {
    const getLocal = localStorage.getItem('introjs');
    if (getLocal !== null) {
      const getJson = JSON.parse(getLocal);
      const getIndex = getJson.findIndex((i: any) => {
        return i.screen === 'support-screen';
      });

      if (getIndex > -1) {
        getJson[getIndex].status = 'true';
        setStepsEnabled(true);
        localStorage.setItem('introjs', JSON.stringify(getJson));
      }
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (event.target.files !== null) {
      if (event.target.files?.length > 0) {
        setFilename((event.target as any).files[0].name);
        setFile((event.target as any).files[0]);
        setFileUpload(true);
        // console.log(localStorage.getItem("dynamo"))
        const formData = new FormData();
        formData.append('file', (event.target as any).files[0]);
        const a: any = localStorage.getItem('dynamo') !== null ? JSON.parse(localStorage.getItem('dynamo')!) : [];
        try {
          const ab = await axios({
            url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/supportcandy/v2/attachments`,
            method: 'Post',
            data: formData,
            auth: {
              username: wordpressLoginUrl.data.user_name,
              password: wordpressLoginUrl.data.app_name,
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          if (ab) {
            a.push(ab.data);
            localStorage.setItem('dynamo', JSON.stringify(a));
            setFileUpload(false);
            setListFile(a);
          }
        } catch (err) {
          toast.error(`Something went wrong!!!`)
          setFileUpload(false);
          console.log(err);
        }
      }
    }
  };

  const SubmitTicket = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (localStorage.getItem('dynamo') !== null) {
      const files = JSON.parse(localStorage.getItem('dynamo')!).map((ik: any) => {
        return ik.id;
      });

      const result = files.reduce((acc: any, current: any, index: number) => {
        acc += current;
        if (index !== files.length - 1) {
          acc += ',';
        }

        return acc;
      }, '');
      const ab = {
        name: userDetails.name,
        email: userDetails.email,
        subject: userDetails.subject,
        description: userDetails.description,
        description_attachments: result,
      };
      try {
        const abc = await axios({
          url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/supportcandy/v2/tickets`,
          method: 'Post',
          data: ab,
          auth: {
            username: wordpressLoginUrl.data.user_name,
            password: wordpressLoginUrl.data.app_name,
          },
        });
        if (abc) {
          // console.log("abc",abc.data)
          localStorage.removeItem('dynamo');
        }
      } catch (err) {
        console.log(err);
      }
      // console.log(ab,"abbbbbbbbbb")
    }
  };

  if (userdetails === null) {
    return <Loader />;
  }

  return (
    <>
      {pathname === '/support' && (
        <Steps
          enabled={stepsEnabled}
          steps={StoreSteps}
          onExit={e => onIntroExit(e)}
          initialStep={initialSteps}
          options={{
            showProgress: true,
          }}
        />
      )}
      <main className='main'>
        <div className='container-fluid'>
          <button
            type='button'
            className='roundedButton d-flex align-items-center me-2 btn_right'
            onClick={() => onActivateIntro()}
          >
            <FontAwesomeIcon icon={faQuestion} />
          </button>
          <div className='store_listab'>
            <ul className='nav nav-tabs store-first store-first ' id='myTab' role='tablist'>
              <li className='nav-item m-0' role='presentation'>
                <button
                  className='nav-link active '
                  id='lists-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#lists'
                  type='button'
                  role='tab'
                  aria-controls='lists'
                  aria-selected='true'
                  onClick={handleShow}
                >
                  {t('support.Generate-ticket')}
                </button>
              </li>
              <li className='nav-item m-0' role='presentation'>
                <button
                  className='nav-link'
                  id='team-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#team'
                  type='button'
                  role='tab'
                  aria-controls='team'
                  aria-selected='false'
                  onClick={handleShow}
                >
                  {t('support.Overview')}
                </button>
              </li>
            </ul>
            <div className='tab-content'>
              <div className='tab-pane active' id='lists' role='tabpanel' aria-labelledby='lists-tab'>
                <div className='py-3'>
                  <form className='addproject' onSubmit={e => SubmitTicket(e)}>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('support.Name')}</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter name'
                        name='name'
                        value={userDetails.name}
                        onChange={e => setUserDetails({ ...userDetails, name: e.target.value })}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('support.Email')}</span>
                      <input
                        type='email'
                        className='form-control'
                        placeholder='Enter email'
                        name='email'
                        value={userDetails.email}
                        onChange={e => setUserDetails({ ...userDetails, email: e.target.value })}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('support.Subject')}</span>
                      <textarea
                        className='form-control'
                        placeholder='write description here'
                        name='issue'
                        value={userDetails.subject}
                        onChange={e => setUserDetails({ ...userDetails, subject: e.target.value })}
                      />
                    </div>
                    {/* <div className='input-group mb-3'>
                    <span className='input-group-text'>Project</span>
                    <select className='form-select' id='projectselect' name='project'>
                      <option value='0'>Select project</option>
                      <option value='1'>Project 1</option>
                      <option value='2'>Project 2</option>
                      <option value='3'>Project 3</option>
                    </select>
                  </div> */}
                    {/* <div className='input-group mb-3'>
                    <span className='input-group-text'>Type</span>
                    <select className='form-select' id='typeselect' name='type'>
                      <option value='0'>Select type</option>
                      <option value='plugin'>Plugin</option>
                      <option value='theme'>Theme</option>
                    </select>
                  </div> */}
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('support.issue-description')}</span>
                      <textarea
                        className='form-control'
                        placeholder='write description here'
                        name='issue'
                        rows={15}
                        value={userDetails.description}
                        onChange={e => setUserDetails({ ...userDetails, description: e.target.value })}
                      />
                    </div>
                    <div className='d-flex mb-3'>
                      <input
                        className='form-control d-none'
                        type='file'
                        id='formFile'
                        onChange={e => handleFileChange(e)}
                      />
                      <input className='form-control' type='text' value={filename} />
                      {fileUpload !== true && (
                        <label htmlFor='formFile' className='ms-2 cblue_btn'>
                          {t('support.Browse')}
                        </label>
                      )}
                    </div>
                    <div className='text-white'>
                      {listFile.map(ik => {
                        return (
                          <>
                            <span className='text-white'>{ik.name}</span>
                            <br />
                          </>
                        );
                      })}
                    </div>
                    <div className='d-flex justify-content-end my-5 '>
                      <button type='submit' className='orange_btn mx-1 support-second'>
                        {t('support.Generate-ticket')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className='tab-pane' id='team' role='tabpanel' aria-labelledby='team-tab'>
                {ticketData.length ? <TicketList ticketData={ticketData} wordpressLoginUrl={wordpressLoginUrl} /> : <Loader />}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Suports;
