// Not a "real" hook, as the browser cannot change while react is running
export default function useBrowserInfo() {
  const userAgent = navigator.userAgent;
  let browserName;
  let browserVersion;

  if (userAgent.indexOf('Firefox') > -1) {
    browserName = 'Mozilla Firefox';
    browserVersion = userAgent.split('Firefox/')[1];
  } else if (userAgent.indexOf('Chrome') > -1) {
    browserName = 'Google Chrome';
    browserVersion = userAgent.split('Chrome/')[1].split(' ')[0];
  } else if (userAgent.indexOf('Safari') > -1) {
    browserName = 'Apple Safari';
    browserVersion = userAgent.split('Version/')[1].split(' ')[0];
  } else if (userAgent.indexOf('MSIE') > -1 || !!document.DOCUMENT_NODE) {
    browserName = 'Internet Explorer';
    browserVersion = userAgent.split('MSIE ')[1].split(';')[0];
  } else if (userAgent.indexOf('Edge') > -1) {
    browserName = 'Microsoft Edge';
    browserVersion = userAgent.split('Edge/')[1];
  } else {
    browserName = 'Unknown Browser';
    browserVersion = 'N/A';
  }

  return {
    browserName,
    browserVersion,
  };
}
