/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IitemList, IProject, Iitem } from '../Interface/appInterface';

import favorite_unselect from '../img/favorite.svg';
import favorite_select from '../img/favorite_select.svg';
import img from '../img/store-thumbnail.svg';

import { Rating } from 'react-simple-star-rating';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus, faHeart } from '@fortawesome/free-solid-svg-icons';
import { setPluginNumber, setWishlistNumber } from '../features/notifyNumbers';
import { useAppSelector } from '../features/hooks';

const StorePlugin: React.FC<IitemList> = ({
  plugin,
  active,
  view,
  setSelectPluginData,
  selectPluginData,
  isSelectedActive,
  disablePrivatepack,
}: IitemList) => {
  let existinProject: number[] = [];
  let projects = useSelector((state: any) => state.queue.value.all);
  projects = Object.values(projects);
  projects.map((project: IProject) => {
    if (project.plugins !== null) {
      if (project.plugins.some((el: Iitem) => el.id === plugin.id)) {
        existinProject.push(project.id);
      }
    }
  });
  const [wishlist1, setWishlist] = useState([]);
  // const [isPluginInWishlist,setIsPluginInWishlist] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  // const { slug } = useParams();
  // const { data: pluginDetails, error, isLoading, isError } = useGetDataByPluginQuery({ plugin_id: slug });
  const queryString = new URLSearchParams(window.location.search);
  let selectedPlugin = useSelector((state: any) => state.userplugins.value).plugins;
  const [src, setSrc] = useState(`https://ps.w.org/${plugin.slug}/assets/icon-256x256.png`);
  const filters = useAppSelector(state => state.storeFilters.value);

  const favorite = false ? favorite_select : favorite_unselect;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const wishlistString = localStorage.getItem('wishlist');
    const wishlist = wishlistString ? JSON.parse(wishlistString) : [];
    setWishlist(wishlist);
    if (Array.isArray(wishlist)) {
      const isAlreadyInWishlist = wishlist.some(
        (plugins: any) => plugins.id === plugin.id && plugins.dataType === filters.data.flag
      );
      if (isAlreadyInWishlist) {
        // setIsPluginInWishlist(true);
        setIsFavorite(true);
      }
    }
  }, [plugin, view]);
  // console.log("selectPluginData",selectPluginData)

  useEffect(() => {
    dispatch(setPluginNumber(selectPluginData.length));
  }, [selectPluginData]);

  // const addremove=(data: Iitem, e: any) => {
  //   data = Object.assign({selectedVersion: data.versions[0]?.id}, data)
  //   let pluginData=[...selectedPlugin]
  //   let index=pluginData.findIndex(x => x.id === data.id);
  //   if(index > -1){
  //     pluginData.splice(index, 1);
  //   }else{
  //     pluginData.push(data)
  //   }
  //   try {
  //     const result = syncUserSession({sessonData:JSON.stringify(pluginData)})
  //     result.then((r:any)=>{
  //       switch (e.target.innerHTML) {
  //         case 'Select':
  //           toast.success(`${data.name} added to current queue`);
  //           break;

  //         case 'Remove':
  //           toast.success(`${data.name} removed from current queue`);
  //           break;

  //         default:
  //           break;
  //     }
  //   })
  //   } catch (error) {
  //     toast.error(`${error}`)
  //   }
  // }

  useEffect(() => {
    dispatch(setWishlistNumber(wishlist1.length));
  }, [wishlist1]);

  const onAddPlugin = (plugin: any, event: any): void => {
    // console.log('plugin', plugin, event, selectPluginData);
    const a = selectPluginData;
    const filterdata = selectPluginData.find((i: any) => {
      return i.id === plugin.id;
    });
    if (filterdata !== undefined) {
      const ab = a.filter((i: any) => {
        return i.id !== plugin.id;
      });
      localStorage.setItem('storequeue', JSON.stringify(ab!));
      toast.error('Plugin removed from queue');
      setSelectPluginData(ab);
    } else {
      const abc = [...a, plugin];
      toast.success('Plugin added to queue');
      localStorage.setItem('storequeue', JSON.stringify(abc!));
      setSelectPluginData(abc);
    }
  };

  const addToWishlist = () => {
    // if(!isFavorite){

    // }else{
    //   setIsFavorite(true)
    // }
    // console.log("pluginDetails",  filters);
    const wishlistString = localStorage.getItem('wishlist');
    const wishlist = wishlistString ? JSON.parse(wishlistString) : [];

    const isAlreadyInWishlist = wishlist.some(
      (plugins: any) => plugins.id === plugin.id && plugins.dataType === filters.data.flag
    );
    if (!isAlreadyInWishlist) {
      const newObj = { ...plugin, versions: plugin.versions, dataType: filters.data.flag };
      wishlist.push(newObj);
      setWishlist(wishlist);
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
      setIsFavorite(true);
      toast.success('Plugin Added to wishlist');
      // console.log('Added to wishlist:', plugin);
    } else {
      // console.log("wishlist",wishlist)
      const filtered = wishlist.filter((ik: any) => {
        return !(ik.id === plugin.id && ik.dataType === filters.data.flag);
      });
      setWishlist(filtered);
      setIsFavorite(false);
      localStorage.setItem('wishlist', JSON.stringify(filtered));
      toast.error('Removed from wishlist');
    }
  };

  const handleImageError = () => {
    setSrc(img);
  };
  const isPremium = plugin?.versions?.some((obj: any) => {
    return obj.fusepress_path !== null;
  })
    ? 'Premium'
    : 'Free';
  const renderRating = () => {
    if (plugin.type === 'private' && plugin.ratings !== undefined) {
      return (
        <div className='tooltip-container'>
          <div className='tooltip'>{t('store-list.plugin.Private')}</div>
        </div>
        //   {/* <div className="price">${plugin.rating}</div> */}
        //   <Rating tooltipDefaultText="Private" showTooltip={true} allowFraction={true} fillColor="#F28A03" size={20} className="float-start" emptyColor="#888888" allowHover={false}/>
      );
    } else {
      return (
        <div aria-disabled={true}>
          {/* <div className="price">${plugin.rating}</div> */}
          <Rating
            initialValue={plugin.rating / 20}
            allowFraction={true}
            fillColor='#F28A03'
            size={20}
            className='float-start'
            emptyColor='#888888'
            allowHover={false}
            disableFillHover={true}
            readonly
          />
        </div>
      );
    }
  };

  const NavigateTo = (plugin: any) => {
    navigate(`/store/${plugin.slug}`);
  };
  return (
    // <div
    // key={plugin.id}
    //   className={view === 'grid' ? 'col-xxl-3 col-xl-4 col-md-6 col-sm-12 plugin_columns' : 'col-md-12 listmode plugin_columns'}
    // >
    //   <div className='plug_grid'>
    //     <div className='card-body'>
    //       <div className={view === 'grid' ? 'plugin_area' : 'plugin_area row g-3 '}>
    //         <div
    //           className={
    //             view === 'grid' ? 'plug_img' : 'plug_img col-sm-12 col-md-6 col-12  col-xl-3 order-md-0 order-xl-0'
    //           }
    //         >
    //           <div className={`product_like ${isFavorite ? 'favorite' : ''}`}  onClick={addToWishlist}>
    //             <FontAwesomeIcon icon={faHeart}  style={{color:'white'}} size='lg'/>
    //           </div>
    //           <img className='store-item-image img-fluid' style={{height:"auto", width:"100%" ,objectPosition:'center'}} src={src} onError={() => handleImageError()} alt=''></img>
    //           {/* <div className="select_favorite">
    //       <img className="favorite_select img-fluid" src={favorite} alt=""></img>
    //     </div> */}
    //         </div>
    //         <div
    //           className={view === 'grid' ? 'list' : 'list col-md-12 col-12 col-sm-12 col-xl-6 order-md-2 order-xl-1'}
    //         >
    //           <Link to={`/store/${plugin.slug}`} style={{ textDecoration: 'none' }}>
    //             <div className='pluginname font20'>{plugin.name ? plugin.name : plugin.slug}</div>
    //           </Link>
    //           <div className='author font16'>{t('store-list.plugin.By')}: {plugin.wp_author}</div>
    //           <div className='up_date font16'>
    //             <span className='text-white font16'>{t('store-list.plugin.Last-updated')} : </span>{' '}
    //             {moment(plugin.updated_at).format('DD MMM YYYY') !== '01 Jan 0001'
    //               ? moment(plugin.updated_at).format('DD MMM YYYY')
    //               : ''}
    //           </div>
    //           {view === 'list' ? (
    //             <div className='list_visible font16'>
    //               {/* <div className='plug_desc font16 grey-color'>
    //                 <span className='text-white'>{t('store-list.plugin.Compatibility')} : </span>
    //                 Lorem Ipsum is simply dummy text of the
    //                 printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
    //                 since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
    //                 specimen book.
    //               </div> */}
    //               <div className='tag_cat'>
    //                 <div className='tag_lst'>
    //                   <span className='text-white my-1 font16'>{t('store-list.plugin.Tags')} :&nbsp;</span>
    //                   <div className='tag_list cattag_lst'>
    //                     <span>{t('store-list.plugin.Plugin')}</span>
    //                   </div>
    //                 </div>
    //                 {/* <div className='categories_lst'>
    //                   <span className='text-white my-1 font16'>{t('store-list.plugin.Categories')}:&nbsp;</span>
    //                   <div className='cat_list cattag_lst'>
    //                     <span>{t('store-list.plugin.Ecommerce')}</span>
    //                     <span>{t('store-list.plugin.SEO')}</span>
    //                   </div>
    //                 </div> */}
    //               </div>
    //             </div>
    //           ) : (
    //             ''
    //           )}
    //         </div>

    //         <div
    //           className={
    //             view === 'grid'
    //               ? 'pricesection'
    //               : 'pricesection col-md-6 col-12 col-sm-12 col-xl-3 order-md-1 order-xl-2 border-sm-width-0 border-md-width-0 border-xl-width-1 justify-content-sm-start justify-content-md-center'
    //           }
    //         >
    //           <div className='row'>
    //             <div className='col-12 col-md-6'>
    //               {view==='list'&&(
    //                 <div className="price">${plugin.rating}</div>
    //               )}
    //               {renderRating()}
    //               {/* <div className="price">${plugin.rating}</div>
    //         <Rating initialValue={plugin.rating/20} allowFraction={true} fillColor="#F28A03" size={20} className="float-start" emptyColor="#888888" /> */}
    //             </div>
    //             <div className='col-12 col-md-6 align-self-end'>
    //               <div className='plugin_select_btn '>
    //                 {/* {active !== null ? (
    //           <button className={active ? 'orange_btn ms-auto' : 'cblue_btn ms-auto'}
    //             onClick={(e: any) => {
    //               if (active) addremove(plugin, e);
    //               else addremove({...plugin,projects:existinProject}, e);
    //             }}
    //           >
    //             {active ? <span>Remove</span> : <span>Select</span>}
    //           </button>
    //         ) : null} */}
    //                 {/* <button className="cblue_btn ms-auto" type="button" disabled={queryString.get("project_id") !== null ? queryString.get("project_id") !== ""?false:true:true} onClick={(e) => onAddPlugin(plugin,e)}>
    //           {isSelectedActive ? "Remove":"Select"}
    //         </button> */}
    //                 <button
    //                   className='cblue_btn ms-auto back_btn'
    //                   type='button'
    //                   style={{ zIndex: 9999999999999 }}
    //                   onClick={e => onAddPlugin(plugin, e)}
    //                 >
    //                   {isSelectedActive ?  `${t('store-list.buttons.Remove')}` : `${t('store-list.buttons.Select')}`}
    //                 </button>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <>
      <div className='col-xxl-4 col-xl-4 col-md-6 col-sm-12 mt-2' onClick={() => NavigateTo(plugin)}>
        <span className='plug_grid' style={{ cursor: 'pointer' }}>
          <div className='plugins_info'>
            <div className='plugins_item_header'>
              <div className='plugin_icon'>
                <img className='img-fluid' src={src} onError={() => handleImageError()} alt='plugin_icon' />
              </div>
              <div className='plugins_title_author'>
                <div className='plugins_item_title' title={plugin.name ? plugin.name : plugin.slug}>
                  <h6>{plugin.name ? plugin.name.substr(0, 20) + '...' : plugin.slug}</h6>
                </div>
                <div className='plugins_item_author'>
                  <p>
                    by <span className='plugins_item_author_name'>{plugin.wp_author}</span>
                  </p>
                </div>
                <div
                  className={`product_like ${isFavorite ? 'favorite' : ''}`}
                  style={{ width: '35px', height: '35px' }}
                  onClick={e => {
                    e.stopPropagation();
                    addToWishlist();
                  }}
                >
                  <FontAwesomeIcon icon={faHeart} style={{ color: 'white' }} size='lg' />
                </div>
                <div className='plugins_item_ratings'>
                  {renderRating()}
                  {/* <svg className="star-svg" stroke="currentColor" fill="#f08921" stroke-width="0" viewBox="0 0 24 24" width="15" height="15" xmlns="http://www.w3.org/2000/svg"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg>
                        <span className="plugins_item_rating_value"><p>4.6</p></span> */}
                </div>
              </div>
            </div>
            <div className='plugins_item_description'>
              {/* <p style={{width:'35%'}} dangerouslySetInnerHTML={{__html:plugin.description}}></p> */}
              <p>
                All the benefits of Yoast SEO Free as well as exclusive SEO features which unlock even more tools and
                functionality.
              </p>
            </div>
            <div style={{ height: '30px' }}>
              {plugin.last_updated !== null ? (
                <span className='text-white'>
                  Last Updated:<span style={{ color: '#f08921' }}>&nbsp;{plugin.last_updated}</span>
                </span>
              ) : null}
            </div>
          </div>
          <div className='plugins_footer'>
            <div className='plugins_item_pricing'>
              <p>{isPremium}</p>
            </div>
            <div className='plugins_item_additional_info'></div>
            <div
              className='plugin_select_btn'
              onClick={e => {
                e.stopPropagation();
                onAddPlugin(plugin, e);
              }}
            >
              {isPremium === 'Premium' ? (
                disablePrivatepack === false ? (
                  <FontAwesomeIcon
                    icon={isSelectedActive ? faCircleMinus : faCirclePlus}
                    size='2xl'
                    color={isSelectedActive ? '#fd2525' : '#f08921'}
                  />
                ) : null
              ) : (
                <FontAwesomeIcon
                  icon={isSelectedActive ? faCircleMinus : faCirclePlus}
                  size='2xl'
                  color={isSelectedActive ? '#fd2525' : '#f08921'}
                />
              )}
            </div>
          </div>
        </span>
      </div>

      {/* {view === 'grid' && (
        <div
        key={plugin.id}
          className={'col-xxl-3 col-xl-4 col-md-6 col-sm-12 plugin_columns'}
          onClick={() => NavigateTo(plugin)}
        >
          <div className='plug_grid' style={{cursor:'pointer'}}>
            <div className='card-body'>
              <div className={view === 'grid' ? 'plugin_area' : 'plugin_area row g-3 '}>
                <div
                  className={
                    view === 'grid' ? 'plug_img' : 'plug_img col-sm-12 col-md-6 col-12  col-xl-3 order-md-0 order-xl-0'
                  }
                >
                  <div className={`product_like ${isFavorite ? 'favorite' : ''}`}  onClick={(e) =>{
                    e.stopPropagation()
                    addToWishlist()}}>
                    <FontAwesomeIcon icon={faHeart}  style={{color:'white'}} size='lg'/>
                  </div>
                  <img className='store-item-image img-fluid' style={{height:"auto", width:"100%" ,objectPosition:'center'}} src={src} onError={() => handleImageError()} alt=''></img>
                </div>
                <div
                  className={view === 'grid' ? 'list' : 'list col-md-12 col-12 col-sm-12 col-xl-6 order-md-2 order-xl-1'}
                >

                    <div className='pluginname font20'>{plugin.name ? plugin.name : plugin.slug}</div>

                  <div className='author font16'>{t('store-list.plugin.By')}: {plugin.wp_author}</div>
                  {Object.prototype.hasOwnProperty.call(plugin,"last_updated") && plugin.last_updated !==null &&
                    <div className='up_date font16'>
                    <span className='text-white font16'>{t('store-list.plugin.Last-updated')} : </span>{' '}
                    {plugin.last_updated}
                  </div>
                  }
                </div>

                <div
                  className={
                    view === 'grid'
                      ? 'pricesection'
                      : 'pricesection col-md-6 col-12 col-sm-12 col-xl-3 order-md-1 order-xl-2 border-sm-width-0 border-md-width-0 border-xl-width-1 justify-content-sm-start justify-content-md-center'
                  }
                >
                  <div className='row'>
                    <div className='col-12 col-md-6'>

                      {renderRating()}

                    </div>
                    <div className='col-12 col-md-6 align-self-end'>
                      <div className='plugin_select_btn '>

                        <button
                          className='cblue_btn ms-auto'
                          type='button'
                          style={{ zIndex: 9999999999999 }}
                          onClick={e => {
                            e.stopPropagation()
                            onAddPlugin(plugin, e)
                          }}
                        >
                          {isSelectedActive ?  `${t('store-list.buttons.Remove')}` : `${t('store-list.buttons.Select')}`}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )}



    {view === 'list' && (
        <div style={{padding:'0px 20px', margin:'0px 0px 30px'}} onClick={(e)=>{
          NavigateTo(plugin)
        }}>
          <div className="plug_grid card list_view_store" style={{cursor:'pointer'}}>

          <div className="card-body p-0">
            <div className="plugin_area row">
              <div
                className="plug_img col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 order-md-0 order-xl-0 m-0"
              >
                <div className="product_image_dec">
                  <div className="product_image">
                  <div className={`product_like ${isFavorite ? 'favorite' : ''}`} style={{width:'30px', height:'30px'}}  onClick={(e)=>
                    {e.stopPropagation()
                    addToWishlist()
                  }}>
                    <FontAwesomeIcon icon={faHeart}  style={{color:'white'}}/>
                  </div>
                    <img className='store-item-image img-fluid'  src={src} onError={() => handleImageError()} alt=''></img>
                  </div>
                  <div className="product_description">

                    <div className='pluginname font20'>{plugin.name ? plugin.name : plugin.slug}</div>

                  <div className='author font16'>{t('store-list.plugin.By')}: {plugin.wp_author}</div>
                  <div aria-disabled="true" className="star_rating">
                  {renderRating()}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="list col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 order-md-2 order-xl-1 m-0"
              >
                <div className='list_tags_updates'>
                {Object.prototype.hasOwnProperty.call(plugin,"last_updated") && plugin.last_updated !==null &&
                    <div className='up_date font16'>
                    <span className='text-white font16'>{t('store-list.plugin.Last-updated')} : </span>{' '}
                    {plugin.last_updated}
                  </div>
                  }
                <div className="list_visible font16">
                  <div className="tag_cat m-0">
                    <div className="tag_lst m-0">
                    <span className='text-white my-1 font16'>{t('store-list.plugin.Tags')} :&nbsp;</span>
                      <div className="tag_list cattag_lst"> <span>{t('store-list.plugin.Plugin')}</span></div>

                    </div>
                  </div>
                </div>
                </div>
              </div>

              <div
                className="pricesection col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12 order-md-1 order-xl-2 border-sm-width-0 border-md-width-0 border-xl-width-1 justify-content-sm-start justify-content-md-center m-0"
              >
                <div className="plugin_select_btn d-flex flex-column">
                <button
                          className='cblue_btn ms-auto'
                          type='button'
                          style={{ zIndex: 9999999999999 }}
                          onClick={e => {e.stopPropagation()
                                        onAddPlugin(plugin, e)}}
                        >
                          {isSelectedActive ?  `${t('store-list.buttons.Remove')}` : `${t('store-list.buttons.Select')}`}
                        </button>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
    )} */}
    </>
  );
};
export default StorePlugin;
