import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticationMutation } from '../features/api';
import { toast } from 'react-toastify';
import useAuthenticated from '../hooks/useAuthenticated';

const AuthOtp: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState(300);
  const [otp, setOtp] = useState('');
  const [Auth] = useAuthenticationMutation();
  const authenticated = useAuthenticated();

  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated.token.length > 0) {
      navigate('/');
    } else {
      if(authenticated.otp === "false"){
        navigate('/auth')
      }
    }

  },[])

  useEffect(() => {
    if (timeLeft === 0) {
      navigate('/login');
    }
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  // if (authenticated) {
  //   navigate('/');
  // }

  function handleSubmit() {
    const ab =
      localStorage.getItem('wordpresslogin') !== null ? JSON.parse(localStorage.getItem('wordpresslogin') || '{}') : {};
    if (Object.keys(ab).length > 0) {
      try {
        const email1 = atob(ab.email);
        Auth({ otp: otp, email: email1 })
          .then((r: any) => {
            console.log("r.data",r)
            if(Object.prototype.hasOwnProperty.call(r,'error')){
              toast.error(r.error.message)
            } else {
              toast.success('Success');
              localStorage.setItem('token', r?.data?.access_token);
              localStorage.removeItem('login_proceed')
              // localStorage.setItem('userDetails', JSON.stringify(r?.data?.user));
              setTimeout(() => {
                navigate('/');
              }, 1000);
            }
            // if (!r.data.error) {
            //   toast.success('Success');
            //   localStorage.setItem('token', r?.data?.access_token);
            //   localStorage.removeItem('login_proceed')
            //   // localStorage.setItem('userDetails', JSON.stringify(r?.data?.user));
            //   setTimeout(() => {
            //     navigate('/');
            //   }, 1000);
            // } else {
            //   toast.error(r.data.message);
            // }
          })
          .catch(e => {
            console.log("e",e)
            toast.error(`${e}`);
          });
      } catch (error) {
        toast.error('Something Went Wrong!!!!');
      }
    } else {
      toast.error('Email Session NotFound');
    }
  }

  return (
    <main className='main'>
      <div className='container-fluid'>
        <div className='d-flex align-items-center justify-content-center auth-wrap'>
          <div className='auth'>
            <h6 className='text-white'>Two-Factor Authentication</h6>
            <p className='otp-text'>OTP sent please check your mail.</p>
            <form>
              <div className='mb-3'>
                <input
                  placeholder='Two-Factor Code'
                  type='text'
                  className='form-control form-square'
                  id='exampleInputEmail1'
                  aria-describedby='emailHelp'
                  onChange={e => setOtp(e.target.value)}
                />
              </div>
              <button type='button' className='orange_btn w-100' onClick={handleSubmit}>
                Continue
              </button>
            </form>
            <div className='otp-text mt-3'>
              <a
                href={`mailto:contact@thethirdwave.co`}
                className='text-decoration-none primary-color'
                target='_blank'
                rel='noreferrer'
              >
                Problems receiving your OTP?
              </a>
            </div>
            <div className='otp-text'>
              Remaining time is <span className='primary-color'>{timeLeft}</span> seconds
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AuthOtp;
